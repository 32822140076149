import * as types from './actionTypes';
import { ajax } from '../helper';
import _ from 'lodash';

export function resetDocumentList() {
    return {
        type: types.RESET_DOCUMENT_LIST,
    };
}

export function resetDocumentTitles() {
    return {
        type: types.RESET_DOCUMENT_TITLES,
    };
}

export function getContainerDocumentsSuccess(documents) {
    return {
        type: types.GET_CONTAINER_DOCUMENTS_SUCCESS,
        payload: documents,
    };
}

export function getContainerDocumentsFailure() {
    return {
        type: types.GET_CONTAINER_DOCUMENTS_FAILURE,
    };
}

export function getContainerDocuments(documents, id) {
    return function (dispatch) {
        if (id === -1) {
            dispatch(getContainerDocumentsSuccess([]));
            return null;
        }
        if (documents) {
            dispatch(getContainerDocumentsSuccess(documents));
        } else {
            dispatch(getContainerDocumentsSuccess([]));
        }
        return documents;
    };
}

export function getDocumentSignaturesSuccess(signatures) {
    return {
        type: types.GET_DOCUMENT_SIGNATURES_SUCCESS,
        payload: signatures,
    };
}

export function getDocumentSignaturesFailure() {
    return {
        type: types.GET_DOCUMENT_SIGNATURES_FAILURE,
    };
}

export function fetchDocumentDeleteSuccess(documentId, index) {
    return {
        type: types.FETCH_DOCUMENT_DELETE_SUCCESS,
        payload: { documentId: documentId, documentIndex: index },
    };
}

export const uploadDocumentSuccess = (data) => {
    return {
        type: types.UPLOAD_DOCUMENT_SUCCESS,
        payload: data,
    };
};

export const documentRenameSuccess = (index, newTitle) => {
    return {
        type: types.RENAME_DOCUMENT_SUCCESS,
        index: index,
        title: newTitle,
    };
};

export function uploadCompanyRegistrationDocument(containerId, formData) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/ecegeljaras/${containerId}/document`, formData)
            .then((response) => {
                dispatch(uploadDocumentSuccess(response.data.details.documents));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function renameDocument(containerType, containerId, docId, title) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/${containerId}/documents/${encodeURIComponent(docId)}/rename`, { title: title })
            .then((response) => {
                const data = response.data;
                dispatch(getContainerDocuments(data.details.documents, data.id));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function uploadDocument(containerId, document) {
    return function (dispatch) {
        return ajax()
            .post(`/containers/${containerId}/documents/upload`, document)
            .then((response) => {
                dispatch(uploadDocumentSuccess(response.data.details.documents));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function deleteDocument(type, contId, docId) {
    return function (dispatch) {
        return ajax()
            .delete(`/containers/${contId}/documents/${docId}`)
            .then((response) => {
                const data = response.data;
                dispatch(getContainerDocuments(data.details.documents, data.id));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function downloadDocument(contId, docId, fileName) {
    return function () {
        return ajax()
            .get(`/containers/${contId}/documents/${encodeURIComponent(docId)}/download`, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        encoding: 'UTF-8',
                        type: response.headers['content-type'] + ';charset=UTF-8',
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function downloadCommentDocument(contId, docId, signatureId) {
    return function () {
        const downloadURL =
            docId === null
                ? `/containers/${contId}/signatures/${signatureId}/download`
                : `/containers/${contId}/documents/${encodeURIComponent(docId)}/signatures/${signatureId}/download`;
        return ajax()
            .get(downloadURL, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: `${response.headers['content-type']};charset=UTF-8`,
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                const headerval = response.headers['content-disposition'];
                const filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
                link.setAttribute('download', decodeURI(filename));
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function downloadAttachment(contId, fileName, description) {
    return function () {
        const downloadURL = `/containers/${contId}/downloadAttachment/${fileName}/${description}`;
        return ajax()
            .get(downloadURL, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                throw err;
            });
    };
}

export const checkCompanyRegistrationDocument = (containerId, formData) => (dispatch) => {
    return ajax()
        .post(`/containers/ecegeljaras/${containerId}/checkDocument`, formData)
        .then((response) => {
            if (!_.isEmpty(response.data)) {
                dispatch(getContainerDocuments(response.data.details.documents));
            }
            return response;
        })
        .catch((err) => {
            throw err;
        });
};
