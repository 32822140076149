import PropTypes from 'prop-types';
import React from 'react';
import eszignoImg from '../images/eszigno-full.svg';
import microSignerImg from '../images/microsigner.svg';
import pbmImg from '../images/passbyme_v2.svg';
import { Avatar } from 'antd';

class ESignNoClient extends React.Component {
    render() {
        const noMobileApplicationOrFailureOnMobileDevice = this.props.signWithPBM || window.isMobileCheck();
        let content = null;
        if (this.props.visible) {
            let firstPart = '';
            let secondPart = '';
            let thirdPart = '';
            let esignClientDownloadLinks = [];
            if (noMobileApplicationOrFailureOnMobileDevice) {
                firstPart = this.props.translate('modals.noMobileApp.title.beforeFirstLogo');
                secondPart = this.props.translate('modals.noMobileApp.title.beforeSecondLogo');
                thirdPart = this.props.translate('modals.noMobileApp.title.afterSecondLogo');
                esignClientDownloadLinks = [
                    {
                        downloadLink: 'https://play.google.com/store/apps/details?id=hu.microsec.eszigno',
                        message: this.props.translate('texts.downloadEszignoMobile') + ' (Android)',
                    },
                    {
                        downloadLink: 'https://apps.apple.com/us/app/e-szign%C3%B3/id1481195992',
                        message: this.props.translate('texts.downloadEszignoMobile') + ' (iOS, iPadOS)',
                    },
                    {
                        downloadLink: 'https://play.google.com/store/apps/details?id=hu.microsec.passbyme',
                        message: this.props.translate('texts.downloadPassByME') + ' (Android)',
                    },
                    {
                        downloadLink: 'https://apps.apple.com/us/app/passby-me-mobile-id/id1182363087',
                        message: this.props.translate('texts.downloadPassByME') + ' (iOS, iPadOS)',
                    },
                ];
            } else {
                firstPart = this.props.translate('modals.noMicroSigner.title.beforeLogo');
                secondPart = this.props.translate('modals.noMicroSigner.title.afterLogo');
                let desktopPlatform = window.navigator.platform;
                if (desktopPlatform.startsWith('Win')) {
                    esignClientDownloadLinks = [
                        {
                            downloadLink: window.config.REACT_APP_MICROSIGNER_WINDOWS,
                            message: this.props.translate('texts.downloadMicroSigner'),
                        },
                    ];
                } else if (desktopPlatform.startsWith('Mac')) {
                    esignClientDownloadLinks = [
                        {
                            downloadLink: window.config.REACT_APP_MICROSIGNER_MACOS,
                            message: this.props.translate('texts.downloadMicroSigner'),
                        },
                    ];
                } else if (desktopPlatform.startsWith('Linux')) {
                    esignClientDownloadLinks = [
                        {
                            downloadLink: window.config.REACT_APP_MICROSIGNER_LINUX,
                            message: this.props.translate('texts.downloadMicroSigner'),
                        },
                    ];
                }
            }
            content = (
                <React.Fragment>
                    {noMobileApplicationOrFailureOnMobileDevice ? (
                        <React.Fragment>
                            {firstPart}
                            <Avatar size={20} src={eszignoImg} style={{ marginLeft: 6, marginRight: 4 }} />
                            {secondPart}
                            <Avatar size={20} src={pbmImg} style={{ marginLeft: 6, marginRight: 4 }} shape="square" />
                            {thirdPart}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {firstPart}
                            <Avatar
                                size={20}
                                src={microSignerImg}
                                style={{ marginLeft: 6, marginRight: 4 }}
                                shape="square"
                            />
                            {secondPart}
                        </React.Fragment>
                    )}
                    {esignClientDownloadLinks.map((obj, i) => {
                        return (
                            <div key={i} style={{ marginTop: 5 }}>
                                <a href={obj.downloadLink} target="_blank" rel="noopener noreferrer">
                                    {obj.message}
                                </a>
                                <br />
                            </div>
                        );
                    })}
                </React.Fragment>
            );
        }
        return content;
    }
}

ESignNoClient.propTypes = {
    translate: PropTypes.func.isRequired,
    signWithPBM: PropTypes.bool.isRequired,
    visible: PropTypes.bool.isRequired,
};

export default ESignNoClient;
