import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';

function InvisibleButton(props) {
    const { children } = props;

    return (
        <Button
            style={{
                border: 'none',
                boxShadow: 'none',
                color: 'rgba(0, 0, 0, 0.65)',
                height: 'fit-content',
                margin: 0,
                padding: 0
            }}
            {...props}>
            {children}
        </Button>
    );
}

InvisibleButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

export default InvisibleButton;
