/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import { removeModal } from '../../../actions/modal';
import { Spin, Button, Select, Descriptions, Modal, Icon } from 'antd';
import { getAttributeRoles } from '../../../actions/user';
import { createNotification } from '../../../helper';
import { getTranslate } from 'react-localize-redux';

export default function AttributeRolesModal(props) {
    const dispatch = useDispatch();

    const [isMounted, setIsMounted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState(null);
    const [selectedRole, setSelectedRole] = useState(0);

    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setIsMounted(true);
        dispatch(getAttributeRoles(props.modalData.certificate))
            .then((response) => {
                setRoles(response.data);
                setSelectedRole(response.data[0]);
                setLoading(false);
            })
            .catch((err) => {
                dispatch(removeModal());
                createNotification(translate, err.response.data.code, {
                    message: translate('notifications.getData.failure'),
                    type: 'error'
                });
                Modal.confirm({
                    title: translate('modals.attributeCert.attributeCertificateRoleError'),
                    cancelText: translate('buttons.cancel'),
                    onCancel: () => Modal.destroyAll(),
                    okText: translate('buttons.yes'),
                    maskClosable: false,
                    onOk: () => props.modalData.startSign(null),
                    icon: <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
                });
            });
        return () => {
            setIsMounted(false);
        };
    }, []);

    const roleSelected = (value) => {
        const selectedRole = roles.filter((role) => role.title === value)[0];
        isMounted && setSelectedRole(selectedRole);
    };

    return (
        <ModalWrapper {...props} title={translate('modals.attributeCert.attributeCertificateRoles')}>
            <Spin spinning={loading}>
                <p>{translate('attributeCertificate.description')}</p>
                {roles !== null && (
                    <div>
                        <Select
                            onChange={roleSelected}
                            defaultValue={roles[0].title}
                            style={{ width: '100%' }}
                        >
                            {roles.map((role) => (
                                <Select.Option key={role.title} value={role.title}>
                                    {role.title}
                                </Select.Option>
                            ))}
                        </Select>
                        <Descriptions
                            bordered
                            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                            style={{ marginTop: '10px' }}
                        >
                            <Descriptions.Item label={translate('userRole.owner')}>
                                {selectedRole.commonName}
                            </Descriptions.Item>
                            <Descriptions.Item label={translate('details')}>
                                <a target={'blank'} href={selectedRole.description}>
                                    {selectedRole.description}
                                </a>
                            </Descriptions.Item>
                            <Descriptions.Item label={translate('attributeCertificate.organization')}>
                                {selectedRole.o === 'NULL' ? '-' : selectedRole.o}
                            </Descriptions.Item>
                            <Descriptions.Item label={translate('attributeCertificate.serialNumber')}>
                                {selectedRole.serialNumber === 'NULL' ? '-' : selectedRole.serialNumber}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                )}

                <Button
                    type='primary'
                    block
                    onClick={() => {
                        dispatch(removeModal());
                        props.modalData.startSign(selectedRole, props.modalData.certificate);
                    }}
                    style={{ marginTop: '10px' }}
                >
                    {translate('modals.signing.title')}
                </Button>
            </Spin>
        </ModalWrapper>
    );
}

AttributeRolesModal.propTypes = {
    modalData: PropTypes.object
};
