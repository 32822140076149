import DocumentControls from '../DocumentControls';
import DocumentList from './DocumentList';
import React, { useState } from 'react';
import { TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS } from '../../constants/strings';
import { Alert, Empty } from 'antd';
import { getTranslate } from 'react-localize-redux';
import { makeGetActiveTabContainerById } from '../../selectors';
import { createNotification, shouldSignAuto } from '../../helper';
import { updateContainerData } from '../../actions/container';
import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../actions/modal';
import SkeletonDocumentPage from '../widget/SkeletonDocumentPage';

export default function DocumentPage(props) {
    const dispatch = useDispatch();

    const [container, setContainer] = useState();
    const [tableLoading, setTableLoading] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));
    const documents = useSelector((state) => state.documentList.documents);
    const getActiveCont = makeGetActiveTabContainerById();
    const selectedContainer = useSelector((state) =>
        state.containerList.selectedContainers.length > 0
            ? state.containerList.selectedContainers[0]
            : getActiveCont(state, props)
    );
    const isDeprecatedCompanyRegistrationSchema = (container) => {
        if (container && container.esNamespace) {
            return container.esNamespace === 'http://www.e-cegjegyzek.hu/2014/e-cegeljaras#';
        }
        return false;
    };
    const isContainerSigned = useSelector((state) => !!state.containerList.signatures.length);
    const isUploadDisabled = (container) => {
        const containerType = container.type;
        return (containerType === 'edossier' && isContainerSigned) ||
        isDeprecatedCompanyRegistrationSchema(container) ||
        containerType === 'et3';
    };
    const containers = useSelector((state) => state.containerList.containers);
    const containersInSignatureProcess = useSelector((state) => state.containerList.containersInSignatureProcess);
    const currentTab = useSelector((state) => state.tabs.current);

    useDeepCompareEffect(() => {
        const getActiveContainerData = (id) => {
            dispatch(updateContainerData(id, currentTab === TAB_KEY_DEFAULT_CONTAINERS))
                .then((response) => {
                    setContainer(response);
                    if (shouldSignAuto(response) && window.config.REACT_APP_PDF_FORM_START_SIGN_AUTO) {
                        dispatch(
                            toggleModal('PDF_WITH_FORM_NOTIFICATION', {
                                container: response,
                                showPdfPreview: false
                            })
                        );
                    }
                })
                .catch((error) => {
                    createNotification(translate, error.response.data.code, {
                        message: translate('notifications.getDocumentData.failure'),
                        type: 'error'
                    });
                });
        };

        selectedContainer && getActiveContainerData(selectedContainer.data.id);
    }, [selectedContainer]);

    const isPDF = selectedContainer && selectedContainer.data.type === 'pdf';
    const containersInUse = (containers.length > 0 && currentTab === TAB_KEY_DEFAULT_CONTAINERS) ||
        (containersInSignatureProcess.length > 0 && currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS);

    if (container) {
        return (
            <React.Fragment>
                {containersInUse && selectedContainer ? (
                    <React.Fragment>
                        <DocumentControls
                            container={container}
                            documents={documents}
                            hasDocuments={documents.length > 0}
                            isContainerSigned={isContainerSigned}
                            isDeprecatedCompanyRegistrationSchema={isDeprecatedCompanyRegistrationSchema(container)}
                            isUploadDisabled={isUploadDisabled(container)}
                            setTableLoadingTrue={() => setTableLoading(true)}
                            setTableLoadingFalse={() => setTableLoading(false)}
                        />
                        {container.type === 'pdf' && shouldSignAuto(container) && (
                            <Alert
                                type='info'
                                message={translate('modals.pdfWithForm.subTitle')}
                                banner
                            />
                        )}
                        <DocumentList
                            container={container}
                            isContainerSigned={isContainerSigned}
                            isDeprecatedCompanyRegistrationSchema={isDeprecatedCompanyRegistrationSchema(container)}
                            isUploadDisabled={isUploadDisabled(container)}
                            tableLoading={tableLoading}
                            setTableLoadingTrue={() => setTableLoading(true)}
                            setTableLoadingFalse={() => setTableLoading(false)}
                        />
                    </React.Fragment>
                ) : (
                    <Empty
                        style={{ marginTop: 20 }}
                        description={<span>{translate('containerNotSelected')}</span>}
                    />
                )}
            </React.Fragment>
        );
    } else {
        return (<SkeletonDocumentPage isPDF={isPDF} />);
    }
};
