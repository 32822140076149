/* eslint-disable react-hooks/exhaustive-deps */
import Box from '../Box';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { TABLE_PAGE_SIZE_WORKFLOW_DETAILS } from '../../constants/config';
import { Table } from 'antd';
import { calculatePageNumber, reversedSorter, sorter } from '../../helper';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

function WorkflowDetails(props) {
    const { selectedParticipantOrder, setSelectedParticipantOrder, selectedWorkflowGuid, workflows } = props;

    const [isMounted, setIsMounted] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [sortDirection, setSortDirection] = useState('ascend');
    const [sortKey, setSortKey] = useState('signingorder');

    const translate = useSelector((state) => getTranslate(state.locale));

    const mounted = useRef();

    const selectedWorkflow = workflows.find((workflow) => workflow.guid === selectedWorkflowGuid);

    useEffect(() => {
        setIsMounted(true);
        if (mounted.current) {
            setPageNumber(getPageNumber());
        }
        return () => {
            setIsMounted(false);
        };
    }, []);

    function getSorter() {
        let _sorter;
        if (sortDirection === 'descend') {
            _sorter = () => reversedSorter(sortKey);
        } else {
            _sorter = () => sorter(sortKey);
        }
        return _sorter;
    }

    function getPageNumber() {
        return calculatePageNumber(
            workflows.sort(getSorter()).findIndex((workflow) => workflow.guid === selectedWorkflowGuid),
            TABLE_PAGE_SIZE_WORKFLOW_DETAILS
        );
    }

    function selectRow(participant) {
        isMounted && setSelectedParticipantOrder(participant.signingorder);
    }

    function onRowSelection(pagination, filters, sorter) {
        isMounted && setSortDirection(sorter.order);
        isMounted && setSortKey(sorter.columnKey);
    }

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            title: translate('workflow.details.table.id')
        },
        {
            align: 'center',
            dataIndex: 'state',
            key: 'state',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                return a.state.localeCompare(b.state);
            },
            title: translate('workflow.details.table.status'),
            width: 128
        },
        {
            dataIndex: 'message',
            key: 'message',
            title: translate('workflow.details.table.message')
        },
        {
            align: 'center',
            dataIndex: 'signingorder',
            defaultSortOrder: 'ascend',
            key: 'signingorder',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.signingorder - b.signingorder,
            title: translate('workflow.details.table.order'),
            width: 128
        }
    ];

    const dataSource = selectedWorkflow
        ? selectedWorkflow.waitingToSignByOthers.map((workflow) => ({
              createdBy: selectedWorkflow.createdBy,
              message: workflow.message ? workflow.message : '',
              key: workflow.bulkSigningOrder,
              name: workflow.user,
              signingorder: workflow.bulkSigningOrder,
              state: translate('workflow.details.status.' + workflow.status),
              status: workflow.status
          }))
        : [];

    return (
        <Box
            borderRadius={8}
            padding={16}>
            <h2>{translate('workflow.details.title')}</h2>
            <Table
                columns={columns}
                dataSource={dataSource}
                locale={{ emptyText: translate('workflow.details.placeholder') }}
                onChange={onRowSelection}
                onRow={(row) => ({
                    onClick: () => {
                        selectRow(row);
                    }
                })}
                pagination={{
                    current: pageNumber,
                    onChange: (page) => isMounted && setPageNumber(page),
                    pageSize: TABLE_PAGE_SIZE_WORKFLOW_DETAILS,
                    size: 'small'
                }}
                rowClassName={(row) => {
                    return row.signingorder === selectedParticipantOrder ? 'table-select' : '';
                }}
                size={'small'}
            />
        </Box>
    );
}

WorkflowDetails.propTypes = {
    selectedParticipantOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setSelectedParticipantOrder: PropTypes.func.isRequired,
    selectedWorkflowGuid: PropTypes.string,
    workflows: PropTypes.array.isRequired
};

export default WorkflowDetails;
