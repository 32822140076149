/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import WebPage from './WebPage';
import { useSelector } from 'react-redux';
import authService from '../services/authService';
import theme from '../theme';
import { Col, Icon, Row, Spin } from 'antd';
import { ajax } from '../helper';
import { getTranslate } from 'react-localize-redux/lib/index';

export default function ResetSecondFactor (props) {

    const { match, history } = props;

    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [error, setError] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));

    if (!isMounted) {
        localStorage.getItem('username') != null && authService.logout();
    }

    useEffect(() => {
        setIsMounted(true);
        const token = match.params.token;
        ajax()
            .post('/resetSecondFactor', { secondFactorToken: token })
            .then(() => {
                isMounted && setTitle(translate('resetSecondFactor.success.title')) 
                            && setSubTitle(translate('resetSecondFactor.success.subTitle', {
                                    link: window.location.protocol + '//' + window.location.host + '/#/login',
                                }))
            })
            .catch((err) => {
                isMounted && setTitle(translate('resetSecondFactor.error.title')) 
                            && setSubTitle(translate('resetSecondFactor.error.details'))
                            && setError(true)
            })
            .finally(() => {
                isMounted && setLoading(true);
            });
        return () => {
            setIsMounted(false);
        };
    }, []);

    return (
        <WebPage location={history.location}>
            <Spin spinning={loading}>
                <h2
                    style={{
                        margin: '64px auto 16px auto',
                        textAlign: 'center',
                    }}
                >
                    {title}
                </h2>
                <h4
                    style={{
                        margin: '16px auto 16px auto',
                        textAlign: 'center',
                    }}
                >
                    {subTitle}
                </h4>
                <Row type="flex" justify="center" align="top">
                    <Col>
                        {error ? (
                            <Icon
                                type="close-circle"
                                theme="twoTone"
                                twoToneColor={theme.errorColor}
                                style={{
                                    margin: '16px auto',
                                    fontSize: 48,
                                }}
                            />
                        ) : (
                            <Icon
                                type="check-circle"
                                theme="twoTone"
                                twoToneColor={theme.successColor}
                                style={{
                                    margin: '16px auto',
                                    fontSize: 48,
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Spin>
        </WebPage>
    );
}

