import { Button, Col, Icon, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { removeModal, toggleModal } from '../../../actions/modal';
import { WORKFLOW_TEMPLATE } from '../../../constants/modalNames';

export default function SendForSignatureTitle(props) {
    const translate = useSelector((state) => getTranslate(state.locale));
    const dispatch = useDispatch();

    function openTemplatesModal() {
        dispatch(removeModal());
        setTimeout(function() {
            dispatch(toggleModal(WORKFLOW_TEMPLATE));
        }, 500);
    }

    return (
        <Row type='flex'>
            <Col span={16}>
                <b>{translate('modals.sendForSignature.title')}</b>
            </Col>
            <Col span={4}>
                <Button key={'sendForSignatureTitle'} style={{ border: 'none', color: 'black', height: 22, boxShadow: 'none'}} ghost
                        onClick={openTemplatesModal}><Icon type='star' /> {translate('workflow.template.title')}
                </Button>
            </Col>
        </Row>
    );
}
