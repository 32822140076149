import React from 'react';
import WebPage from './WebPage';
import authService from '../services/authService';
import theme from '../theme';
import { Col, Icon, Row, Spin } from 'antd';
import { PropTypes } from 'prop-types';
import { ajax } from '../helper';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux/lib/index';

export class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            title: '',
            subTitle: '',
            error: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        localStorage.getItem('username') != null && authService.logout();
        const { translate, isPassword, match } = this.props;
        const token = match.params.token;
        if (token) {
            ajax()
                .post('/confirmation', { confirmationToken: token })
                .then(() => {
                    this._isMounted &&
                        this.setState({
                            title: translate('registration.confirmation.title'),
                            subTitle: translate('registration.confirmation.subTitle', {
                                link: window.location.protocol + '//' + window.location.host + '/#/login',
                            }),
                        });
                })
                .catch((err) => {
                    this._isMounted &&
                        this.setState({
                            title: translate('registration.confirmation.error.title'),
                            subTitle: translate('registration.confirmation.error.details'),
                            error: true,
                        });
                    console.error(err);
                })
                .finally(() => {
                    this._isMounted && this.setState({ loading: false });
                });
        } else {
            this._isMounted &&
                this.setState({
                    loading: false,
                    title: isPassword ? translate('renew.success.title') : translate('confirm.title'),
                    subTitle: isPassword ? translate('renew.success.subTitle') : translate('confirm.subTitle'),
                });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { history } = this.props;
        return (
            <WebPage location={history.location}>
                <Spin spinning={this.state.loading}>
                    <h2
                        style={{
                            margin: '64px auto 16px auto',
                            textAlign: 'center',
                        }}
                    >
                        {this.state.title}
                    </h2>
                    <h4
                        style={{
                            margin: '16px auto 16px auto',
                            textAlign: 'center',
                        }}
                    >
                        {this.state.subTitle}
                    </h4>
                    <Row type="flex" justify="center" align="top">
                        <Col>
                            {this.state.error ? (
                                <Icon
                                    type="close-circle"
                                    theme="twoTone"
                                    twoToneColor={theme.errorColor}
                                    style={{
                                        margin: '16px auto',
                                        fontSize: 48,
                                    }}
                                />
                            ) : (
                                <Icon
                                    type="check-circle"
                                    theme="twoTone"
                                    twoToneColor={theme.successColor}
                                    style={{
                                        margin: '16px auto',
                                        fontSize: 48,
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </Spin>
            </WebPage>
        );
    }
}

Confirm.propTypes = {
    history: PropTypes.object.isRequired,
    isPassword: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        isPassword: state.confirm.isPassword,
        translate: getTranslate(state.locale),
    };
}

export default connect(mapStateToProps, null)(Confirm);
