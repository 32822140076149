import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function(state = initialState.confirm, action) {
    switch (action.type) {
        case types.SET_ISPASSWORD:
            return { ...state, isPassword: true };
        case types.RESET_ISPASSWORD:
            return { ...state, isPassword: false };
        default:
            return state;
    }
}