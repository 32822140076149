/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

export default function ESignCheckDocumentStates(props) {
    const { documentIds } = props;

    const [canNotBeSignedCount, setCanNotBeSignedCount] = useState(0);
    const [signedCount, setSignedCount] = useState(0);
    const [unsignedCount, setUnsignedCount] = useState(0);

    const [isMounted, setIsMounted] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setIsMounted(true);
        esignCheckDocumentStates();
        return () => {
            setIsMounted(false);
        };
    }, [props.start]);

    const esignCheckDocumentStates = () => {
        window.esignstate.getDocumentStates(
            // onsuccess
            (documentStates) => {
                let x = 0,
                    y = 0,
                    z = 0;
                for (let i = 0; i < documentStates.length; i++) {
                    if (documentIds.indexOf(documentStates[i].documentId) < 0) {
                        throw new Error('Unexpected document id!');
                    }
                    if (documentStates[i].state === 'UNSIGNED') {
                        x++;
                    } else if (documentStates[i].state === 'SIGNED') {
                        y++;
                    } else if (documentStates[i].state === 'CAN_NOT_BE_SIGNED') {
                        z++;
                    }
                }
                if (
                    unsignedCount !== x ||
                    signedCount !== y ||
                    canNotBeSignedCount !== z
                ) {
                    if (isMounted) {
                        setUnsignedCount(x);
                        setSignedCount(y);
                        setCanNotBeSignedCount(z);
                    }
                }
                if (x > 0) {
                    setTimeout(esignCheckDocumentStates, 1000);
                    return;
                }
                setTimeout(props.onFinished, 2000, z === 0);
            },
            // onfailure
            (jqXHR, textStatus) => {
                // NOP
            }
        );
    };

    return (
        <div>
            <div>{translate('modals.signing.information.progressBarSigned')}:</div>
            <Progress strokeColor={'#95C11F'} percent={documentIds.length > 0 ? Math.round((signedCount * 100) / documentIds.length) : 0} />
        </div>
    );
}

ESignCheckDocumentStates.propTypes = {
    documentIds: PropTypes.array.isRequired,
    start: PropTypes.bool.isRequired,
    onFinished: PropTypes.func.isRequired
};
