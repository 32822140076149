/* eslint-disable no-case-declarations */
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import update from 'immutability-helper';

export default function (state = initialState.documentList, action) {
    switch (action.type) {
        case types.GET_CONTAINER_DOCUMENTS_SUCCESS:
            return { ...state, documents: action.payload };
        case types.GET_CONTAINER_DOCUMENTS_FAILURE:
            return { ...state, documents: [], signatures: [] };
        case types.UPLOAD_DOCUMENT_SUCCESS:
            return { ...state, documents: action.payload };
        case types.FETCH_DOCUMENT_DELETE_SUCCESS:
            const docId = action.payload.documentId;
            let i;
            let reducedDocuments = [];
            for (i = 0; i < [...state.documents].length; ++i) {
                if ([...state.documents][i].data.id !== docId) {
                    reducedDocuments.push([...state.documents][i]);
                }
            }
            /* We should also delete the signatures of that document */
            let newSigCont = state.signatures.slice();
            newSigCont.splice(action.payload.documentIndex, 1);
            return {
                ...state,
                documents: reducedDocuments,
                signatures: newSigCont,
            };
        case types.GET_DOCUMENT_SIGNATURES_SUCCESS:
            return {
                ...state,
                signatures: action.payload,
            };
        case types.GET_DOCUMENT_SIGNATURES_FAILURE:
            return {
                ...state,
                signatures: [],
            };
        case types.RENAME_DOCUMENT_SUCCESS:
            //Documents in asic
            if (state.documents[action.index].data.id === state.documents[action.index].data.title) {
                return update(state, {
                    documents: {
                        [action.index]: {
                            data: {
                                id: { $set: action.title },
                                title: { $set: action.title },
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    documents: {
                        [action.index]: {
                            data: {
                                title: { $set: action.title },
                            },
                        },
                    },
                });
            }
        case types.NEW_CONTAINER_SUCCESS:
        case types.UPLOAD_CONTAINER_SUCCESS:
            return {
                ...state,
                signatures: [],
                documents: [],
            };
        case types.RESET_DOCUMENT_LIST:
            return {
                ...state,
                documents: [],
                signatures: [],
            };
        default:
            return state;
    }
}
