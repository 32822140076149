export const TAB_KEY_DEFAULT_CONTAINERS = 'DefaultContainers';
export const TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS = 'ContainersInSignatureProcess';

export const WORKFLOW_ROLE_SIGNER = "SIGNER";
export const WORKFLOW_ROLE_APPROVER = "APPROVER";


export const SIGNING_TYPE_SIGN = "SIGNATURE"
export const SIGNING_TYPE_COUNTER_SIGN = "COUNTERSIGNATURE"
export const SIGNING_TYPE_CREATE_EACKNOWLEDGEMENT = "ACKNOWLEDGEMENT"
