import DescriptionItem from './DescriptionItem';
import React from 'react';
import { Card, Avatar, Badge, Empty, Row } from 'antd';
import { getTranslate } from 'react-localize-redux';
import { iconTypeFromType, formatDate, extensionFromContainerType } from '../helper';
import { useSelector } from 'react-redux';

export default function SelectedContainerList() {
    const selectedContainers = useSelector((state) => state.containerList.selectedContainers);
    const translate = useSelector((state) => getTranslate(state.locale));

    const containerList = selectedContainers.map((container, index) => (
        <Card
            key={index}
            style={{ width: 300, marginTop: 16 }}>
            <Card.Meta
                avatar={
                    <Badge count={extensionFromContainerType(container.data.type)}>
                        <Avatar
                            icon={iconTypeFromType(container.data.type)}
                            shape={'square'}
                            size={80}
                        />
                    </Badge>
                }
                title={<span title={container.data.fileBaseName}>{container.data.fileBaseName}</span>}
                description={
                    <DescriptionItem
                        content={formatDate(container.data.creationDate)}
                        title={translate('attributes.creationDate')}
                    />
                }
            />
        </Card>
    ));

    return selectedContainers.length > 0 ? (
        <Row type={'flex'}>{containerList}</Row>
    ) : (
        <Empty
            style={{ marginTop: 20 }}
            description={<span>{translate('containerNotSelected')}</span>}
        />
    );
}
