import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { ajax } from '../helper';

class CheckStatusMultiple extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingCount: 0,
            intervalId: ''
        };
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.start !== this.props.start && this.props.start) {
            const myInterval = setInterval(() => {
                this.checkStatusMultiple();
            }, 1000);
            this.setState({ intervalId: myInterval });
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.start) {
            clearInterval(this.state.intervalId);
        }
    }

    checkStatusMultiple() {
        const { containerIds, sessionId } = this.props;
        ajax()
            .get('/containers/status?sessionId=' + sessionId)
            .then((response) => {
                const containers = response.data;
                if (containerIds.length !== containers.length) {
                    throw new Error('Something went wrong during status checking.');
                }
                let numberOfPendingContainers = 0;
                for (let i = 0; i < containers.length; i++) {
                    let container = containers[i];
                    if (container.status === 'pending') {
                        numberOfPendingContainers++;
                    }
                }
                if (containers.length === numberOfPendingContainers) {
                    clearInterval(this.interval);
                }
                if (this.state.pendingCount !== numberOfPendingContainers) {
                    this._isMounted && this.setState({ pendingCount: numberOfPendingContainers });
                }
            })
            .catch(() => {
                // NOP
            }).finally(() => {
            clearInterval(this.state.intervalId);
        });
    }

    render() {
        const { translate, containerIds } = this.props;
        const { pendingCount } = this.state;

        return (
            <div>
                <div>{translate('modals.signing.information.progressBarPending')}:</div>
                <Progress strokeColor={'#95C11F'}
                          percent={containerIds.length > 0 ? Math.round((pendingCount * 100) / containerIds.length) : 0}
                />
            </div>
        );
    }
}

CheckStatusMultiple.propTypes = {
    translate: PropTypes.func.isRequired,
    containerIds: PropTypes.array.isRequired,
    sessionId: PropTypes.string,
    start: PropTypes.bool.isRequired,
};

export default CheckStatusMultiple;
