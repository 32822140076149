import PropTypes from 'prop-types';
import React from 'react';
import { Card, Avatar, Icon } from 'antd';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const { Meta } = Card;

export class CompanySignError extends React.Component {
    render() {
        const { translate, signedPDfNames } = this.props;
        return (
            <Card className="myCard">
                <Meta
                    avatar={<Avatar size={50} icon={<Icon type="exclamation" />} />}
                    title={<div className="cardTitle">{translate('company.sign.error.title')}</div>}
                    description={
                        <div>
                            <p>{translate('company.sign.error.description')}</p>
                            {signedPDfNames &&
                                signedPDfNames.map((name, index) => {
                                    return <p key={index}>- {name}.pdf</p>;
                                })}
                        </div>
                    }
                />
            </Card>
        );
    }
}

CompanySignError.propTypes = {
    modalData: PropTypes.object,
    signedPDfNames: PropTypes.array,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

export default connect(mapStateToProps)(CompanySignError);
