import DescriptionItem from '../DescriptionItem';
import DocumentIcon from '../document/DocumentIcon';
import PropTypes from 'prop-types';
import React from 'react';
import SignatureTag from '../document/SignatureTag';
import theme from '../../theme';
import { PDF_EMBEDDED_FILES } from '../../constants/modalNames';
import { Button, Col, Icon, Row } from 'antd';
import { downloadCommentDocument } from '../../actions/document';
import {
    extensionFromContainerType,
    formatBytes,
    formatDate
} from '../../helper';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../actions/modal';
import WorkflowComments from '../WorkflowComments';

function ContainerDetails(props) {
    const { container, openSignatureDetails, signatures } = props;

    const translate = useSelector((state) => getTranslate(state.locale));

    const dispatch = useDispatch();

    return (
        <div style={{ background: theme.documentListBg, padding: '24px 24px 8px 24px' }}>
            <Row justify={'space-between'}>
                <Col
                    xs={24}
                    sm={22}
                    style={{ overflowWrap: 'anywhere' }}>
                    <h2 style={{ fontSize: 24 }}>{container.fileBaseName}</h2>
                </Col>
                <Col
                    xs={24}
                    sm={2}>
                    <DocumentIcon type={extensionFromContainerType(container.type)} />
                </Col>
            </Row>
            <Row justify={'start'}>
                <Col
                    xs={24}
                    lg={12}>
                    <DescriptionItem
                        content={formatBytes(container.size)}
                        title={translate('attributes.size')}
                    />
                </Col>
                <Col
                    xs={24}
                    lg={12}>
                    <DescriptionItem
                        content={formatDate(container.creationDate, translate('language'))}
                        title={translate('attributes.creationDate')}
                    />
                </Col>
                {signatures && signatures.length > 0 && (
                    <Col
                        xs={24}
                        lg={12}>
                        {signatures.map((nextSigList, index) => {
                            return (
                                <DescriptionItem
                                    content={
                                        nextSigList.length &&
                                        nextSigList.map((signature, index) => {
                                            return (
                                                <SignatureTag
                                                    key = { index }
                                                    signature = { signature }
                                                    downloadAttachment = {
                                                        () => dispatch(downloadCommentDocument(container.id, null, signature.id))
                                                    }
                                                    onOpenSignatureDetails = {
                                                        () => openSignatureDetails(signature)
                                                    } />
                                            );
                                        })
                                    }
                                    title={
                                        index % 2 === 0
                                            ? translate('titles.signatureDetails')
                                            : translate('titles.countersignatureDetails')
                                    }
                                    key={index}
                                />
                            );
                        })}
                    </Col>
                )}
                {container.processStarter && (
                    <Col
                        xs={24}
                        lg={12}>
                        <DescriptionItem
                            content={formatDate(container.processStarter, translate('language'))}
                            title={translate('attributes.createdBy')}
                        />
                    </Col>
                )}
                {container.pdfEmbeddedFiles && container.pdfEmbeddedFiles.length > 0 && (
                    <Col
                        xs={24}
                        lg={12}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(toggleModal(PDF_EMBEDDED_FILES, container));
                            }}
                            size={'small'}
                            style={{ marginTop: '10px' }}>
                            <Icon type="link" />
                            {translate('modals.attachments')}
                        </Button>
                    </Col>
                )}
                {container.workflowGuid && (
                    <Col xs={24}>
                        <WorkflowComments workflowGuid={container.workflowGuid} />
                    </Col>
                )}
            </Row>
        </div>
    );
}

ContainerDetails.propTypes = {
    container: PropTypes.object.isRequired,
    openSignatureDetails: PropTypes.func,
    signatures: PropTypes.array
};

export default ContainerDetails;
