import DeleteButton from './DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import { REFUSE_SIGNATURE } from '../../constants/modalNames';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux/lib/index';
import { makeGetFirstFromRemainingContainers } from './../../selectors/index';
import { toggleModal } from '../../actions/modal';

function RefuseSignButton(props) {
    const { buttonText, disabled } = props;

    const getFirstFromRemainingContainers = makeGetFirstFromRemainingContainers();
    const newActiveContainer = useSelector((state) => getFirstFromRemainingContainers(state, props));
    const selectedContainerIDs = useSelector((state) => state.containerList.selectedContainers.map(container => container.data.id));
    const translate = useSelector((state) => getTranslate(state.locale));

    const dispatch = useDispatch();

    function buttonClickAction() {
        dispatch(
            toggleModal(REFUSE_SIGNATURE, {
                containerId: selectedContainerIDs,
                newActiveContainerId: newActiveContainer
            })
        );
    }

    return (
        <DeleteButton
            disabled={disabled}
            icon={'stop'}
            id={'refuse-signature-button'}
            key={'refuse-signature-button'}
            onClick={() => buttonClickAction()}
            style={{ background: '#ffffff', color: '#ff4d4f' }}
            title={buttonText || translate('buttons.delete.containerToSign')}
        />
    );
}

RefuseSignButton.propTypes = {
    buttonText: PropTypes.string,
    disabled: PropTypes.bool.isRequired
};

export default RefuseSignButton;
