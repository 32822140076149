import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonDocumentPage = ({ isPDF }) => {
    return (
        <SkeletonTheme>
            <section>
                <div style={{ padding: "8px" }}>
                    <div style={{ marginTop: "7px" }}>
                        <Skeleton height={40} width={`28%`} />
                    </div>
                    <div>
                        <Skeleton height={isPDF ? 200 : 180} />
                    </div>
                    <div>
                        <Skeleton height={isPDF ? 65 : 40} width={`100%`} />
                    </div>
                    {isPDF &&
                    <div>
                        <Skeleton height={400} width={`100%`} />
                    </div>}
                </div>
            </section>
        </SkeletonTheme>
    );
};

export default SkeletonDocumentPage;
