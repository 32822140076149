import ChangeLanguage from './ChangeLanguage';
import React from 'react';
import PropTypes from 'prop-types';
import authService from '../services/authService';
import { Menu, Icon, Badge, Popover, Row, Avatar, Empty } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { createNotification, getDocuments } from '../helper';
import { isEmpty } from 'lodash';
import { getDeputy } from './../actions/deputy';
import { getTranslate } from 'react-localize-redux';
import { toggleModal } from '../actions/modal';

class MenuMarkup extends React.Component {
    componentDidMount() {
        const { getDeputyAction, translate } = this.props;
        if (authService.isRegistered() && authService.hasJWT() && window.config.REACT_APP_DEPUTY_FUNCTION_AVAILABLE) {
            getDeputyAction().catch((e) => {
                createNotification(translate, e.response.data.code, {
                    message: translate('notifications.getDeputy.error.message'),
                    type: 'error'
                });
            });
        }
    }

    deputyNoty = () => {
        const { deputy, translate } = this.props;

        if (isEmpty(deputy) || isEmpty(deputy.data)) {
            return <Empty description={<span>{translate('noNotification')}</span>} />;
        } else {
            return (
                <React.Fragment>
                    {!isEmpty(deputy.data.currentUsersDeputy) && (
                        <Row
                            type="flex"
                            style={{ marginTop: 12 }}>
                            <Avatar
                                size={50}
                                shape="square"
                                icon="usergroup-add"
                                style={{ marginRight: 10 }}
                            />
                            <div style={{ marginBottom: 5 }}>
                                <p style={{ marginBottom: 5 }}>
                                    {translate('notificationDeputyText', {
                                        email: deputy.data.currentUsersDeputy.deputyEmail
                                    })}
                                </p>
                                <p>
                                    <Link to="/settings#deputy">{translate('notificationDeputyModifyLink')}</Link>
                                </p>
                            </div>
                        </Row>
                    )}
                    {!isEmpty(deputy.data.currentUserIsDeputyTo) &&
                        deputy.data.currentUserIsDeputyTo.map((deputyInfo) => (
                            <Row
                                key={deputyInfo.deputyEmail}
                                type="flex"
                                style={{ marginTop: 12 }}>
                                <Avatar
                                    size={50}
                                    shape="square"
                                    icon="usergroup-add"
                                    style={{ marginRight: 10 }}
                                />
                                <div style={{ marginBottom: 10 }}>
                                    <p style={{ marginBottom: 5 }}>
                                        {translate('notificationDeputyToText', { email: deputyInfo.userEmail })}
                                    </p>
                                </div>
                            </Row>
                        ))}
                </React.Fragment>
            );
        }
    };

    showNoProfile() {
        let user = localStorage.getItem('user');
        return (
            user !== null &&
            user !== '' &&
            localStorage.getItem('profileCode') === 'registered' &&
            authService.getIsMicrosecOIDEnabled()
        );
    }

    render() {
        const { translate, activeLinkKey, lightStyle, mobileVersion, deputy } = this.props;
        return (
            <Menu
                theme={lightStyle || mobileVersion ? 'light' : 'dark'}
                mode={mobileVersion ? 'vertical' : 'horizontal'}
                selectedKeys={[`${activeLinkKey}`]}
                style={{ border: 0 }}>
                {this.showNoProfile() && (
                    <Menu.Item key="profileName">
                        {' '}
                        <a
                            href={getDocuments('order', localStorage.getItem('language'))}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate('noProfileName')}
                        </a>
                    </Menu.Item>
                )}
                {authService.isRegistered() && (
                    <Menu.SubMenu
                        title={
                            <span>
                                <Icon
                                    type="user"
                                    style={{ fontSize: 20 }}
                                />
                                {authService.getUserEmail()}
                                <Icon
                                    type="down"
                                    theme="outlined"
                                    style={{ fontSize: 10, marginLeft: 8 }}
                                />
                            </span>
                        }>
                        <Menu.Item key="settings">
                            <Link to="/settings">
                                <Icon type="setting" />
                                {translate('header.links.settings.text')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="bulkSigning">
                            <Link to="/bulkSigning">
                                <Icon type="bars" />
                                {translate('header.links.tools.massSign')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="waitingToSignByOthers">
                            <Link to="/waitingToSignByOthers">
                                <Icon type="reconciliation" />
                                {translate('header.links.waitingtosignbyothers')}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="logout">
                            <Link to="/logout">
                                <Icon type="logout" />
                                {translate('header.links.logout.text')}
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )}
                {authService.isRegistered() && window.config.REACT_APP_DEPUTY_FUNCTION_AVAILABLE && (
                    <Menu.Item
                        key="notification"
                        style={{ border: 'none' }}>
                        <Popover
                            placement="bottomRight"
                            title={translate('notificationTitle')}
                            content={this.deputyNoty()}
                            trigger="hover">
                            <Badge dot={isEmpty(deputy) || isEmpty(deputy.data) ? false : true}>
                                <Icon
                                    type="message"
                                    style={{ fontSize: 20, margin: '0 10px 0 0' }}
                                />
                                {translate('notificationTitle')}
                                <Icon
                                    type="down"
                                    theme="outlined"
                                    style={{ fontSize: 10, marginLeft: 8 }}
                                />
                            </Badge>
                        </Popover>
                    </Menu.Item>
                )}
                <Menu.Item
                    key="lang"
                    style={{ border: 'none' }}>
                    <ChangeLanguage
                        isBgLight={lightStyle || mobileVersion}
                        block={mobileVersion}
                    />
                </Menu.Item>
            </Menu>
        );
    }
}

MenuMarkup.propTypes = {
    translate: PropTypes.func.isRequired,
    activeLinkKey: PropTypes.string.isRequired,
    lightStyle: PropTypes.bool.isRequired,
    mobileVersion: PropTypes.bool,
    onSubMenuItemClick: PropTypes.func,
    showMassSignModal: PropTypes.func.isRequired,
    getDeputyAction: PropTypes.func.isRequired,
    deputy: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        deputy: state.deputy,
        translate: getTranslate(state.locale),
        updateCounter: state.menuMarkup.updateCounter
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showMassSignModal: () => {
            dispatch(toggleModal('MASS_SIGN'));
        },
        getDeputyAction: () => {
            return dispatch(getDeputy());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMarkup);
