import { RESET_MENU_MARKUP, UPDATE_MENU_MARKUP } from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.menuMarkup, action) {
    switch (action.type) {
        case UPDATE_MENU_MARKUP:
            return { ...state, updateCounter: state.updateCounter + 1 };
        case RESET_MENU_MARKUP:
            return { ...state, updateCounter: 0 };
        default:
            return state;
    }
}
