import CustomAlert from '../CustomAlert';
import FormItem from '../form/FormItem';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { createNotificationShort } from '../../helper';
import { forgottenPassword } from '../../actions/user';
import { getActiveLanguage, getTranslate } from 'react-localize-redux/lib/index';
import { Button, Form, Icon, Input } from 'antd';
import { removeModal } from '../../actions/modal';

class ForgottenPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        const { form } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                this.forgottenPasswordAction(values.inputValue).catch((err) => {
                    this._isMounted && this.setState({ serverError: err });
                });
            }
        });
    };

    forgottenPasswordAction(email) {
        const { action, currentLanguage, translate } = this.props;
        return action(email, currentLanguage)
            .then(() => {
                createNotificationShort(translate, {
                    message: translate('notifications.forgottenPasswordURLSent.message'),
                    type: 'success',
                });
            })
            .catch((error) => {
                return Promise.reject(error.response.data.error);
            });
    }

    render() {
        const { translate, title } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { serverError } = this.state;
        return (
            <span>
                <ModalWrapper {...this.props} title={translate('modals.forgottenPassword.title')}>
                    <p>{translate('modals.forgottenPassword.subTitle')}</p>
                    {serverError && <CustomAlert error={serverError} />}
                    <Form onSubmit={this.handleSubmit}>
                        <FormItem>
                            {getFieldDecorator('inputValue', {
                                rules: [
                                    { required: true, message: translate('form.required') },
                                    { type: 'email', message: translate('login.validateEmail') },
                                ],
                                normalize: (input) => (input !== undefined ? input.toLowerCase() : ''),
                            })(<Input prefix={<Icon type="mail" />} placeholder={title} />)}
                        </FormItem>
                        <FormItem>
                            <Button type="primary" block htmlType="submit">
                                OK
                            </Button>
                        </FormItem>
                    </Form>
                </ModalWrapper>
            </span>
        );
    }
}

ForgottenPasswordModal.propTypes = {
    action: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    title: PropTypes.string,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        action: (email, lang) => {
            ReactGA.event({
                category: 'User',
                action: 'Forgotten Password',
            });
            return dispatch(forgottenPassword(email, lang)).then(() => {
                dispatch(removeModal());
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ForgottenPasswordModal));
