import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../theme';
import { Button, Tree, Input } from 'antd';
import { connect } from 'react-redux';
import { createNotificationShort } from '../../../helper';

class UploadCegeljarasModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            docTitle: 'A kérelem formanyomtatványa',
            uploading: false,
            expandedKeys: ['0'],
            selectedKey: ['0-0'],
            searchValue: '',
            autoExpandParent: true,
            dataList: [],
        };
        this.dataListProp = [];
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { documentTitles } = this.props;
        this.generateList(documentTitles);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    generateList = (data) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            const key = node.key;
            this.dataListProp[key] = node.displayTitle;
            if (node.children) {
                this.generateList(node.children);
            }
        }
        this._isMounted && this.setState({ dataList: this.dataListProp });
    };

    onExpand = (expandedKeys) => {
        this._isMounted && this.setState({ expandedKeys, autoExpandParent: false });
    };

    getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (this.getParentKey(key, node.children)) {
                    parentKey = this.getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    onChange = (e) => {
        const { dataList } = this.state;
        const value = e.target.value;
        const expandedKeys = Object.keys(dataList)
            .map((item) => {
                if (dataList[item].toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return this.getParentKey(item, this.props.documentTitles);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        this._isMounted && this.setState({ expandedKeys, searchValue: value, autoExpandParent: true });
    };

    handleUpload = () => {
        const { docTitle } = this.state;
        this._isMounted && this.setState({ uploading: true });
        const fileExt = this.props.modalData.fileName.split('.').pop();
        const edossierTitles = docTitle.includes('költség') || docTitle.includes('illeték');
        const allowedExtension = ['txt', 'xml', 'pdf', 'es3', 'nldossier', 'dossier', 'dosszie', 'x132'].includes(
            fileExt
        );
        if (
            (docTitle === 'A kérelem formanyomtatványa' && fileExt !== 'xml') ||
            !allowedExtension ||
            (edossierTitles && !['es3', 'nldossier', 'dossier', 'dosszie', 'x132'])
        ) {
            createNotificationShort(this.props.translate, {
                description: this.props.translate('notifications.uploadFailure.badFormat', { fileName: docTitle }),
                message: this.props.translate('notifications.uploadFailure.message'),
                type: 'error',
            });
        } else {
            this.props.modalData.setTitle(docTitle);
        }

        this._isMounted &&
            this.setState({ docTitle: 'A kérelem formanyomtatványa', expandedKeys: ['0'], selectedKey: ['0-0'] });

        this.props.toggleModal(this.props.modalName);
    };

    renderTreeNodes = (data) => {
        return data.map((item) => {
            const index = item.displayTitle.toLowerCase().indexOf(this.state.searchValue.toLowerCase());
            const highlightedStr = item.displayTitle.substr(index, this.state.searchValue.length);
            const beforeStr = item.displayTitle.substr(0, index);
            const afterStr = item.displayTitle.substr(index + this.state.searchValue.length);
            const title =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span style={{ color: theme.errorColor, background: theme.modalTextHighlightColor}}>
                            {highlightedStr}
                        </span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{item.displayTitle}</span>
                );

            if (item.children) {
                return (
                    <Tree.TreeNode
                        title={title}
                        key={item.key}
                        selectable={item.children.length === 0}
                        selected
                        dataRef={item}
                    >
                        {this.renderTreeNodes(item.children)}
                    </Tree.TreeNode>
                );
            }
            return <Tree.TreeNode key={item.key} {...item} selectable={true} dataRef={item} />;
        });
    };

    onSelect = (selectedKeys) => {
        const { dataList } = this.state;
        if (selectedKeys.length > 0) {
            this._isMounted && this.setState({ docTitle: dataList[selectedKeys[0]], selectedKey: selectedKeys });
        } else {
            this._isMounted && this.setState({ docTitle: '', selectedKey: [] });
        }
    };

    render() {
        const { translate, uploading } = this.props;
        return (
            <ModalWrapper {...this.props} width={800} title={translate('modals.uploadDocuments.title')}>
                <div className="modal-width">
                    <Input.Search
                        style={{ marginBottom: 10 }}
                        placeholder={this.props.translate('modals.UploadCegeljaras.search')}
                        onChange={this.onChange}
                    />
                    <Tree
                        defaultExpandedKeys={['0']}
                        onSelect={this.onSelect}
                        onExpand={this.onExpand}
                        expandedKeys={this.state.expandedKeys}
                        autoExpandParent={this.state.autoExpandParent}
                        defaultSelectedKeys={this.state.selectedKey}
                    >
                        {this.renderTreeNodes(this.props.documentTitles)}
                    </Tree>
                </div>
                <Button
                    disabled={this.state.docTitle === ''}
                    type="primary"
                    onClick={this.handleUpload}
                    loading={uploading}
                >
                    {translate('buttons.upload')}
                </Button>
            </ModalWrapper>
        );
    }
}

UploadCegeljarasModal.propTypes = {
    documentTitles: PropTypes.array,
    modalData: PropTypes.object,
    modalName: PropTypes.string,
    uploading: PropTypes.bool,
    toggleModal: PropTypes.func,
    translate: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        documentTitles: state.documentTitles,
    };
};

export default connect(mapStateToProps)(UploadCegeljarasModal);
