import React from 'react';
import { Alert } from 'antd';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

export default function Banner() {
    const translate = useSelector((state) => getTranslate(state.locale));

    function closeBannerAndRememberIt() {
        localStorage.setItem('shouldShowBanner', 'no');
    }

    if (window.config.REACT_APP_BANNER_DAYS !== 0 && localStorage.getItem('shouldShowBanner') !== 'no') {
        return (
            <Alert
                banner={true}
                closable={true}
                message={translate('banner.deleteFilesAfter', { days: window.config.REACT_APP_BANNER_DAYS })}
                onClose={closeBannerAndRememberIt}
                style={{ fontWeight: 'bold' }}
                type={'info'}
            />
        );
    }

    return null;
}
