import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function TextFileReader(props) {
    const { file } = props;

    const [text, setText] = useState('');

    useEffect(() => {
        readTextFile(file);
    }, [file]);

    function readTextFile(file) {
        var rawFile = new XMLHttpRequest();
        rawFile.open('GET', file, false);
        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4) {
                if (rawFile.status === 200 || rawFile.status === 0) {
                    setText(rawFile.responseText);
                }
            }
        };
        rawFile.send(null);
    }

    return (
        <pre style={{ fontFamily: 'inherit', margin: '16px 0px 0px 0px', whiteSpace: 'pre-wrap' }}>
            {text}
            <br />
        </pre>
    );
}

TextFileReader.propTypes = {
    file: PropTypes.string.isRequired
};
