import CompleteLoginForm from './CompleteLoginForm';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import Registration from './Registration';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteStoredFileId } from '../actions/container';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { createNotification, getHistory } from '../helper';
import { resetIsPassword } from '../actions/confirm';
import { toggleModal } from '../actions/modal';
import { Tabs, Spin } from 'antd';
import * as userActions from '../actions/user';
import * as modalActions from '../actions/modal';
import { NOTIFY_USER } from '../constants/modalNames';

export class AuthenticationTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this.props.resetIsPassword();
    }

    basicRegistration = (post) => {
        ReactGA.event({
            category: 'User',
            action: 'Registration'
        });
        const { actions } = this.props;
        return actions
            .registration(Object.assign(post))
            .then(() => {
                getHistory().push('/confirm');
            })
            .catch((err) => {
                if (err.response.data.detail.includes('already')) {
                    createNotification(this.props.translate, err.response.data.code, {
                        message: this.props.translate('registration.error.already'),
                        type: 'error'
                    });
                }
            });
    };

    render() {
        const { currentLanguage, deleteStoredFileId, embedded, storedFileId, translate } = this.props;
        const TabPane = Tabs.TabPane;

        return (
            <Spin spinning={this.state.loading}>
                <Tabs defaultActiveKey="1">
                    <TabPane
                        tab={translate('login.title')}
                        key="1">
                        <CompleteLoginForm
                            currentLanguage={currentLanguage}
                            deleteStoredFileId={deleteStoredFileId}
                            embedded={embedded}
                            showForgottenPassword={true}
                            showSocialOptions={true}
                            storedFileId={storedFileId}
                            isAdminLogin={false}
                        />
                    </TabPane>
                    <TabPane
                        tab={translate('registration.title')}
                        key="2">
                        <Registration
                            submitFunction={this.basicRegistration}
                            translate={translate}
                            currentLanguage={currentLanguage}
                        />
                    </TabPane>
                </Tabs>
            </Spin>
        );
    }
}

AuthenticationTabs.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLanguage: PropTypes.string.isRequired,
    deleteStoredFileId: PropTypes.func.isRequired,
    embedded: PropTypes.bool,
    notifyUserModal: PropTypes.func.isRequired,
    storedFileId: PropTypes.string.isRequired,
    resetIsPassword: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        storedFileId: state.containerList.storedFileIdForDownload,
        currentLanguage: getActiveLanguage(state.locale).code
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...userActions, ...modalActions }, dispatch),
        resetIsPassword: () => {
            return dispatch(resetIsPassword());
        },
        notifyUserModal: (result) => {
            if (result) {
                dispatch(toggleModal(NOTIFY_USER));
            }
        },
        deleteStoredFileId: () => {
            return dispatch(deleteStoredFileId());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationTabs);
