import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { createNotification, createNotificationShort, iconTypeFromType } from '../helper';
import { Button, Modal, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import { validateSignature } from '../actions/container';
import { getTranslate } from 'react-localize-redux/lib/index';
import { filter, includes, isEmpty } from 'lodash';

class AckVerify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedIndex: '0',
            selectedContainerId: '',
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showModal = () => {
        this._isMounted &&
            this.setState({
                visible: true,
                selectedContainerId: this.props.containers.containers[0].data.id,
            });
    };

    hideModal = () => {
        this._isMounted && this.setState({ visible: false, selectedIndex: '0', selectedContainerId: '' });
    };
    handleOk = () => {
        this.hideModal();
        this.validateAcknowledgementWithRef(this.state.selectedContainerId);
    };
    handleCancel = () => {
        this.hideModal();
    };

    onModalSelect(key, selectedContainerId) {
        this._isMounted &&
            this.setState({
                selectedIndex: key.toString(),
                selectedContainerId: selectedContainerId,
            });
    }

    validateAcknowledgement() {
        this.validateAcknowledgementWithRef(null);
    }

    validateAcknowledgementWithRef(ref) {
        ReactGA.event({
            category: 'User',
            action: 'Acknowledgement Validation',
        });

        let queryParam = ref === (undefined || null) ? '' : ref;
        
        this.props
            .validateAcknowledgementAction(this.props.containers.selectedContainers[0].data.id, queryParam)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('checkAcknowledgement.success'),
                    type: 'success',
                });
            })
            .catch((error) => {
                if (error.response.data.code === 'USER_ACK_NO_REFERENCE') {
                    this.showModal();
                    return;
                }
                createNotification(this.props.translate, error.response.data.code, {
                    message: this.props.translate('checkAcknowledgement.failure'),
                    type: 'error',
                });
            });
    }

    ackReferenceList() {
        const { containers, selectedContainers } = this.props.containers;
        return filter(containers, (container) => {
            return !includes(selectedContainers.map(cont => cont.data.id), container.data.id);
        });
    }

    render() {
        const { translate, isDrawer } = this.props;
        return (
            <span>
                {isDrawer === true ? (
                    <span>
                        {translate('checkAcknowledgement.button')}
                        <Button
                            style={{ float: 'right' }}
                            size={'small'}
                            type={'primary'}
                            title={translate('checkAcknowledgement.button')}
                            onClick={() => {
                                this.validateAcknowledgement();
                            }}
                            icon="check"
                        />
                    </span>
                ) : (
                    <Button type={'primary'} onClick={() => this.validateAcknowledgement()}>
                        <Icon type={'check'} />
                        {translate('checkAcknowledgement.button')}
                    </Button>
                )}

                <Modal
                    title={translate('checkAcknowledgement.reference')}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    cancelText={translate('checkAcknowledgement.cancel')}
                    okButtonProps={{ disabled: isEmpty(this.state.selectedIndex) }}
                >
                    <Menu mode="inline" selectable style={{ border: 0 }} selectedKeys={[this.state.selectedIndex]}>
                        {this.ackReferenceList().map((container, index) => (
                            <Menu.Item
                                key={index}
                                onClick={(item) => {
                                    this.onModalSelect(item.key, container.data.id);
                                }}
                            >
                                <Icon type={iconTypeFromType(container.data.type)} style={{ fontSize: 25 }} />
                                <span>{container.data.fileBaseName}</span>
                            </Menu.Item>
                        ))}
                    </Menu>
                </Modal>
            </span>
        );
    }
}

AckVerify.propTypes = {
    containers: PropTypes.object,
    isDrawer: PropTypes.bool,
    translate: PropTypes.func,
    validateAcknowledgementAction: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        containers: state.containerList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        validateAcknowledgementAction: (id, param) => {
            return dispatch(validateSignature(id, null, param));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AckVerify);
