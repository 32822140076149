import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row, InputNumber } from 'antd';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { sendGenerationData } from '../../actions/admin';

class PdfGeneration extends React.Component {
    state = {
        tNumber: 1,
        iNumber: 1
    };

    setTNumber = (num) => {
        this.setState({ tNumber: num });
    };
    setINumber = (num) => {
        this.setState({ iNumber: num });
    };

    render() {
        const { translate } = this.props;
        return (
            <div>
                <Row type='flex' align='middle'>
                    <Col sm={4} md={4} lg={2}>
                        <div>Szálak száma:</div>
                    </Col>
                    <Col sm={8} md={8} lg={6}>
                        <InputNumber size='large' min={1} max={100} defaultValue={1} onChange={this.setTNumber} />
                    </Col>
                    <Col sm={4} md={4} lg={2}>
                        <div>Képek száma:</div>
                    </Col>
                    <Col sm={8} md={8} lg={6}>
                        <InputNumber size='large' min={1} max={100} defaultValue={1} onChange={this.setINumber} />
                    </Col>
                    <Col>
                        <Button onClick={() => this.props.sendGenerationDataAction(Object.assign({
                            threads: this.state.tNumber, images: this.state.iNumber
                        }))}>
                            {translate('buttons.send')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

PdfGeneration.propTypes = {
    history: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendGenerationDataAction: (data) => {
            return dispatch(sendGenerationData(data));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfGeneration);
