import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { changeLanguage } from '../actions/user';
import { connect } from 'react-redux';
import { getActiveLanguage, setActiveLanguage, getLanguages } from 'react-localize-redux/lib/index';
import { Button, Dropdown, Icon, Menu } from 'antd';

class ChangeLanguage extends React.Component {
    handleMenuClick = (e) => {
        const { changeActiveLanguage } = this.props;
        changeActiveLanguage(e.key);
        localStorage.setItem('language', e.key);
        this.props.changeLanguageAction(e.key).catch(() => {
            // Do nothing - language change without signing in => 401
        });
    };

    render() {
        const { block, isBgLight, languages, activeLanguage } = this.props;
        const menuItems = languages.map((language) => {
            return <Menu.Item key={language.code}>{getLongLanguageName(language.code)}</Menu.Item>;
        });
        const menu = <Menu id={isBgLight ? 'language-menu-light' : 'language-menu-dark'} onClick={this.handleMenuClick}>{menuItems}</Menu>;
        return (
            <Dropdown overlay={menu} trigger={['hover']}>
                <Button
                    id={isBgLight ? 'language-dropdown-light' : 'language-dropdown-dark'}
                    style={{ border: 'none', color: isBgLight ? 'rgb(0, 0, 0)' : 'rgba(255, 255, 255, 0.65)' }}
                    block={block}
                    size={'small'}
                    type={isBgLight ? 'primary' : 'default'}
                    ghost={!isBgLight}
                >
                    <Icon type="flag" style={{ marginRight: 0, fontSize: 20 }} />
                    {getLongLanguageName(activeLanguage.code)}
                    <Icon type="down" style={{ marginRight: 0 }} />
                </Button>
            </Dropdown>
        );
    }
}

ChangeLanguage.propTypes = {
    activeLanguage: PropTypes.object.isRequired,
    block: PropTypes.bool,
    changeActiveLanguage: PropTypes.func.isRequired,
    changeLanguageAction: PropTypes.func.isRequired,
    isBgLight: PropTypes.bool,
    languages: PropTypes.array.isRequired,
};

function getLongLanguageName(languageCode) {
    switch (languageCode) {
        case 'hu':
            return 'Magyar';
        case 'en':
            return 'English';
        case 'pl':
            return 'Polskie';
        default:
            return 'English';
    }
}

function mapStateToProps(state) {
    return {
        activeLanguage: getActiveLanguage(state.locale),
        languages: getLanguages(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeActiveLanguage: (languageCode) => {
            dispatch(setActiveLanguage(languageCode));
        },
        changeLanguageAction: (languageCode) => {
            ReactGA.event({
                category: 'User',
                action: 'ChangeLanguage',
            });
            return dispatch(changeLanguage({ languageCode }));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLanguage);
