import PropTypes from 'prop-types';
import React from 'react';
import { Card, Avatar, Icon } from 'antd';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { getDocuments } from '../helper';

const { Meta } = Card;

export class UserProfileError extends React.Component {
    render() {
        const { translate, currentLanguage, modalData } = this.props;
        return (
            <Card className="myCard">
                <Meta
                    avatar={<Avatar size={50} icon={<Icon type="exclamation" />} />}
                    title={<div className="cardTitle">{translate('user.profile.error.title')}</div>}
                    description={
                        <div>
                            <p>
                                {translate('user.profile.error.description')}{' '}
                                {<a href={getDocuments('price_list', currentLanguage)}>{translate('priceList')}</a>}
                            </p>
                            {modalData &&
                                modalData.locale.map((locale, index) => {
                                    return <p key={index}>{locale}</p>;
                                })}
                        </div>
                    }
                />
            </Card>
        );
    }
}

UserProfileError.propTypes = {
    modalData: PropTypes.object,
    translate: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
    };
}

export default connect(mapStateToProps)(UserProfileError);
