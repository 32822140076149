/* eslint-disable react-hooks/exhaustive-deps */
import BackToHomePageButton from '../buttons/BackToHomePageButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import WebPage from '../WebPage';
import WorkflowDetails from './WorkflowDetails';
import WorkflowList from './WorkflowList';
import WorkflowOperations from './WorkflowOperations';
import WorkflowParticipantOperations from './WorkflowParticipantOperations';
import authService from '../../services/authService';
import { Col, Row, Spin } from 'antd';
import { createNotification } from '../../helper';
import { getTranslate } from 'react-localize-redux';
import { getWaitingToSignByOthers } from '../../actions/container';
import { useDispatch, useSelector } from 'react-redux';

function WorkflowPage(props) {
    const { history } = props;

    const [containsMetadata, setContainsMetadata] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSelectMultipleWorkflow, setIsSelectMultipleWorkflow] = useState(false);
    const [selectedParticipantOrder, setSelectedParticipantOrder] = useState('');
    const [selectedWorkflowGuid, setSelectedWorkflowGuid] = useState('');
    const [selectedWorkflowGuids, setSelectedWorkflowGuids] = useState([]);

    const dispatch = useDispatch();

    const containersInSignatureProcess = useSelector((state) => state.containerList.containersInSignatureProcess);
    const selectedContainers = useSelector((state) => state.containerList.selectedContainers);
    const workflows = useSelector((state) => state.containerList.waitingToSignByOthers);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setIsLoading(true);
        dispatch(getWaitingToSignByOthers())
            .then(() => {
                if (selectedContainers.length > 0) {
                    setSelectedWorkflowGuid(getWorkflowGuidByContainerId(selectedContainers[0]));
                }
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate(`errorCodes.${error.response.data.code}`),
                    type: 'error'
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    function getWorkflowGuidByContainerId(containerId) {
        let container = containersInSignatureProcess.find((c) => c.data.id === containerId);
        if (container) {
            return container.data.workflowGuid;
        }

        return null;
    }

    function getSelectedWorkflow() {
        return workflows.find((workflow) => workflow.guid === selectedWorkflowGuid);
    }

    function isWorkflowStarter() {
        const selected = getSelectedWorkflow();
        if (selected && selected.createdBy === authService.getUserEmail()) {
            return true;
        }
        return false;
    }

    if (workflows.length === 0 && !isLoading) {
        return (
            <WebPage location={history.location}>
                <Row justify={'start'}>
                    <BackToHomePageButton />
                </Row>
                <Row justify={'start'}>
                    <span style={{ fontSize: 20 }}> {translate('workflow.placeholder')}</span>
                </Row>
            </WebPage>
        );
    }

    return (
        <WebPage location={history.location}>
            <Spin
                size={'large'}
                spinning={isLoading}>
                <Row justify={'start'}>
                    <BackToHomePageButton />
                </Row>
                <Row
                    gutter={16}
                    justify={'start'}>
                    <Col
                        xs={24}
                        xxl={14}>
                        <Row style={{ paddingTop: 8 }}>
                            <WorkflowOperations
                                containsMetadata={containsMetadata}
                                deleteDisabled={!isWorkflowStarter() || selectedWorkflowGuids.length !== 0}
                                isMetadataEnabled={window.config.REACT_APP_META_DATA_ENABLED}
                                isSelectMultipleWorkflow={isSelectMultipleWorkflow}
                                selectedWorkflowGuid={selectedWorkflowGuid}
                                selectedWorkflowGuids={selectedWorkflowGuids}
                                setSelectedWorkflowGuids={setSelectedWorkflowGuids}
                                workflows={workflows}
                            />
                        </Row>
                        <Row>
                            <WorkflowList
                                isSelectMultipleWorkflow={isSelectMultipleWorkflow}
                                selectedWorkflowGuid={selectedWorkflowGuid}
                                selectedWorkflowGuids={selectedWorkflowGuids}
                                setContainsMetadata={setContainsMetadata}
                                setIsSelectMultipleWorkflow={setIsSelectMultipleWorkflow}
                                setSelectedWorkflowGuid={setSelectedWorkflowGuid}
                                setSelectedWorkflowGuids={setSelectedWorkflowGuids}
                                workflows={workflows}
                            />
                        </Row>
                    </Col>
                    <Col
                        xs={24}
                        xxl={10}>
                        <Row style={{ paddingTop: 8 }}>
                            <WorkflowParticipantOperations
                                isStarterOfSelectedWorkflow={isWorkflowStarter()}
                                selectedParticipantOrder={selectedParticipantOrder}
                                selectedWorkflowGuid={selectedWorkflowGuid}
                            />
                        </Row>
                        <Row>
                            <WorkflowDetails
                                selectedParticipantOrder={selectedParticipantOrder}
                                selectedWorkflowGuid={selectedWorkflowGuid}
                                setSelectedParticipantOrder={setSelectedParticipantOrder}
                                workflows={workflows}
                            />
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </WebPage>
    );
}

WorkflowPage.propTypes = {
    history: PropTypes.object.isRequired
};

export default WorkflowPage;
