import { createSelector } from '@reduxjs/toolkit';
import { find, filter, includes } from 'lodash';
import { TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS } from '../constants/strings';

/**
 * THIS IS A TEST TO ORGANIZE REDUX SELECTORS
 */
const getFirstContainerId = (state) => state.containerList.selectedContainers[0] ? state.containerList.selectedContainers[0].data.id : '';
const getSelectedContainerIdList = (state) => state.containerList.selectedContainers.map(c => c.data.id);
const getContainers = (state) => state.containerList.containers;
const getContainersInSignatureProcess = (state) => state.containerList.containersInSignatureProcess;
/**
 * NOTICE "currentTab" property need to be passed in order to this to work!
 * @param {*} state
 * @param {*} props
 */
const getContainersByCurrentTab = (state, props) => {
    const { containersInSignatureProcess, containers } = state.containerList;
    switch (state.tabs.current) {
        case TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS:
            return containersInSignatureProcess;
        default:
            return containers;
    }
};

/**
 * Calculate the difference between the current container list and the selected container list.
 * Useful when deleting or signing containers and need to refresh the current active container state.
 *
 * @return the first list element or an empty list.
 */
export const makeGetFirstFromRemainingContainers = () => {
    return createSelector([getContainersByCurrentTab, getSelectedContainerIdList], (containers, selectedIds) => {
        const remainingContainers = filter(containers, (container) => {
            return !includes(selectedIds, container.data.id);
        });
        return remainingContainers.length > 0 ? [remainingContainers[0]] : [];
    });
};

export const makeGetActiveTabContainerById = () => {
    return createSelector(
        [getContainers, getContainersInSignatureProcess, getFirstContainerId],
        (containers, containersInSignatureProcess, selectedContainerId) => {
            const containersInSignatureProcessFound = find(containersInSignatureProcess, {
                data: { id: selectedContainerId },
            });
            if (containersInSignatureProcessFound) {
                return containersInSignatureProcessFound;
            }
            const containerFound = find(containers, {
                data: { id: selectedContainerId },
            });
            if (containerFound) {
                return containerFound;
            }
        }
    );
};
