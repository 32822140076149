/**
 * Certificate selection and signing is done with MicroSigner.
 */
export const SIGN_MODE_MICRO_SIGNER = "sign";

/**
 * Certificate selection and signing is done with using push notification provided by PassByME.
 */
export const SIGN_MODE_PUSH_NOTIFICATION = "signUsingPush";

/**
 * Certificate selection and signing is done with using QR code provided by PassByME.
 */
export const SIGN_MODE_QR_CODE = "signUsingQRCode";

export function isPassByMeRelatedSignMode(signMode) {
    switch (signMode) {
        case SIGN_MODE_PUSH_NOTIFICATION:
        case SIGN_MODE_QR_CODE:
            return true;

        default:
            return false;
    }
}
