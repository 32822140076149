import CustomAlert from './CustomAlert';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Icon, Input } from 'antd';
import { ajax } from '../helper';
import authService from '../services/authService';

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class TspAuthForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                ajax()
                    .post('/tsp', { tsaUser: values.tsaUser, tsaPassword: values.tsaPassword })
                    .then(() => {
                        this.props.onFinished();
                    })
                    .catch((error) => {
                        if (error.response) {
                            this._isMounted && this.setState({ serverError: error.response.data });
                        }
                    });
            }
        });
    };

    onSkip = () => {
        this.props.onFinished();
    };

    render() {
        const { translate } = this.props;
        const { getFieldDecorator, getFieldError, getFieldsError, isFieldTouched } = this.props.form;
        const { serverError } = this.state;
        const tsaUserError = isFieldTouched('tsaUser') && getFieldError('tsaUser');
        const tsaPasswordError = isFieldTouched('tsaPassword') && getFieldError('tsaPassword');
        const enableSkipTimestampAuthButton = !authService.getSigningWithTimestampOnly();

        return (
            <div>
                <p>
                    {translate('modals.tsa.textStart')}
                    <span style={{ whiteSpace: 'nowrap' }}>
                        (<span style={{ fontWeight: 'bold' }}>{window.config.REACT_APP_TSA_URL}</span>)
                    </span>
                    {translate('modals.tsa.textEnd')}
                </p>
                {serverError && <CustomAlert error={serverError} />}
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item validateStatus={tsaUserError ? 'error' : ''} help={tsaUserError || ''}>
                        {getFieldDecorator('tsaUser', {
                            rules: [{ required: true, message: translate('formValidationErrors.required') }],
                        })(
                            <Input
                                prefix={<Icon type="user" />}
                                placeholder={translate('modals.tsa.fields.tsaUser.placeholder')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item validateStatus={tsaPasswordError ? 'error' : ''} help={tsaPasswordError || ''}>
                        {getFieldDecorator('tsaPassword', {
                            rules: [{ required: true, message: translate('formValidationErrors.required') }],
                        })(
                            <Input
                                prefix={<Icon type="lock" />}
                                type="password"
                                placeholder={translate('modals.tsa.fields.tsaPassword.placeholder')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button block type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
                            {translate('modals.tsa.buttonNext.text')}
                        </Button>
                    </Form.Item>
                </Form>
                {enableSkipTimestampAuthButton && (
                    <Button block type="primary" onClick={this.onSkip}>
                        {translate('modals.tsa.buttonSkip.text')}
                    </Button>
                )}
            </div>
        );
    }
}

TspAuthForm.propTypes = {
    form: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

const WrappedTspAuthForm = Form.create({ name: 'tsp_auth' })(TspAuthForm);

export default WrappedTspAuthForm;
