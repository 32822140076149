import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import CheckTspAuthAndSign from '../../CheckTspAuthAndSign';

class SignModal extends React.Component {
    render() {
        const { activeLanguage, translate } = this.props;
        let title;
        let content;
        if (this.props.modalData) {
            const {
                signingType,
                cegjegyzekSzam,
                onFinished,
                signModalData,
                commentType,
                commentMessage,
                attributeRole,
                certificate
            } = this.props.modalData;
            if (signModalData.length > 1) {
                title =
                    signingType === 'acknowledgement'
                        ? translate('modals.creatingAcknowledgement.titleMultiple')
                        : translate('modals.signing.titleMultiple');
            } else {
                title =
                    signingType === 'acknowledgement'
                        ? translate('modals.creatingAcknowledgement.title')
                        : translate('modals.signing.title');
            }
            content = (
                <CheckTspAuthAndSign
                    signingType={signingType}
                    activeLanguage={activeLanguage}
                    onFinished={onFinished}
                    cegjegyzekSzam={cegjegyzekSzam}
                    signModalData={signModalData}
                    translate={translate}
                    attributeRole={attributeRole}
                    commentType={commentType}
                    commentMessage={commentMessage}
                    certificate={certificate}
                />
            );
        } else {
            title = null;
            content = null;
        }
        return (
            <ModalWrapper
                {...this.props}
                maskClosable={false}
                title={title}
                width={560}>
                {content}
            </ModalWrapper>
        );
    }
}

SignModal.propTypes = {
    activeLanguage: PropTypes.string.isRequired,
    modalData: PropTypes.object,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        activeLanguage: getActiveLanguage(state.locale).code
    };
}

export default connect(mapStateToProps)(SignModal);
