import React from 'react';

export default class Saml extends React.Component {
    componentDidMount() {
        window.location.assign(window.config.REACT_APP_SAML_URL);
    }

    render() {
        return null;
    }
}
