import PropTypes from 'prop-types';
import React from 'react';
import checkMark from '../../images/checkmark.svg';
import signedImage from '../../images/eszigno.svg';
import signMark from '../../images/sign.svg';
import { TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS } from '../../constants/strings';
import { Badge, Icon, Tooltip } from 'antd';
import { iconTypeFromType } from '../../helper';
import { useSelector } from 'react-redux';

export default function ContainerIcon(props) {
    const { offset, container, translate } = props;

    const currentTab = useSelector((state) => state.tabs.current);

    const eszignoIconsStyle = {
        width: '40px',
        height: '40px'
    };

    const checkMarkStyle = {
        width: '16px',
        height: '16px'
    };

    let icon;

    if (currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS) {
        if (container.action === 'sign') {
            icon = (
                <Badge
                    offset={[-15, 20]}
                    style={checkMarkStyle}
                    count={
                        <img
                            style={checkMarkStyle}
                            src={signMark}
                            alt={'signMark icon'}
                        />
                    }>
                    <Icon
                        type={iconTypeFromType(container.type)}
                        style={{ fontSize: 25, marginRight: 10 }}
                    />
                </Badge>
            );
        } else if (container.action === 'approve') {
            icon = (
                <Badge
                    offset={[-15, 20]}
                    style={checkMarkStyle}
                    count={
                        <img
                            style={checkMarkStyle}
                            src={checkMark}
                            alt={'checkMark icon'}
                        />
                    }>
                    <Icon
                        type={iconTypeFromType(container.type)}
                        style={{ fontSize: 25, marginRight: 10 }}
                    />
                </Badge>
            );
        }
    } else if (container.signedFromBulkSigning === 'REFUSED') {
        icon = (
            <Badge
                offset={[-15, 35]}
                style={eszignoIconsStyle}
                count={
                    <Icon
                        type="stop"
                        theme="twoTone"
                        twoToneColor="red"
                    />
                }>
                <Icon
                    type={iconTypeFromType(container.type)}
                    style={{ fontSize: 25, marginRight: 10 }}
                />
            </Badge>
        );
    } else if (container.signedFromBulkSigning === 'SIGNED' || container.signedFromBulkSigning === 'APPROVED') {
        icon = (
            <Badge
                offset={[-15, 20]}
                style={eszignoIconsStyle}
                count={
                    <img
                        style={eszignoIconsStyle}
                        src={signedImage}
                        alt={'eszigno icon'}
                    />
                }>
                <Icon
                    type={iconTypeFromType(container.type)}
                    style={{ fontSize: 25, marginRight: 10 }}
                />
            </Badge>
        );
    } else {
        icon =
            container.error !== undefined ? (
                <Tooltip title={translate('messages.error.corruptedFile')}>
                    <Badge
                        offset={[-15, 35]}
                        style={eszignoIconsStyle}
                        count={
                            <Icon
                                type="exclamation-circle"
                                theme="twoTone"
                                twoToneColor="red"
                            />
                        }>
                        <Icon
                            type={iconTypeFromType(container.type)}
                            style={{ fontSize: 25, marginRight: 10 }}
                        />
                    </Badge>
                </Tooltip>
            ) : (
                <Icon
                    type={iconTypeFromType(container.type)}
                    style={{ fontSize: 25, marginRight: 10 }}
                />
            );
    }

    return (
        <Badge
            offset={offset}
            dot={!container.wasOpened}>
            {icon}
        </Badge>
    );
}

ContainerIcon.propTypes = {
    container: PropTypes.object,
    offset: PropTypes.array,
    translate: PropTypes.func
};
