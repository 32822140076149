import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';

class LinkButton extends React.Component {
    render() {
        const { style, text, url } = this.props;
        return (
            <Button
                type="primary"
                href={url}
                rel={'noopener noreferrer'}
                style={{
                    margin: '16px 0px 16px 0px',
                    minWidth: '150px',
                    width: '180px',
                    ...style
                }}
                target={'_blank'}>
                {text}
            </Button>
        );
    }
}

LinkButton.propTypes = {
    style: PropTypes.object,
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};

export default LinkButton;
