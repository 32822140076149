import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import React from 'react';
import { Button } from 'antd';
import { ajax, createNotification } from '../../helper';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { removeModal } from '../../actions/modal';

class MobileAuthenticationModal extends React.Component {
    componentDidMount() {
        const { modalData } = this.props;

        this.schedulePolling(modalData.pollingTimeIntervalSec * 1000);
    }

    componentWillUnmount() {
        const { timeoutId } = this.state;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
    }

    schedulePolling = (timeout) => {
        this.setState({
            timeoutId: setTimeout(this.polling, timeout),
        });
    };

    polling = () => {
        const { modalData, removeModal, translate } = this.props;

        ajax()
            .post(
                `/user/pollingMobileAuthentication?updateRefreshToken=${!!modalData.updateRefreshToken}`,
                modalData.token
            )
            .then((response) => {
                switch (response.status) {
                    case 200:
                        removeModal();

                        if (modalData.onAuthenticated) {
                            modalData.onAuthenticated(response.data);
                        }

                        break;

                    case 202:
                        this.schedulePolling(modalData.pollingTimeIntervalSec * 1000);
                        break;

                    default:
                        console.info(
                            `Polling mobile authentication returned with HTTP status code '${response.status}'.`
                        );
                }
            })
            .catch((error) => {
                console.error('Unable to polling mobile authentication.', error);

                createNotification(translate, error.response.data.code, {
                    message: translate('messages.error.couldNotLogin'),
                    type: 'error',
                });

                this.onCancel();
            });
    };

    onCancel = () => {
        const { modalData, removeModal } = this.props;
        const { timeoutId } = this.state;

        clearTimeout(timeoutId);

        this.setState({
            timeoutId: undefined,
        });

        removeModal();

        if (modalData.onCancel) {
            modalData.onCancel();
        }
    };

    render() {
        const { modalData, translate } = this.props;

        return (
            <ModalWrapper
                {...this.props}
                closable={true}
                maskClosable={false}
                title={modalData.title}
                width={700}
                bodyStyle={{ textAlign: 'center' }}
                onCancel={this.onCancel}
            >
                <React.Fragment>
                    <QRCode value={modalData.qrContent} size={150} />
                    <p>{modalData.description}</p>
                    <Button type="primary" block onClick={this.onCancel}>
                        {translate('buttons.cancel')}
                    </Button>
                </React.Fragment>
            </ModalWrapper>
        );
    }
}

MobileAuthenticationModal.propTypes = {
    modalData: PropTypes.object,
    removeModal: PropTypes.func,
    translate: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeModal: () => dispatch(removeModal()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileAuthenticationModal);
