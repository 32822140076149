import PropTypes from 'prop-types';
import React from 'react';
import ModalWrapper from './ModalWrapper';
import PdfEmbeddedFiles from '../document/PdfEmbeddedFiles';

class PdfEmbeddedFilesModal extends React.Component {
    render() {
        const modalProps = { width: '600px', ...this.props };
        const { translate } = this.props;
        return (
            <ModalWrapper {...modalProps} title={translate('modals.attachments')}>
                <PdfEmbeddedFiles container={this.props.modalData} />
            </ModalWrapper>
        );
    }
}

PdfEmbeddedFilesModal.propTypes = {
    modalData: PropTypes.object,
    translate: PropTypes.func,
};

export default PdfEmbeddedFilesModal;
