import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchAllSignatureProfiles } from '../../actions/signatureProfile';
import { createNotification, createNotificationShort, getHistory } from '../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { removeModal, toggleModal } from '../../actions/modal';
import * as userActions from '../../actions/user';
import { Button, Spin, Input, Icon, Form } from 'antd';
import FormItem from '../form/FormItem';
import authService from '../../services/authService';
import { NOTIFY_USER } from '../../constants/modalNames';

class SecondFactorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiTimeout: null,
            showFallbackEmail: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { modalData } = this.props;
        this._isMounted &&
            this.setState({
                apiTimeout:
                    modalData.pollType === 'loginPoll'
                        ? setTimeout(this.polling)
                        : setTimeout(this.pollingUpdate),
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this.state.apiTimeout);
    }

    polling = () => {
        const { actions, modalData, currentLanguage } = this.props;
        actions
            .pollingSecondFactor(modalData, currentLanguage)
            .then((response) => {
                if (response === 202) {
                    this._isMounted &&
                        this.setState({ apiTimeout: setTimeout(this.polling, modalData.availabilityInMiliSec) });
                } else {
                    this.clear();
                    this.redirect(response.data.notifyUser);
                }
            })
            .catch((e) => {
                this.clear();
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.couldNotLogin'),
                    type: 'error',
                });
            });
    };

    pollingUpdate = () => {
        const { actions, modalData } = this.props;
        actions
            .pollingUpdateSecondFactor(modalData)
            .then((response) => {
                if (response === 202) {
                    this._isMounted &&
                        this.setState({ apiTimeout: setTimeout(this.pollingUpdate, modalData.availabilityInMiliSec) });
                } else {
                    authService.setSecondFactor(modalData.secondFactor);
                    createNotificationShort(this.props.translate, {
                        message: this.props.translate('messages.success.settingSaved'),
                        type: 'success',
                    });
                    this.clear();
                }
            })
            .catch((e) => {
                this.props.modalData.onCancel();
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.settingFailed'),
                    type: 'error',
                });
                this.clear();
            });
    };

    redirect(shouldNotifyUser) {
        getHistory().push('/');
        if (shouldNotifyUser) {
            this.props.notifyUserModal();
        }
    }

    clear = () => {
        clearTimeout(this.state.apiTimeout);
        this.props.removeModal();
    };

    setShowFallbackEmail = () => {
        this._isMounted && this.setState({ showFallbackEmail: true });
    };

    resetSecondFactorIntent = (e) => {
        const { form, modalData, translate, actions, currentLanguage } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                return actions
                    .resetSecondFactorIntent(values.email, currentLanguage)
                    .then((response) => {
                        createNotificationShort(this.props.translate, {
                            message: translate('messages.success.resetSecondFactorIntentSuccess'),
                            type: 'success',
                        });
                        this.clear();
                    })
                    .catch((e) => {
                        modalData.onCancel();
                        createNotification(this.props.translate, e.response.data.code, {
                            message: translate('messages.error.resetSecondFactorIntentFailed'),
                            type: 'error',
                        });
                        this.clear();
                    });
            }
        });
    };

    render() {
        const { translate } = this.props;
        const { getFieldDecorator } = this.props.form;
        const modalProps = { width: '700px', ...this.props };

        return (
            <ModalWrapper
                {...modalProps}
                title={translate('modals.secondFactor.modalTitle')}
                width={400}
                bodyStyle={{ textAlign: 'center' }}
                onCancel={this.props.modalData.onCancel}
                closable={false}
                maskClosable={false}
            >
                <div>
                    <p>{translate('modals.secondFactor.description')}</p>
                    {this.props.modalData.secureId && (
                        <p style={{ textAlign: 'center' }}>SecureID: {this.props.modalData.secureId}</p>
                    )}
                    <Spin style={{ marginBottom: '20px', marginTop: '20px' }} />
                    <Button
                        type="primary"
                        block
                        onClick={() => {
                            this.props.modalData.onCancel();
                            this.clear();
                        }}
                    >
                        {translate('buttons.cancel')}
                    </Button>
                    <Button type={'link'} onClick={() => this.setShowFallbackEmail()}>
                        {translate('modals.secondFactor.help')}
                    </Button>
                    {this.state.showFallbackEmail && (
                        <Form style={{ marginTop: '25px' }} onSubmit={this.resetSecondFactorIntent}>
                            <p>{translate('modals.secondFactor.helpext')}</p>
                            <FormItem>
                                {getFieldDecorator('email', {
                                    rules: [
                                        { required: true, message: translate('formValidationErrors.required') },
                                        { type: 'email', message: translate('formValidationErrors.validateEmail') },
                                    ],
                                })(
                                    <Input
                                        prefix={<Icon type="mail" />}
                                        placeholder={translate('inputs.email')}
                                        style={{ marginBottom: '5px' }}
                                    />
                                )}
                            </FormItem>
                            <Button style={{ marginBottom: '5px', width: '100%' }} type="primary" htmlType="submit">
                                {translate('buttons.send')}
                            </Button>
                        </Form>
                    )}
                </div>
            </ModalWrapper>
        );
    }
}

SecondFactorModal.propTypes = {
    actions: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
    modalData: PropTypes.object,
    notifyUserModal: PropTypes.func.isRequired,
    pollMobileLogin: PropTypes.func,
    pollingMobileLogin: PropTypes.func,
    removeModal: PropTypes.func,
    translate: PropTypes.func,
    form: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
        fetchSignatureProfiles: () => dispatch(fetchAllSignatureProfiles()),
        removeModal: () => dispatch(removeModal()),
        notifyUserModal: (result) => {
            if (result) {
                dispatch(toggleModal(NOTIFY_USER));
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(SecondFactorModal));
