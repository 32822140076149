import CustomAlert from './CustomAlert';
import FormItem from './form/FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Icon, Input } from 'antd';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                this.props.submitForm(e, values).catch((err) => {
                    this._isMounted && this.setState({ serverError: err });
                });
            }
        });
    };

    render() {
        const { translate, isLoading } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { serverError } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                {serverError && (
                    <FormItem>
                        <CustomAlert error={serverError} />
                    </FormItem>
                )}
                <FormItem>
                    {getFieldDecorator('userName', {
                        rules: [{ required: true, message: translate('form.required') }],
                        normalize: (input) => (input !== undefined ? input.toLowerCase() : '')
                    })(
                        <Input
                            prefix={<Icon type="user" />}
                            placeholder={translate('login.userName')}
                            disabled={isLoading}
                        />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: translate('form.required') }]
                    })(
                        <Input
                            prefix={<Icon type="lock" />}
                            placeholder={translate('login.passwordInput')}
                            type={'password'}
                            disabled={isLoading}
                            autoComplete={'password'}
                        />
                    )}
                </FormItem>
                <Button
                    disabled={isLoading}
                    type="primary"
                    block
                    htmlType="submit">
                    {translate('login.loginButton')}
                </Button>
            </Form>
        );
    }
}

LoginForm.propTypes = {
    getFieldDecorator: PropTypes.func,
    form: PropTypes.object.isRequired,
    submitForm: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default Form.create()(LoginForm);
