import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from './ModalWrapper';
import CreateSignatureSchema from '../document/CreateSignatureProfile';
import UpdateSignatureSchema from '../document/UpdateSignatureProfile';

class SignatureSchemaModal extends Component {
    render() {
        const modalProps = { width: '700px', ...this.props };
        return (
            <ModalWrapper {...modalProps}>
                {!this.props.update && <CreateSignatureSchema {...this.props} />}
                {this.props.update && <UpdateSignatureSchema {...this.props} />}
            </ModalWrapper>
        );
    }
}

SignatureSchemaModal.propTypes = {
    update: PropTypes.bool.isRequired,
};

export default SignatureSchemaModal;
