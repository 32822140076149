import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import AttributeCertificates from './AttributeCertificates';
import { Col, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

export default function AttributeCertificatesModal(props) {
    const [isMounted, setIsMounted] = useState(false);
    const [enableDetailedView, setEnableDetailedView] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    const handleRowSelectionChange = (enable) => {
        isMounted && setEnableDetailedView(enable);
    };

    const modalProps = { width: '800px', ...props };
    return (
        <ModalWrapper {...modalProps} title={translate('modals.attributeCert.attributeCertificates')}>
            <Col>
                {translate('attributeCertificate.detailed')}
                <Switch
                    style={{ marginLeft: '10px' }}
                    defaultChecked={false}
                    onChange={handleRowSelectionChange}
                />
            </Col>
            <AttributeCertificates
                eSzignoAttributeCertificates={props.modalData}
                enableDetailedView={enableDetailedView}
            />
        </ModalWrapper>
    );
}

AttributeCertificatesModal.propTypes = {
    modalData: PropTypes.array,
    translate: PropTypes.func.isRequired
};
