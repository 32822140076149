import * as containerActions from '../../actions/container';
import Cleave from 'cleave.js/react';
import CustomAlert from '../CustomAlert';
import FormItem from '../form/FormItem';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import UserProfileError from '../UserProfileError';
import { Button, Col, Form, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { ajax, getDocuments, getHistory, PreviewContainerList } from '../../helper';
import { removeModal, toggleModal } from '../../actions/modal';
import { CompanySignError } from '../CompanySignError';
import { getContainers, getContainersInSignatureProcess } from '../../actions/container';
import {
    ATTRIBUTE_ROLES,
    SELECT_CERTIFICATE,
    SIGN,
    SIGNATURE_POSITION,
    USER_PROFILE_ERROR
} from '../../constants/modalNames';
import { getAttributeUsage } from '../../actions/user';
import { SIGNING_TYPE_SIGN } from '../../constants/strings';

class CompanyRegModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: props.modalData.containers.every((container) => this.isFeatureEnabled(container.data.type)),
            imageHeight: 0,
            imageWidth: 0,
            serverError: null,
            isThereAnySignedPDFSelected: false,
            signedPDFNames: [],
            isLoading: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.modalData.isMultiple) {
            this.isThereAnySignedPDF(this.props.modalData.containerIds);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isFeatureEnabled(containerType) {
        if (containerType === 'pdf' && !(localStorage.getItem('signPDF') === 'true')) {
            return false;
        }
        if (
            (containerType === 'es3' || containerType === 'et3') &&
            !(localStorage.getItem('signContainers') === 'true')
        ) {
            return false;
        }
        return (
            localStorage.getItem('signAsCompanyRepresentative') === 'true' ||
            !(localStorage.getItem('isMicrosecOIDDisabled') === 'true')
        );
    }

    getContainerWithPages = (id) => {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };

    getPdfSize(id) {
        return ajax()
            .get(`${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${id}/preview?pageNumber=1`)
            .then((data) => {
                this._isMounted &&
                    this.setState({
                        imageHeight: data.data.imageHeight,
                        imageWidth: data.data.imageWidth,
                    });
                return {
                    imageHeight: data.data.imageHeight,
                    imageWidth: data.data.imageWidth,
                };
            })
            .catch((error) => {
                this._isMounted &&
                    this.setState({
                        error: error.response.data.error,
                    });
            });
    }

    startSignWithCegjegyzekSzam(cegjegyzekSzam, selectedRole, certificate) {
        const { modalData, showSignaturePositioning, showSignModal, translate, userProfileErrorModal } = this.props;
        const containers =
            this.props.containers !== undefined ? this.props.containers : this.props.modalData.containers;
        const maxContainerSignNumber = parseInt(localStorage.getItem('massDocumentSignMaxNumber'));

        if (containers.length > maxContainerSignNumber) {
            userProfileErrorModal([
                translate('modals.massSigning.maxContainerSignNumberText') + ' ' + maxContainerSignNumber,
            ]);
            return;
        }

        const pdfs = containers.filter((container) => container.data.type === 'pdf');
        const edossiers = containers.filter((container) => container.data.type === 'edossier');
        const previewContainerList = [];
        const signModalData = [];

        for (let i = 0; i < edossiers.length; i++) {
            signModalData.push({
                containerId: edossiers[i].data.id,
                pdfSettings: null,
            });
            previewContainerList.push(new PreviewContainerList(edossiers[i].data, 0, 0, false));
        }

        if (pdfs.length > 0) {
            let promises = [];
            pdfs.forEach((pdf) => {
                promises.push(this.getContainerWithPages(pdf.data.id));
            });
            Promise.all(promises).then((containerWithPages) => {
                promises = [];
                pdfs.forEach((pdf) => {
                    promises.push(this.getPdfSize(pdf.data.id));
                });
                Promise.all(promises).then((dimensions) => {
                    for (let i = 0; i < dimensions.length; i++) {
                        previewContainerList.push(
                            new PreviewContainerList(
                                containerWithPages[i],
                                dimensions[i].imageHeight,
                                dimensions[i].imageWidth,
                                true
                            )
                        );
                    }
                    for (let i = 0; i < edossiers.length; i++) {
                        previewContainerList.push(new PreviewContainerList(edossiers[i].data, 0, 0, false));
                    }
                    showSignaturePositioning({
                        cegjegyzekSzam,
                        container: containerWithPages[0],
                        currentTab: modalData.currentTab,
                        imageHeight: dimensions[0].imageHeight,
                        imageWidth: dimensions[0].imageWidth,
                        previewContainerList: previewContainerList,
                        signModalData: signModalData,
                        certificate: certificate,
                        attributeRole: selectedRole
                    });
                });
            });
        } else {
            showSignModal({
                cegjegyzekSzam,
                onFinished: this.onSignFinished,
                signModalData: signModalData,
                certificate: certificate,
                attributeRole: selectedRole,
                signingType: SIGNING_TYPE_SIGN
            });
        }
    }

    onSignFinished = (result) => {
        const { refreshContainersList, hideModal, updateSelectedContainerSignatures, modalData } = this.props;
        if (result.succeeded) {
            setTimeout(() => {
                hideModal();
                if (modalData.isMultiple) {
                    // multiple selection view
                    return;
                }
                if (modalData.containers.length === 1) {
                    // only one contianer is selected
                    updateSelectedContainerSignatures(modalData.containers[0].data);
                }
                refreshContainersList();
            }, 1000);
        }
    };

    validateCegjegyzekSzam = (rule, value, callback) => {
        const regexp = /^[0-2][0-9]-[0-2][0-9]-[0-9]{6}$/m;
        const { translate } = this.props;
        if (!regexp.test(value)) {
            callback(translate('formValidationErrors.wrong_cegjegyzekszam'));
        } else {
            callback();
        }
    };

    handleSubmit = (e) => {
        const { form } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                if (localStorage.getItem('cegjegyzekSzam') === null) {
                    localStorage.setItem('cegjegyzekSzam', values.cegjegyzekSzam);
                }
                this.props.hideModal();
                this.props.getAttributeUsage().then((response) => {
                    if (response.data.attributeCertUsage) {
                        ajax()
                            .get('/user/certificate')
                            .then(() => {
                                this.props.openAttributeRolesModal({
                                    startSign: (selectedRole) =>
                                        this.startSignWithCegjegyzekSzam(values.cegjegyzekSzam, selectedRole, null)
                                });
                            })
                            .catch(() => {
                                this.props.openSelectCertificateModal({
                                    startSign: (selectedRole, certificate) =>
                                        this.startSignWithCegjegyzekSzam(
                                            values.cegjegyzekSzam,
                                            selectedRole,
                                            certificate
                                        )
                                });
                            });
                    } else {
                        this.startSignWithCegjegyzekSzam(values.cegjegyzekSzam, null, null);
                    }
                });
            }
        });
    };

    getPDFSignedStatus = async (containerId) => {
        return await ajax()
            .get(`/containers/${containerId}`)
            .then((response) => {
                let result = response.data.type === 'pdf' && response.data.details.signatures.length > 0;
                if (result) {
                    this._isMounted &&
                        this.setState({ signedPDFNames: [...this.state.signedPDFNames, response.data.title] });
                }
                return result;
            });
    };

    isThereAnySignedPDF = async () => {
        const { containerIds } = this.props.modalData;
        this._isMounted && this.setState({ isLoading: true });
        let result = false;
        const stopper = new Promise((resolve) => {
            containerIds.forEach(async (value, index, array) => {
                await this.getPDFSignedStatus(value)
                    .then((response) => {
                        result = result || response;
                    })
                    .catch((error) => console.error(error));
                if (index === array.length - 1) resolve();
            });
        });
        stopper.then(() => {
            this._isMounted && this.setState({ isThereAnySignedPDFSelected: result, isLoading: false });
            return result;
        });
    };

    render() {
        const { translate, currentLanguage } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { enabled, serverError } = this.state;
        const noOID =
            (!localStorage.getItem('userOid') || localStorage.getItem('userOid').length === 0) &&
            !(localStorage.getItem('isMicrosecOIDDisabled') === 'true');
        return (
            <ModalWrapper
                {...this.props}
                title={'Aláírás cégképviseleti igazolással'}>
                {serverError && <CustomAlert error={serverError} />}
                {this.state.isLoading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Spin size={'large'} />
                    </div>
                ) : (
                    <div>
                        {!this.state.isThereAnySignedPDFSelected && this.state.signedPDFNames.length === 0 ? (
                            <div>
                                {enabled ? (
                                    <Form onSubmit={this.handleSubmit}>
                                        <div>
                                            <p>{translate('company.reg.description1')}</p>
                                            <p>
                                                {translate('company.reg.description2', {
                                                    link: getDocuments('price_list', currentLanguage),
                                                })}
                                            </p>
                                            {noOID && (
                                                <div>
                                                    <Row>
                                                        <Col span={19}>
                                                            {translate('company.reg.description.conditional')}
                                                        </Col>
                                                        <Col span={4}>
                                                            <Button
                                                                onClick={() => {
                                                                    getHistory().push({
                                                                        pathname: '/settings',
                                                                        focusOnOid: true,
                                                                    });
                                                                    this.props.hideModal();
                                                                }}
                                                                type="primary">
                                                                {translate('company.reg.button')}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                        <FormItem
                                            formItemProps={{
                                                label: translate('labels.newContainerType.cegeljaras_cegjegyzekszam')
                                            }}>
                                            {getFieldDecorator('cegjegyzekSzam', {
                                                initialValue: localStorage.getItem('cegjegyzekSzam'),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate('formValidationErrors.required'),
                                                    },
                                                    { validator: this.validateCegjegyzekSzam },
                                                ],
                                            })(
                                                <Cleave
                                                    className="ant-input"
                                                    placeholder="11-11-111111"
                                                    options={{ blocks: [2, 2, 6], delimiter: '-', numericOnly: true }}
                                                />
                                            )}
                                        </FormItem>
                                        <FormItem>
                                            <Button
                                                disabled={noOID}
                                                type="primary"
                                                block
                                                htmlType="submit">
                                                {translate('buttons.sign.text')}
                                            </Button>
                                        </FormItem>
                                    </Form>
                                ) : (
                                    <UserProfileError
                                        size={64}
                                        icon="user"
                                    />
                                )}
                            </div>
                        ) : (
                            <CompanySignError
                                size={64}
                                icon="user"
                                translate={translate}
                                signedPDfNames={this.state.signedPDFNames}
                            />
                        )}
                    </div>
                )}
            </ModalWrapper>
        );
    }
}

CompanyRegModal.propTypes = {
    containers: PropTypes.array,
    currentLanguage: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    getAttributeUsage: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
    openAttributeRolesModal: PropTypes.func.isRequired,
    openSelectCertificateModal: PropTypes.func.isRequired,
    showSignaturePositioning: PropTypes.func.isRequired,
    showSignModal: PropTypes.func,
    updateContainersWaiting: PropTypes.func.isRequired,
    updateSelectedContainerSignatures: PropTypes.func.isRequired,
    userProfileErrorModal: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    refreshContainersList: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAttributeUsage: () => {
            return dispatch(getAttributeUsage());
        },
        hideModal: () => {
            dispatch(removeModal());
        },
        openAttributeRolesModal: (callback) => {
            dispatch(toggleModal(ATTRIBUTE_ROLES, callback));
        },
        openSelectCertificateModal: (callback) => {
            dispatch(toggleModal(SELECT_CERTIFICATE, callback));
        },
        showSignModal: (data) => {
            dispatch(toggleModal(SIGN, data));
        },
        refreshContainersList: () => {
            dispatch(getContainersInSignatureProcess());
            dispatch(getContainers());
        },
        showSignaturePositioning: (data) => {
            dispatch(toggleModal(SIGNATURE_POSITION, data));
        },
        updateSelectedContainerSignatures: (container) => {
            dispatch(containerActions.getContainerSignatures(container.type, container.id));
        },
        updateContainersWaiting: () => {
            dispatch(containerActions.getContainersInSignatureProcess()).then((containersInSignatureProcess) => {
                const containers = containersInSignatureProcess.containers;
                if (containers.length > 0) {
                    dispatch(containerActions.updateSelectedContainerList([containers[0]]));
                }
            });
        },
        userProfileErrorModal: () => {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        },
    };
}

const enhancedWithForm = Form.create()(CompanyRegModal);
export default connect(mapStateToProps, mapDispatchToProps)(enhancedWithForm);
