import Box from './Box';
import CompleteLoginForm from './CompleteLoginForm';
import PropTypes from 'prop-types';
import React from 'react';
import WebPage from './WebPage';
import adminLogo from '../images/admin_dark.svg';
import authService from '../services/authService';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { getTranslate } from 'react-localize-redux/lib/index';
import { removeModal } from '../actions/modal';

class AdminLogin extends React.Component {
    componentDidMount() {
        authService.logout();
        this.props.setModalStateToDefault();
    }

    render() {
        const { currentLanguage, deleteStoredFileId, history, storedFileId } = this.props;

        return (
            <WebPage location={history.location}>
                <Row
                    type={'flex'}
                    justify={'space-around'}
                    align={'middle'}>
                    <Col
                        xs={24}
                        md={16}
                        lg={8}
                        xl={8}>
                        <img
                            src={adminLogo}
                            alt="web-szigno"
                            style={{
                                width: 290,
                                height: 60,
                                marginBottom: 22
                            }}
                        />
                    </Col>
                </Row>
                <Row
                    type={'flex'}
                    justify={'space-around'}
                    align={'middle'}>
                    <Col
                        xs={24}
                        md={16}
                        lg={8}
                        xl={8}>
                        <Box>
                            <CompleteLoginForm
                                currentLanguage={currentLanguage}
                                deleteStoredFileId={deleteStoredFileId}
                                embedded={false}
                                showForgottenPassword={false}
                                showSocialOptions={false}
                                storedFileId={storedFileId}
                                isAdminLogin={true}
                            />
                        </Box>
                    </Col>
                </Row>
            </WebPage>
        );
    }
}

AdminLogin.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    storedFileId: PropTypes.string.isRequired,
    setModalStateToDefault: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        storedFileId: state.containerList.storedFileIdForDownload,
        translate: getTranslate(state.locale)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalStateToDefault: () => {
            dispatch(removeModal());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
