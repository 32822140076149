import MobileStoreButton from '../buttons/MobileStoreButton';
import React from 'react';
import theme from '../../theme';
import { Col, Layout, List, Row } from 'antd';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { getDocuments, isOnLoginPage } from '../../helper';
import { useSelector } from 'react-redux';

export default function Footer() {
    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);
    const translate = useSelector((state) => getTranslate(state.locale));

    const data = [
        {
            title: translate('termsAndConditions'),
            href: getDocuments('terms_and_conditions', currentLanguage)
        },
        {
            title: translate('priceList'),
            href: getDocuments('price_list', currentLanguage)
        },
        {
            title: translate('manual'),
            href: getDocuments('manual', currentLanguage)
        },
        {
            title: translate('privacy_policy'),
            href: getDocuments('privacy_policy', currentLanguage)
        }
    ];
    const numberOfAvailableDocument = data.filter((obj) => obj.href !== undefined).length;

    const isLoginPage = isOnLoginPage();

    return (
        <Layout.Footer
            style={{
                backgroundColor: isLoginPage ? theme.headerLight : theme.headerDark,
                color: isLoginPage ? theme.textColor : theme.textColorLight,
                margin: '0px',
                padding: '12px',
                textAlign: 'center'
            }}>
            {window.config.REACT_APP_CUSTOM_FOOTER_ENABLED ? (
                <iframe
                    id="custom-footer"
                    src={'custom_footer.html'}
                    frameBorder="0"
                    title="Custom footer"
                />
            ) : (
                <div>
                    <Col
                        xs={0}
                        xl={6}
                        align={'middle'}
                        style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <MobileStoreButton
                            linkProps={{ title: 'iOS Store Button', style: { marginRight: '8px' } }}
                            store={'ios'}
                            url={'https://apps.apple.com/us/app/e-szignó/id1481195992'}
                        />
                        <MobileStoreButton
                            linkProps={{ title: 'Android Store Button' }}
                            store={'android'}
                            url={'https://play.google.com/store/apps/details?id=hu.microsec.eszigno'}
                        />
                    </Col>
                    <Col
                        xs={24}
                        xl={12}>
                        <Row
                            align={'middle'}
                            justify={'center'}
                            type={'flex'}>
                            <Col
                                xs={24}
                                lg={10}>
                                <List
                                    dataSource={data}
                                    grid={{ column: numberOfAvailableDocument, gutter: 16 }}
                                    renderItem={(item) =>
                                        item.href ? (
                                            <List.Item>
                                                <a
                                                    href={item.href}
                                                    rel={'noopener noreferrer'}
                                                    style={{
                                                        color: isLoginPage ? theme.textColor : theme.textColorLight
                                                    }}
                                                    target={'_blank'}>
                                                    {item.title}
                                                </a>
                                            </List.Item>
                                        ) : (
                                            <div />
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>{translate('copyright', { year: new Date().getFullYear() })}</Col>
                        </Row>
                    </Col>
                    <Col
                        xs={0}
                        xl={6}
                    />
                </div>
            )}
        </Layout.Footer>
    );
}
