import BackToHomePageButton from './buttons/BackToHomePageButton';
import Box from './Box';
import DeleteButton from './buttons/DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import WebPage from './WebPage';
import { Button, Col, Input, Row, Table, notification } from 'antd';
import { map, get } from 'lodash';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux/lib/index';
import { toggleModal } from '../actions/modal';
import { ajax, createNotification, createNotificationShort } from '../helper';
import { refreshFrequentlyUsedEmailAddresses } from '../actions/email';
import { CREATE_FREQUENTLY_USED_EMAIL_ADDRESS, UPDATE_FREQUENTLY_USED_EMAIL_ADDRESS } from '../constants/modalNames';

const Search = Input.Search;

class FrequentlyUsedEmailAddresses extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        data: null,
        searchText: ''
    };

    componentDidMount() {
        this._isMounted = true;
        this.props.refreshEmailAddresses();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    deleteEmailAddress(emailAddress) {
        ajax()
            .delete(`/emailAddress/${emailAddress.id}`)
            .then(() => {
                this.props.refreshEmailAddresses();
                notification.destroy();
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('frequentlyUsedEmailAddresses.delete.success'),
                    type: 'success'
                });
            })
            .catch((error) => {
                notification.destroy();
                createNotification(this.props.translate, error.response.data.code, {
                    message: this.props.translate('frequentlyUsedEmailAddresses.delete.error'),
                    type: 'error'
                });
            });
    }

    deleteAllEmailAddresses() {
        const { frequentlyUsedEmailAddresses } = this.props;
        frequentlyUsedEmailAddresses.forEach((element) => {
            this.deleteEmailAddress(element);
        });
        this.props.refreshEmailAddresses();
    }

    onSearch = (e) => {
        e.target.value = e.target.value !== undefined ? e.target.value.toLowerCase() : '';
        const text = e.target.value;
        const regExp = new RegExp(text, 'gi');
        const filteredData = map(this.props.data, (record) => {
            const emailAddressMatch = get(record, 'emailAddress').match(regExp);
            if (!emailAddressMatch) {
                return null;
            }
            return record;
        }).filter((record) => !!record);
        this._isMounted && this.setState({ searchText: text, data: text ? filteredData : this.props.data });
    };

    render() {
        const columns = [
            {
                title: this.props.translate('frequentlyUsedEmailAddresses.tableTitle'),
                dataIndex: 'emailAddress',
                key: 'emailAddress',
                sorter: (a, b) => a.emailAddress.localeCompare(b.emailAddress),
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'ascend'
            },
            {
                title: '',
                align: 'right',
                render: (record) => {
                    if (record.isEmptyRow) {
                        return '';
                    }
                    return (
                        <div>
                            <Button
                                icon={'edit'}
                                key={'update'}
                                onClick={() => this.props.updateFrequentlyUsedEmailAddress(record)}
                                size={'small'}
                                style={{ marginRight: 8 }}
                                title={this.props.translate('buttons.modify.container')}
                            />
                            <DeleteButton
                                key={'delete'}
                                onClick={() => this.deleteEmailAddress(record)}
                                size={'small'}
                                title={this.props.translate('buttons.delete.container')}
                            />
                        </div>
                    );
                }
            }
        ];

        const { history } = this.props;
        const dataSource = this.state.data ? this.state.data : this.props.data;

        return (
            <WebPage location={history.location}>
                <Row
                    type="flex"
                    justify="center"
                    align="middle">
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <BackToHomePageButton />
                    </Col>
                </Row>
                <Row
                    type="flex"
                    justify="center"
                    align="middle">
                    <Col
                        xs={24}
                        md={20}
                        lg={16}
                        xl={10}>
                        <h1 style={{ marginTop: 30 }}>{this.props.translate('titles.frequentlyUsedEmailAddresses')}</h1>
                        <Box>
                            <Button
                                icon={'plus'}
                                onClick={() => this.props.createFrequentlyUsedEmailAddress()}
                                style={{ marginRight: 8, marginBottom: 8 }}
                                type={'primary'}>
                                {this.props.translate('frequentlyUsedEmailAddresses.addEmailAddress')}
                            </Button>
                            <DeleteButton
                                onClick={() => this.deleteAllEmailAddresses()}
                                style={{ marginBottom: 8 }}>
                                {this.props.translate('frequentlyUsedEmailAddresses.deleteAllEmailAddresses')}
                            </DeleteButton>
                            <Search
                                onChange={this.onSearch}
                                onPressEnter={this.onSearch}
                                placeholder={'pelda.peter@domain.hu'}
                                size="large"
                                style={{ marginBottom: 8 }}
                                value={this.state.searchText}
                            />
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                locale={{ emptyText: this.props.translate('frequentlyUsedEmailAddresses.empty') }}
                                pagination={{ pageSize: 25 }}
                                rowKey={'id'}
                                showHeader={dataSource.length > 0}
                                size={'small'}
                            />
                        </Box>
                    </Col>
                </Row>
            </WebPage>
        );
    }
}

FrequentlyUsedEmailAddresses.propTypes = {
    createFrequentlyUsedEmailAddress: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    frequentlyUsedEmailAddresses: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    updateFrequentlyUsedEmailAddress: PropTypes.func.isRequired,
    refreshEmailAddresses: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        data: state.frequentlyUsedEmailAddresses,
        frequentlyUsedEmailAddresses: state.frequentlyUsedEmailAddresses,
        translate: getTranslate(state.locale)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createFrequentlyUsedEmailAddress: () => {
            dispatch(toggleModal(CREATE_FREQUENTLY_USED_EMAIL_ADDRESS));
        },
        updateFrequentlyUsedEmailAddress: (emailAddress) => {
            dispatch(
                toggleModal(UPDATE_FREQUENTLY_USED_EMAIL_ADDRESS, {
                    emailAddress: emailAddress
                })
            );
        },
        refreshEmailAddresses: () => {
            ajax()
                .get('/emailAddress')
                .then((response) => {
                    dispatch(refreshFrequentlyUsedEmailAddresses(response));
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FrequentlyUsedEmailAddresses);
