/* eslint-disable react-hooks/exhaustive-deps */
import ContainerControls from '../ContainerControls';
import ContainerTabs from '../container/ContainerTabs';
import DocumentPage from '../document/DocumentPage';
import MultipleContainersControls from '../MultipleContainersControls';
import SelectedContainerList from '../SelectedContainerList';
import React, { useEffect, useState } from 'react';
import WebPage from '../WebPage';
import authService from '../../services/authService';
import { NOTIFY_USER_REGISTERED } from '../../constants/modalNames';
import { REGISTERED_PROFILE } from '../../userProfileNames';
import { Col, Row } from 'antd';
import { equals } from '../../utilities/stringUtilities';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../actions/modal';

export default function HomePage() {
    const dispatch = useDispatch();

    const [isMounted, setIsMounted] = useState(false);

    const containerExist = useSelector((state) => state.containerList.containerExist);
    const filter = useSelector((state) => state.containerList.filter);
    const containerSPExist = useSelector((state) => state.containerList.containerSPExist);
    const filterSP = useSelector((state) => state.containerList.filterSP);
    const selectedContainers = useSelector((state) => state.containerList.selectedContainers);
    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        setIsMounted(true);
        if (
            isMounted && authService.getIsMicrosecOIDEnabled() &&
            equals(localStorage.getItem('doNotShowAgain'), 'false') &&
            equals(authService.getPackageName(), REGISTERED_PROFILE)
        ) {
            isMounted && dispatch(toggleModal(NOTIFY_USER_REGISTERED));
        }
        return () => {
            setIsMounted(false);
        };
    }, []);

    if (!containerExist && filter === "" && !containerSPExist && filterSP === "") {
        return (
            <WebPage>
                <Row
                    align={'middle'}
                    justify={'center'}
                    style={{ textAlign: 'center' }}
                    type={'flex'}>
                    <Col>
                        <ContainerControls />
                        <p>{translate('homepage.title')}</p>
                        {authService.isRegistered() && <p>{translate('homepage.subTitle')}</p>}
                        <p>{translate('homepage.subSubTitle')}</p>
                    </Col>
                </Row>
            </WebPage>
        );
    }

    return (
        <WebPage>
            <Row
                justify={'center'}
                gutter={16}
                type={'flex'}>
                <Col
                    xs={24}
                    xl={10}>
                    <ContainerControls />
                    <ContainerTabs />
                </Col>
                <Col
                    xs={24}
                    xl={14}>
                    <Row
                        align={'middle'}
                        justify={'center'}
                        style={{ padding: '10px 0' }}
                        type={'flex'}>
                        <Col xs={24}>
                            {selectedContainers.length < 2  ? (
                                    <DocumentPage props />
                                ) : (
                                    <React.Fragment>
                                        <MultipleContainersControls />
                                        <SelectedContainerList />
                                    </React.Fragment>
                                )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </WebPage>
    );
}
