import React from 'react';
import authService from '../../services/authService';
import { BRONZE_PROFILE, GOLD_PROFILE, PLATINUM_PROFILE, SILVER_PROFILE } from '../../userProfileNames';
import { getActiveLanguage } from 'react-localize-redux';
import { isOnAdminPage, isOnLoginPage } from '../../helper';
import { useSelector } from 'react-redux';

import LogoLight from '../../images/logo-light.svg';
import LogoDark from '../../images/logo-dark.svg';

import LogoDarkBronzeHU from '../../images/logo-dark-bronze-hu.svg';
import LogoDarkSilverHU from '../../images/logo-dark-silver-hu.svg';
import LogoDarkGoldHU from '../../images/logo-dark-gold-hu.svg';
import LogoDarkPlatinumHU from '../../images/logo-dark-platinum-hu.svg';

import LogoDarkBronzeEN from '../../images/logo-dark-bronze-en.svg';
import LogoDarkSilverEN from '../../images/logo-dark-silver-en.svg';
import LogoDarkGoldEN from '../../images/logo-dark-gold-en.svg';
import LogoDarkPlatinumEN from '../../images/logo-dark-platinum-en.svg';

import LogoDarkAdmin from '../../images/admin_dark.svg';
import LogoLightAdmin from '../../images/admin.svg';

export default function Logo() {
    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);

    const isAdmin = authService.isAdmin() || isOnAdminPage();
    const isHungarian = currentLanguage === 'hu';
    const isLoginPage = isOnLoginPage();
    const packageName = authService.getPackageName();

    let image = LogoLight;

    if (isAdmin && isLoginPage) {
        image = LogoDarkAdmin;
    } else if (isAdmin && !isLoginPage) {
        image = LogoLightAdmin;
    } else if (!isAdmin && isLoginPage) {
        image = LogoLight;
    } else {
        switch (packageName) {
            case BRONZE_PROFILE:
                image = isHungarian ? LogoDarkBronzeHU : LogoDarkBronzeEN;
                break;
            case SILVER_PROFILE:
                image = isHungarian ? LogoDarkSilverHU : LogoDarkSilverEN;
                break;
            case GOLD_PROFILE:
                image = isHungarian ? LogoDarkGoldHU : LogoDarkGoldEN;
                break;
            case PLATINUM_PROFILE:
                image = isHungarian ? LogoDarkPlatinumHU : LogoDarkPlatinumEN;
                break;
            default:
                image = LogoDark;
                break;
        }
    }

    return (
        <img
            alt={'web-Szignó logo'}
            src={image}
            style={{ height: '56px', margin: '8px 16px 8px 16px' }}
        />
    );
}
