import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import authService from '../../services/authService';
import { Button } from 'antd';
import { removeModal, toggleModal } from '../../actions/modal';
import { connect } from 'react-redux';
import { createNotificationShort, getHistory } from '../../helper';
import * as containerActions from '../../actions/container';
import { SIGN, SIGNATURE_POSITION } from '../../constants/modalNames';
import { TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS } from '../../constants/strings';

class PdfWithFormNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tryToNavigate: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.setState({ tryToNavigate: this.props.modalData.data.showPdfPreview });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    sign = () => {
        const { showSignModal, hideSignModal, defaultSignatureProfile, modalData, currentTab } = this.props;
        const pdfSettings = {
            signatureProfileId: defaultSignatureProfile.id,
            pdfReason: '',
            pdfFormFieldName: authService.getUserEmail(),
        };
        showSignModal({
            signModalData: [{ containerId: modalData.data.container.id, pdfSettings: pdfSettings }],
            ack: false,
            onFinished: (result) => {
                if (result.succeeded) {
                    setTimeout(() => {
                        hideSignModal();
                    }, 1000);
                    if (currentTab === TAB_KEY_DEFAULT_CONTAINERS) {
                        const {updateContainers, updateSelectedContainerSignatures } = this.props;
                        updateSelectedContainerSignatures(modalData.data.container);
                        updateContainers();
                    } else if (modalData.data.currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS) {
                        const { updateContainersWaiting, updateContainers } = this.props;
                        updateContainersWaiting();
                        updateContainers();
                    } else {
                        // should not happen
                        createNotificationShort(this.props.translate, {
                            message: this.props.translate('errorCodes.USER_INVALID_PARAMETERS'),
                            type: 'error',
                        });
                    }
                }
            },
        });
    };

    navigateToSettings = () => {
        if (
            (this.props.defaultSignatureProfile.id === 2 && this.state.tryToNavigate) ||
            this.props.defaultSignatureProfile.id !== 2
        ) {
            return false;
        }
        return true;
    };

    render() {
        const { translate, removeModal, modalData } = this.props;
        return (
            <ModalWrapper
                {...this.props}
                closable={false}
                footer={
                    this.navigateToSettings()
                        ? [
                            <Button
                                key={'settings'}
                                type="primary"
                                onClick={() => {
                                    getHistory().push('/settings');
                                    removeModal();
                                }}
                            >
                                {translate('header.links.settings.text')}
                            </Button>,
                            <Button
                                key={'ok'}
                                type="primary"
                                onClick={() => {
                                    removeModal();
                                }}
                            >
                                {translate('buttons.ok')}
                            </Button>,
                        ]
                        : [
                              <Button
                                  key={'yes'}
                                  type="primary"
                                  onClick={() => {
                                      if (this.props.defaultSignatureProfile.id === 2 && this.state.tryToNavigate) {
                                          this._isMounted && this.setState({ tryToNavigate: false });
                                      } else {
                                          this.sign();
                                      }
                                  }}
                              >
                                  {translate('buttons.yes')}
                              </Button>,
                              <Button
                                  key={'no'}
                                  type="primary"
                                  onClick={() => {
                                      if (modalData.data.showPdfPreview) {
                                          this.props.showSignaturePositioning({
                                              signModalData: [],
                                              previewContainerList: [],
                                              container: modalData.data.container,
                                              imageWidth: 0,
                                              imageHeight: 0,
                                          });
                                      } else {
                                          removeModal();
                                      }
                                  }}
                              >
                                  {translate('buttons.no')}
                              </Button>,
                          ]
                }
                title={translate(`modals.pdfWithForm.title`)}
            >
                {this.navigateToSettings() ? (
                    <p>{translate(`modals.pdfWithForm.setNotEmptyProfile`)}</p>
                ) : (
                    <p>{translate(`modals.pdfWithForm.subTitleWithSuggestion`)}</p>
                )}
            </ModalWrapper>
        );
    }
}

PdfWithFormNotification.propTypes = {
    showSignaturePositioning: PropTypes.func,
    modalData: PropTypes.object,
    translate: PropTypes.func,
    removeModal: PropTypes.func,
    addNewContainerToDefaultContainerList: PropTypes.func.isRequired,
    hideSignModal: PropTypes.func.isRequired,
    showSignModal: PropTypes.func.isRequired,
    updateContainers: PropTypes.func.isRequired,
    defaultSignatureProfile: PropTypes.object.isRequired,
    updateContainersWaiting: PropTypes.func.isRequired,
    updateSelectedContainerSignatures: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        toggleTsaModal: () => {
            dispatch(removeModal());
        },
        showSignModal: (data) => {
            dispatch(removeModal());
            dispatch(toggleModal(SIGN, data));
        },
        hideSignModal: () => {
            dispatch(removeModal());
        },
        addNewContainerToDefaultContainerList: (containerId) => {
            dispatch(containerActions.newContainer(containerId));
        },
        updateSelectedContainerSignatures: (container) => {
            dispatch(containerActions.getContainerSignatures(container.type, container.id));
        },
        updateContainersWaiting: () => {
            dispatch(containerActions.getContainersInSignatureProcess()).then((containersInSignatureProcess) => {
                const containers = containersInSignatureProcess.containers;
                if (containers.length > 0) {
                    dispatch(containerActions.updateSelectedContainerList([containers[0]]));
                }
            });
        },
        updateContainers: () => {
            dispatch(containerActions.getContainers());
        },
        showSignaturePositioning: (data) => {
            dispatch(removeModal());
            setTimeout(function () {
                dispatch(toggleModal(SIGNATURE_POSITION, data));
            }, 500);
        },
    };
}

function mapStateToProps(state) {
    return {
        defaultSignatureProfile: state.signatureProfiles.find((profile) => profile.isDefault),
        currentTab: state.tabs.current,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfWithFormNotification);
