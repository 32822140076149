import {
    ADD_SIGNATURE_PROFILE_IMAGE,
    DELETE_SIGNATURE_PROFILE_IMAGE,
    GET_SIGNATURE_PROFILE_IMAGES,
    RESET_SIGNATURE_PROFILE_IMAGES,
    UPDATE_SIGNATURE_PROFILE_IMAGE
} from './actionTypes';

export const addSignatureProfileImage = (profile) => {
    return {
        type: ADD_SIGNATURE_PROFILE_IMAGE,
        payload: profile,
    };
};

export const deleteSignatureProfileImage = (profile) => {
    return {
        type: DELETE_SIGNATURE_PROFILE_IMAGE,
        payload: profile,
    };
};

export const getSignatureProfileImage = (id) => {
    return {
        type: GET_SIGNATURE_PROFILE_IMAGES,
        payload: id,
    };
};

export const getSignatureProfileImages = (profiles) => {
    return {
        type: GET_SIGNATURE_PROFILE_IMAGES,
        payload: profiles,
    };
};

export const updateSignatureProfileImage = (profile) => {
    return {
        type: UPDATE_SIGNATURE_PROFILE_IMAGE,
        payload: profile,
    };
};

export const resetSignatureProfileImages = () => {
    return {
        type: RESET_SIGNATURE_PROFILE_IMAGES,
    };
};
