/* eslint-disable no-case-declarations */
import {
    UPDATE_SIGNATURE_PROFILE_IMAGE,
    RESET_SIGNATURE_PROFILE_IMAGES,
    DELETE_SIGNATURE_PROFILE_IMAGE,
    ADD_SIGNATURE_PROFILE_IMAGE,
    GET_SIGNATURE_PROFILE_IMAGES,
    GET_SIGNATURE_PROFILE_IMAGE
} from '../actions/actionTypes';
import initialState from './initialState';

export default function(state = initialState.signatureProfileImages, action) {
    switch (action.type) {
        case ADD_SIGNATURE_PROFILE_IMAGE:
            if (state.filter(profile => profile.id === action.payload.id).length === 0) {
                return [...state, action.payload];
            }
            return state;
        case DELETE_SIGNATURE_PROFILE_IMAGE:
            return state.filter((profile) => profile.id !== action.payload.id);

        case  GET_SIGNATURE_PROFILE_IMAGE:
            return state.map((profile) => profile.id === action.payload.id);

        case GET_SIGNATURE_PROFILE_IMAGES:
            return [state];

        case UPDATE_SIGNATURE_PROFILE_IMAGE:
            const index = state.findIndex((profile) => profile.id === action.payload.id);
            const refreshedState = state.map((value) => Object.assign({}, value));
            refreshedState[index].image = action.payload.image;
            return refreshedState;

        case RESET_SIGNATURE_PROFILE_IMAGES:
            return [[]];

        default:
            return state;
    }
}
