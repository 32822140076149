import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'antd';

function FormItem(props) {
    const { children, formItemProps } = props;

    const formItemDefaults = {
        hasFeedback: true,
        wrapperCol: { span: 24 }
    };

    const mergedFormItemProperties = Object.assign(formItemDefaults, formItemProps);

    return <Form.Item {...mergedFormItemProperties}>{children}</Form.Item>;
}

FormItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    formItemProps: PropTypes.object
};

export default FormItem;
