import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';

const withTracker = (WrappedComponent, options = {}) => {
    const debug = process.env.NODE_ENV === 'development';

    const settings = Object.assign(options, { debug: debug });
    const trackPage = (page) => {
        ReactGA.set({
            page,
            settings,
        });
        ReactGA.pageview(page);
    };

    class HOC extends React.Component {
        componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page);
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    HOC.propTypes = {
        location: PropTypes.object,
    };

    return HOC;
};

export default withTracker;
