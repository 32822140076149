/* eslint-disable react-hooks/exhaustive-deps */
import * as containerActions from '../../actions/container';
import { Button, Checkbox, Col, Input, Row, Select, Spin } from 'antd';
import { ajax, getSignModeIconType, countPadding, createNotificationShort } from '../../helper';
import { removeModal, toggleModal } from '../../actions/modal';
import React, { useEffect, useRef, useState } from 'react';
import SignImage from './SignImage';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { resetCheckbox } from '../../actions/enable';
import theme from '../../theme';
import UserProfileError from '../UserProfileError';
import ScrollContainer from 'react-indiana-drag-scroll';
import { TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS, TAB_KEY_DEFAULT_CONTAINERS } from '../../constants/strings';

const { Option } = Select;

const PdfPreviewWithHooks = (props) => {
    const dispatch = useDispatch();
    const pdfDocPage = useRef();
    const [selectedOption, setSelectedOption] = useState(null);
    const [applyToEveryPDF, setApplyToEveryPDF] = useState(false);
    const [userProfileError, setUserProfileError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [emptyImage, setEmptyImage] = useState(true);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [data, setData] = useState('');
    const [pdfReason, setPdfReason] = useState('');
    const [signSchemas, setSignSchemas] = useState([]);
    const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
    const [maxRelativePosition, setMaxRelativePosition] = useState({ x: 0, y: 0 });
    const [relativePosition, setRelativePosition] = useState({ x: 0, y: 0 });
    const [fittedPDFSizeX, setFittedPDFSizeX] = useState(0);
    const [fittedPDFSizeY, setFittedPDFSizeY] = useState(0);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [parentWidth, setParentWidth] = useState(0);
    const [initialPDFSizeX, setInitialPDFSizeX] = useState(0);
    const [initialPDFSizeY, setInitialPDFSizeY] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfReasonLength, setPdfReasonLength] = useState(0);
    const [pdfResizeCounter, setPdfResizeCounter] = useState(0);
    const [pdfSizeX, setPdfSizeX] = useState(0);
    const [pdfSizeY, setPdfSizeY] = useState(0);
    const translate = useSelector((state) => getTranslate(state.locale));
    const containersInSignatureProcess = useSelector((state) => state.containerList.containersInSignatureProcess);
    const modal = useSelector((state) => state.modal);
    const currentTab = useSelector((state) => state.tabs.current);

    useEffect(() => {
        const loadSchemas = async () => {
            await ajax()
                .get('/signatureProfiles')
                .then(({ data }) => {
                    setSignSchemas(data);
                })
                .catch((err) => console.log('error', err))
                .finally(() => {
                    let schema = signSchemas.filter((s) => s.isDefault);
                    setSelectedSchema(schema[0]);
                });
        };

        if (props.container.type === 'pdf') {
            setPageNumber(1);
            setPdfResizeCounter(0);
        }
        loadPdfPage(props.container.id, 1);
        props.setSignaturePosition && loadSchemas();
    }, [props.container]);

    useEffect(() => {
        if (!isLoaded) {
            setIsLoaded(true);
            setParentWidth(pdfDocPage.current.clientWidth);
            setRelative();
        }
    }, [isLoaded]);

    const onDragStop = (e, d) => {
        setDeltaPosition({
            x: d.x,
            y: d.y
        });
        setRelative();
    };

    const setRelative = () => {
        setRelativePosition({
            x: deltaPosition.x === 0 ? 0 : deltaPosition.x / pdfSizeX,
            y: deltaPosition.y === 0 ? 0 : deltaPosition.y / pdfSizeY
        });
    };

    const setMaxRelative = (pdfSizeX, pdfSizeY) => {
        setMaxRelativePosition({
            x: (pdfSizeX - width) / pdfSizeX,
            y: (pdfSizeY - height) / pdfSizeY
        });
    };

    const recountRelative = (pdfSizeX, pdfSizeY) => {
        let newMaxRelativeX = pdfSizeX - width === 0 ? 0 : (pdfSizeX - width) / pdfSizeX;
        let newMaxRelativeY = pdfSizeY - height === 0 ? 0 : (pdfSizeY - height) / pdfSizeY;
        let recountedRelativeX =
            relativePosition.x * (newMaxRelativeX === 0 ? 0 : newMaxRelativeX / maxRelativePosition.x);
        let recountedRelativeY =
            relativePosition.y * (newMaxRelativeY === 0 ? 0 : newMaxRelativeY / maxRelativePosition.y);
        setRelativePosition({
            x: recountedRelativeX,
            y: recountedRelativeY
        });
        setMaxRelativePosition({
            x: newMaxRelativeX,
            y: newMaxRelativeY
        });
    };

    const recountDeltaPosition = (pdfSizeX, pdfSizeY) => {
        setDeltaPosition({
            x: pdfSizeX * relativePosition.x,
            y: pdfSizeY * relativePosition.y
        });
    };

    // eslint-disable-next-line no-unused-vars
    const onResize = (e, dir, ref, delta, position) => {
        let resizedWidth = +ref.style.width.replace('px', '');
        let resizedHeight = +ref.style.height.replace('px', '');
        setWidth(resizedWidth);
        setHeight(resizedHeight);
    };

    const setSelectedSchema = async (selectedOption) => {
        const isSelectable = signSchemas.length > 3 && localStorage.getItem('customSignatureImage') === 'false';
        setUserProfileError(isSelectable);
        if (selectedOption.profileName === 'empty') {
            setEmptyImage(true);
            setSelectedOption(selectedOption);
        } else {
            let base64Image;
            await ajax()
                .get(`/signatureImage/${selectedOption.id}`)
                .then((response) => {
                    base64Image = `data:image/png;base64,${response.data.image}`;
                })
                .catch(async () => {
                    await ajax()
                        .get(`/signatureImage/1`)
                        .then((response) => {
                            base64Image = `data:image/png;base64,${response.data.image}`;
                        })
                        .catch(() => {});
                });
            setBackgroundImage(base64Image);
            setDeltaPosition({
                x: selectedOption.x,
                y: selectedOption.y
            });
            setWidth(selectedOption.width);
            setHeight(selectedOption.height);
            setSelectedOption(selectedOption);
            setEmptyImage(false);
        }
    };

    const loadPdfPage = (id, pageNumber) => {
        setLoading(true);
        ajax()
            .get(`${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${id}/preview?pageNumber=${pageNumber}`)
            .then((data) => {
                const resizeNeeded = data.data.imageWidth > parentWidth - 30 && !setSignaturePosition;
                const rateToFitToScreen = (parentWidth - 30) / data.data.imageWidth;
                const fittedPDFSizeX = resizeNeeded && modal.visible ? parentWidth - 30 : data.data.imageWidth;
                const fittedPDFSizeY =
                    resizeNeeded && modal.visible ? data.data.imageHeight * rateToFitToScreen : data.data.imageHeight;
                if (maxRelativePosition.y !== 0) {
                    recountRelative(data.data.imageWidth, data.data.imageHeight);
                } else {
                    setMaxRelative(data.data.imageWidth, data.data.imageHeight);
                    recountRelative(data.data.imageWidth, data.data.imageHeight);
                }
                recountDeltaPosition(data.data.imageWidth, data.data.imageHeight);
                setLoading(false);
                setData(`data:image/jpeg;base64,${data.data.image}`);
                setInitialPDFSizeX(data.data.imageWidth);
                setInitialPDFSizeY(data.data.imageHeight);
                setFittedPDFSizeX(fittedPDFSizeX);
                setFittedPDFSizeY(fittedPDFSizeY);
                setPdfSizeX(
                    pdfResizeCounter === 0
                        ? fittedPDFSizeX
                        : fittedPDFSizeX + pdfResizeCounter * (data.data.imageWidth / 10)
                );
                setPdfSizeY(
                    pdfResizeCounter === 0
                        ? fittedPDFSizeY
                        : fittedPDFSizeY + pdfResizeCounter * (data.data.imageHeight / 10)
                );
            })
            .catch(() => {
                setLoading(false);
            })
            .finally(() => setLoading(false));
    };

    const goToPrevPage = () => {
        let toSet = pageNumber - 1;
        setPageNumber(toSet);
        recountDeltaPosition();
        loadPdfPage(props.container.id, toSet);
    };
    const goToNextPage = () => {
        let toSet = pageNumber + 1;
        setPageNumber(toSet);
        recountDeltaPosition();
        loadPdfPage(props.container.id, toSet);
    };

    const countPaddingX = (position) => {
        return countPadding(position, initialPDFSizeX);
    };

    const countPaddingY = (position) => {
        return countPadding(position, initialPDFSizeY);
    };

    const continueSigning = () => {
        const { container, cegjegyzekSzam, previewContainerList, signModalData } = props;
        const pdfImagePageToSet = pageNumber === container.pages && container.pages > 1 ? -1 : pageNumber;
        dispatch(removeModal());

        let paddingX = countPaddingX(deltaPosition.x <= 0 ? 0 : deltaPosition.x);
        let paddingY = countPaddingY(deltaPosition.y <= 0 ? 0 : deltaPosition.y);
        const pdfSettings = {
            pdfImagePaddingX: paddingX,
            pdfImagePaddingY: paddingY,
            pdfImagePage: pdfImagePageToSet,
            pdfImageResize: Math.floor((width / initialPDFSizeX) * 100),
            signatureProfileId: selectedOption ? selectedOption.id : null,
            pdfReason: pdfReason
        };
        signModalData.push({
            containerId: container.id,
            pdfSettings
        });
        const pdfs = previewContainerList.filter((e) => e.isPdf === true);
        const tmpList = pdfs.filter(
            (e) => signModalData.map((data) => data.containerId).indexOf(e.container.id) === -1
        );

        if (applyToEveryPDF) {
            tmpList.forEach(({ container }) => {
                signModalData.push({
                    containerId: container.id,
                    pdfSettings
                });
            });
        }

        if (tmpList.length > 0 && !applyToEveryPDF) {
            dispatch(removeModal());
            setTimeout(function () {
                dispatch(
                    toggleModal('SIG_POSITION', {
                        cegjegyzekSzam,
                        container: tmpList[0].container,
                        imageHeight: tmpList[0].height,
                        imageWidth: tmpList[0].width,
                        previewContainerList: previewContainerList,
                        signModalData: signModalData
                    })
                );
            }, 500);
        } else {
            dispatch(
                toggleModal('SIGN', {
                    containerIds:
                        previewContainerList.length > 0
                            ? previewContainerList.map((p) => p.container.id)
                            : [container.id],
                    ack: false,
                    cegjegyzekSzam,
                    previewSchemaId: selectedOption
                        ? selectedOption.extension === 'png'
                            ? selectedOption.id
                            : 1
                        : null,
                    signModalData: signModalData,
                    onFinished: (result) => {
                        const currentContainerIdList =
                            previewContainerList.length > 0
                                ? previewContainerList.map((p) => p.container.id)
                                : [container.id];
                        if (result.succeeded) {
                            setTimeout(() => {
                                dispatch(removeModal());
                            }, 500);
                            dispatch(containerActions.getContainers());
                            dispatch(containerActions.getContainersInSignatureProcess());
                            if (currentTab === TAB_KEY_DEFAULT_CONTAINERS) {
                                dispatch(containerActions.getContainerSignatures(container.type, container.id));
                            } else if (currentTab === TAB_KEY_CONTAINERS_IN_SIGNATURE_PROCESS) {
                                let newContainerToSignArray = [...containersInSignatureProcess];
                                let containersToSignIds = containersInSignatureProcess.map(
                                    (container) => container.data.id
                                );
                                for (let i = 0; i < currentContainerIdList.length; i++) {
                                    if (containersToSignIds.includes(currentContainerIdList[i])) {
                                        newContainerToSignArray = newContainerToSignArray.filter((container) => {
                                            return currentContainerIdList[i] !== container.data.id;
                                        });
                                    }
                                }
                                dispatch(containerActions.getContainersInSignatureProcessSuccess(newContainerToSignArray));
                                dispatch(resetCheckbox());
                            } else {
                                // should not happen
                                createNotificationShort(this.props.translate, {
                                    message: this.props.translate('errorCodes.USER_INTERRUPTED_SIGN'),
                                    type: 'error',
                                });
                                dispatch(removeModal());
                            }
                        }
                    }
                }));
        }
    };

    const handleChangeSchema = (value) => {
        let selectedSchemaId = parseInt(value);
        let schemas = signSchemas;
        for (let i = 0; i < schemas.length; i++) {
            let schema = schemas[i];
            if (schema.id === selectedSchemaId) {
                setSelectedSchema(schema);
                return;
            }
        }
    };

    const translateSchemaName = (schema) => {
        if (schema.id === 1) {
            return translate('default.sign');
        }
        if (schema.id === 2) {
            return translate('invisible.sign');
        }
        return schema.profileName;
    };

    const setDefaultSchema = () => {
        let selectedSchema = selectedOption;
        if (selectedSchema) {
            return translateSchemaName(selectedSchema);
        }
        let schemas = signSchemas;
        for (let i = 0; i < schemas.length; i++) {
            let schema = schemas[i];
            if (schema.isDefault) {
                return translateSchemaName(schema);
            }
        }
        return null;
    };

    const handleZoomIn = () => {
        const toAddX = Math.floor(fittedPDFSizeX / 10);
        const toAddY = Math.floor(fittedPDFSizeY / 10);
        setPdfSizeX(pdfSizeX + toAddX);
        setPdfSizeY(pdfSizeY + toAddY);
        setPdfResizeCounter(pdfResizeCounter + 1);
    };

    const handleZoomOut = () => {
        const toSubtractX = Math.floor(fittedPDFSizeX / 10);
        const toSubtractY = Math.floor(fittedPDFSizeY / 10);
        setPdfSizeX(pdfSizeX - toSubtractX);
        setPdfSizeY(pdfSizeY - toSubtractY);
        setPdfResizeCounter(pdfResizeCounter - 1);
    };

    const handleResetZoom = () => {
        setPdfSizeX(fittedPDFSizeX);
        setPdfSizeY(fittedPDFSizeY);
        setPdfResizeCounter(0);
    };

    const { setSignaturePosition, container, previewContainerList } = props;
    const imgStyle = { height: pdfSizeY, width: pdfSizeX };
    const pdfDocPageStyleForModal = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        borderRadius: '4px',
        left: '+7px',
        position: 'relative'
    };
    const pdfDocPageStyle = {
        display: 'table',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        borderRadius: '4px',
        left: '+7px',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.09) 0 2px 8px 1px',
        border: '1px solid rgba(0, 0, 0, 0.09)'
    };

    const pdfWrapperStyle = {
        overflow: 'auto',
        paddingTop: '8px',
        paddingBottom: '8px'
    };
    const styleToUseForDocPage = modal.visible ? pdfDocPageStyle : pdfDocPageStyleForModal;
    const styleToUseForWrapper = modal.visible ? pdfWrapperStyle : {};
    const SelectElement = () => {
        return (
            <Select
                style={{ width: 200 }}
                onSelect={handleChangeSchema}
                defaultValue={setDefaultSchema()}>
                {signSchemas
                    ? signSchemas.map((schema) => <Option key={schema.id}>{translateSchemaName(schema)}</Option>)
                    : null}
            </Select>
        );
    };

    return (
        <div className={'pdf-doc-container'}>
            {setSignaturePosition && (
                <Row>
                    <Col
                        span={6}
                        style={{ fontWeight: 'bold', paddingTop: '8px' }}>
                        {translate('signature.image.title.reason') + ':'}
                    </Col>
                    <Col span={18}>
                        <Input
                            placeholder={translate('signature.image.title.reason')}
                            value={pdfReason}
                            onChange={(e) => {
                                setPdfReason(e.target.value);
                                setPdfReasonLength(e.target.value.length);
                            }}
                            maxLength={1000}
                        />
                        {pdfReasonLength >= 1000 && <p style={{ color: 'red' }}>{translate('reason.length')}</p>}
                    </Col>
                </Row>
            )}
            <div style={{ paddingTop: '8px' }}>
                <nav
                    style={{
                        textAlign: 'center',
                        padding: 16,
                        marginBottom: 16,
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #e8e8e8'
                    }}>
                    <Row style={{ textAlign: 'left' }}>
                        {setSignaturePosition && previewContainerList.length > 0 && (
                            <Checkbox
                                onChange={(e) => {
                                    setApplyToEveryPDF(e.target.checked);
                                }}>
                                {' '}
                                {translate('ckeckbox.signimage.text')}
                            </Checkbox>
                        )}
                    </Row>
                    <Row>
                        <Col
                            span={8}
                            style={{ textAlign: 'left' }}>
                            {setSignaturePosition && <SelectElement />}
                        </Col>
                        <Col span={8}>
                            <Button
                                type={'primary'}
                                style={{ marginRight: 10 }}
                                disabled={pageNumber <= 1 || loading}
                                onClick={() => goToPrevPage()}
                                icon={'left'}
                            />
                            <span style={{ color: theme.textColor }}>
                                {pageNumber}/{container.pages}
                            </span>
                            <Button
                                type={'primary'}
                                style={{ marginLeft: 10 }}
                                disabled={container.pages <= pageNumber || loading}
                                onClick={() => goToNextPage()}
                                icon={'right'}
                            />
                        </Col>
                        {!modal.visible && (
                            <Col span={4}>
                                <Button
                                    style={{ marginRight: 10 }}
                                    title={translate('button.zoom.in')}
                                    type={'primary'}
                                    onClick={handleZoomIn}
                                    icon={'zoom-in'}
                                />
                                <span />
                                <Button
                                    style={{ marginRight: 10 }}
                                    title={translate('button.zoom.out')}
                                    type={'primary'}
                                    onClick={handleZoomOut}
                                    icon={'zoom-out'}
                                />
                                <Button
                                    title={translate('button.zoom.reset')}
                                    type={'primary'}
                                    onClick={handleResetZoom}
                                    icon={'undo'}
                                />
                            </Col>
                        )}
                        <Col
                            span={8}
                            style={{ textAlign: 'right' }}>
                            {setSignaturePosition && (
                                <Button
                                    disabled={userProfileError}
                                    type="primary"
                                    icon={getSignModeIconType()}
                                    onClick={() => {
                                        continueSigning();
                                    }}>
                                    {translate('buttons.sign.text')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                    {userProfileError && (
                        <Row style={{ paddingTop: '8px' }}>
                            <UserProfileError
                                size={64}
                                icon="user"
                            />
                        </Row>
                    )}
                </nav>
                <Spin spinning={loading}>
                    <div
                        className="pdf-doc-page-wrapper"
                        style={styleToUseForWrapper}>
                        <div
                            className="pdf-doc-page"
                            style={styleToUseForDocPage}
                            ref={pdfDocPage}>
                            {setSignaturePosition && !emptyImage && (
                                <SignImage
                                    props={{
                                        width: width,
                                        height: height,
                                        deltaPosition: deltaPosition,
                                        minConstraints: [150, height * (150 / height)],
                                        maxConstraints: [600, height * (600 / width)],
                                        onDragStop: onDragStop,
                                        onResize: onResize,
                                        namePosition: false
                                    }}
                                    backgroundImage={backgroundImage}
                                />
                            )}
                            {!modal.visible ? (
                                <ScrollContainer
                                    className="scroll-container"
                                    nativeMobileScroll={false}
                                    hideScrollbars={false}>
                                    <img
                                        style={imgStyle}
                                        src={data}
                                        alt={'PDF document'}
                                    />
                                </ScrollContainer>
                            ) : (
                                <img
                                    style={imgStyle}
                                    src={data}
                                    alt={'PDF document'}
                                    draggable="false"
                                />
                            )}
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    );
};

PdfPreviewWithHooks.defaultProps = {
    currentTab: TAB_KEY_DEFAULT_CONTAINERS,
    pdfSizeX: 594,
    pdfSizeY: 841,
    setSignaturePosition: false
};

export default PdfPreviewWithHooks;
