import AuthenticationTabs from '../AuthenticationTabs';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';

class AuthenticationModal extends React.Component {
    render() {
        return (
            <ModalWrapper {...this.props}>
                <p id="anonymSign">{this.props.translate('texts.anonymSign')}</p>
                <AuthenticationTabs embedded={true} />
            </ModalWrapper>
        );
    }
}

AuthenticationModal.propTypes = {
    translate: PropTypes.func,
};

export default AuthenticationModal;
