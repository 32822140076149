import DeleteContainerButton from './buttons/DeleteContainerButton';
import PropTypes from 'prop-types';
import React from 'react';
import RefuseSignButton from './buttons/RefuseSignButton';
import authService from '../services/authService';
import { Button, Divider, Dropdown, Icon, Menu } from 'antd';
import {
    ajax,
    createNotificationShort,
    getSignModeIconType,
    PreviewContainerList,
    downloadContainersInZip,
    createNotification,
} from '../helper';
import { connect } from 'react-redux';
import { getContainers, getContainersInSignatureProcess, approveContainers } from '../actions/container';
import { getTranslate } from 'react-localize-redux/lib/index';
import { isEqual } from 'lodash';
import { removeModal, toggleModal } from '../actions/modal';
import { getAttributeUsage } from '../actions/user';
import { confirmationModal } from './modals/ConfirmationModal';
import {
    ATTRIBUTE_ROLES,
    COMPANY_REGISTRATION,
    SELECT_CERTIFICATE,
    SEND_FOR_SIGNATURE,
    SIGN,
    SIGNATURE_POSITION,
    USER_PROFILE_ERROR
} from '../constants/modalNames';
import { isBlank } from '../utilities/stringUtilities';
import { TAB_KEY_DEFAULT_CONTAINERS } from '../constants/strings';

class MultipleContainersControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            container: null,
            imageHeight: 0,
            imageWidth: 0,
            previousContainerIds: this.props.containerIds,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isThereAnyDifferentContainer = (previous, actual) => {
        return !isEqual(previous, actual);
    };

    getContainerWithPages = (id) => {
        return ajax()
            .get(`/containers/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };

    getPdfSize(id) {
        return ajax()
            .get(`${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${id}/preview?pageNumber=1`)
            .then((data) => {
                this._isMounted &&
                    this.setState({ imageHeight: data.data.imageHeight, imageWidth: data.data.imageWidth });
                return {
                    imageHeight: data.data.imageHeight,
                    imageWidth: data.data.imageWidth,
                };
            })
            .catch((error) => {
                this._isMounted && this.setState({ error: error.response.data.error });
            });
    }

    sign = (containerIds, selectedRole, certificate) => {
        const { userProfileErrorModal, translate } = this.props;
        const maxContainerSignNumber = parseInt(localStorage.getItem('massDocumentSignMaxNumber'));

        if (containerIds.length > maxContainerSignNumber) {
            userProfileErrorModal([
                translate('modals.massSigning.maxContainerSignNumberText') + ' ' + maxContainerSignNumber,
            ]);
            return;
        }

        const { showSignModal, showSignaturePositioning, containers } = this.props;
        const pdfs = containers.filter((container) => container.data.type === 'pdf');
        const others = containers.filter((container) => container.data.type !== 'pdf');
        let previewContainerList = [];
        let signModalData = [];

        for (let i = 0; i < others.length; i++) {
            signModalData.push({
                containerId: others[i].data.id,
                pdfSettings: null,
            });
            previewContainerList.push(new PreviewContainerList(others[i].data, 0, 0, false));
        }

        if (pdfs.length > 0) {
            let promises = [];
            pdfs.forEach((pdf) => {
                promises.push(this.getContainerWithPages(pdf.data.id));
            });
            Promise.all(promises).then((containerWPages) => {
                promises = [];
                pdfs.forEach((pdf) => {
                    promises.push(this.getPdfSize(pdf.data.id));
                });
                Promise.all(promises).then((dimensions) => {
                    for (let i = 0; i < dimensions.length; i++) {
                        previewContainerList.push(
                            new PreviewContainerList(
                                containerWPages[i],
                                dimensions[i].imageHeight,
                                dimensions[i].imageWidth,
                                true
                            )
                        );
                    }
                    showSignaturePositioning({
                        signModalData: signModalData,
                        previewContainerList: previewContainerList,
                        container: containerWPages[0],
                        imageHeight: dimensions[0].imageHeight,
                        imageWidth: dimensions[0].imageWidth,
                        certificate: certificate,
                        attributeRole: selectedRole,
                    });
                });
            });
        } else {
            showSignModal({
                ack: false,
                onFinished: this.onSignFinished,
                signModalData,
                attributeRole: selectedRole,
                certificate: certificate,
            });
        }
    };

    onSignFinished = (result) => {
        const { refreshContainersList, hideSignModal } = this.props;
        if (result.succeeded) {
            setTimeout(() => {
                hideSignModal();
                refreshContainersList();
            }, 1000);
        }
    };

    download = () => {
        const { containerIds } = this.props;
        downloadContainersInZip(containerIds);
    };

    hasNoPermission = () => {
        const { containers, translate, userProfileErrorModal } = this.props;
        const canSignContainer = localStorage.getItem('signContainers') === 'true';
        const canSignPDF = localStorage.getItem('signPDF') === 'true';
        const forbidContainerSignature =
            containers.filter((container) => container.data.type === 'es3' || container.data.type === 'asice').length >
                0 && !canSignContainer;
        const forbidPDFSignature =
            containers.filter((container) => container.data.type === 'pdf').length > 0 && !canSignPDF;
        const locale = [];
        if (forbidContainerSignature) {
            locale.push(translate('user.profile.error.cantSignContainer'));
        }
        if (forbidPDFSignature) {
            locale.push(translate('user.profile.error.cantSignPDF'));
        }
        if (forbidContainerSignature || forbidPDFSignature) {
            userProfileErrorModal(locale);
            return true;
        }
        return false;
    };

    approve = (filteredContainerToApproveIds, filteredContainerIds) => {
        const { approveContainer, refreshContainersList, translate } = this.props;
        confirmationModal(
            translate,
            () =>
                approveContainer(filteredContainerToApproveIds)
                    .then(() => {
                        refreshContainersList();
                        if (filteredContainerIds && filteredContainerIds.length > 0) {
                            this.sign(filteredContainerIds);
                        } else {
                            createNotificationShort(translate, {
                                message: translate('notifications.approve.success'),
                                type: 'success'
                            });
                            refreshContainersList();
                        }
                    })
                    .catch((e) => {
                        createNotification(this.props.translate, e.response.data.code, {
                            message: translate('notifications.approve.failure'),
                            type: 'error',
                        });
                    }),
            filteredContainerIds && filteredContainerIds.length > 0
                ? translate('confirm.approveAndSign')
                : translate('confirm.approve')
        );
    };

    start = () => {
        let certificate;
        let attributeUsage;
        this.props.getAttributeUsage().then((attributeUsageResponse) => {
            attributeUsage = attributeUsageResponse.data.attributeCertUsage;
            ajax()
                .get('/user/certificate')
                .then((response) => {
                    console.debug('Signing with default user certificate.');
                    certificate = response.data;
                    if (attributeUsage && !isBlank(certificate)) {
                        this.props.openAttributeRolesModal({
                            startSign: (selectedRole, certificate) => this.signAndApprove(selectedRole, certificate)
                        });
                    } else {
                        this.signAndApprove(null, certificate);
                    }
                }).catch(() => {
                if (attributeUsage && isBlank(certificate)) {
                    this.props.openSelectCertificateModal({
                        startSign: (selectedRole, certificate) => this.signAndApprove(selectedRole, certificate)
                    });
                } else {
                    this.signAndApprove(null, certificate);
                }
            });
        });
    };

    signAndApprove = (selectedRole, certificate) => {
        const { containers, containerIds } = this.props;
        const hasContainers = containers.filter((container) => container.data.action !== 'approve').length > 0;
        const hasContainersToApprove = containers.filter((container) => container.data.action === 'approve').length > 0;
        if (hasContainers && !hasContainersToApprove) {
            this.sign(containerIds, selectedRole, certificate);
        } else if (!hasContainers && hasContainersToApprove) {
            this.approve(containerIds, null);
        } else if (hasContainers && hasContainersToApprove) {
            const filteredContainerIds = containers
                .filter((container) => container.data.action === 'sign')
                .map((container) => container.data.id);
            const filteredContainerToApproveIds = containers
                .filter((container) => container.data.action === 'approve')
                .map((container) => container.data.id);
            this.approve(filteredContainerToApproveIds, filteredContainerIds);
        }
    };

    getSignButton = (containers) => {
        const { showCompanyRegistrationModal, translate, containerIds } = this.props;
        const containersContainAck = containers.filter((container) => container.data.type === 'et3').length > 0;
        const hasContainers = containers.filter((container) => container.data.action !== 'approve').length > 0;
        const hasContainersToApprove = containers.filter((container) => container.data.action === 'approve').length > 0;
        const isHungarian = localStorage.getItem('language') === 'hu';
        const hasAsic = containers.filter((container) => container.data.type === 'asic').length > 0;

        let signButtonText = translate('buttons.sign.text');
        if (hasContainers && hasContainersToApprove) {
            signButtonText = translate('buttons.sign.text') + '/' + translate('sendForSignature.approve');
        } else if (!hasContainers && hasContainersToApprove) {
            signButtonText = translate('sendForSignature.approve');
        }

        if (hasContainers && isHungarian && !hasAsic && !hasContainersToApprove) {
            return (
                <Dropdown.Button
                    id={'companyRegistrationSign'}
                    type={'primary'}
                    onClick={this.start}
                    trigger={['click']}
                    overlay={
                        <Menu
                            onClick={() =>
                                showCompanyRegistrationModal({
                                    containers: containers,
                                    containerIds: containerIds,
                                    isMultiple: containers.length > 1
                                })
                            }
                        >
                            <Menu.Item key='1'>
                                <Icon type='form' />
                                Aláírás cégképviseleti igazolással
                            </Menu.Item>
                        </Menu>
                    }>
                    <Icon type={getSignModeIconType()} />
                    {signButtonText}
                </Dropdown.Button>
            );
        } else {
            return (
                <Button
                    type={'primary'}
                    disabled={this.hasNoPermission() || (!hasContainers && !hasContainersToApprove) || containersContainAck}
                    onClick={this.start}
                >
                    <Icon type={getSignModeIconType()} />
                    {signButtonText}
                </Button>
            );
        }
    };

    render() {
        const { containers, translate, currentTab } = this.props;
        const hasContainers = containers.length > 0;
        const isDefaultContainer = currentTab === TAB_KEY_DEFAULT_CONTAINERS;
        const menu = (
            <Menu>
                <Menu.Item
                    key='sendForSignature'
                    onClick={() => {
                        this.props.sendForSignatureModal(containers, translate);
                    }}>
                    {translate('modals.sendForSignature.title')}
                </Menu.Item>
            </Menu>
        );
        return (
            <div
                style={{
                    marginBottom: 10
                }}>
                {this.getSignButton(containers)}
                <Divider type="vertical" />
                <Button
                    type={'default'}
                    disabled={!hasContainers}
                    key={'download'}
                    title={translate('buttons.download.container')}
                    onClick={this.download}
                    icon={'download'}
                />
                <Divider type='vertical' />
                {isDefaultContainer ? (
                    <DeleteContainerButton
                        currentTab={currentTab}
                        disabled={!hasContainers}
                    />
                ) : (
                    <RefuseSignButton disabled={true} />
                )}
                <Divider type="vertical" />
                {currentTab === TAB_KEY_DEFAULT_CONTAINERS && authService.isRegistered() && (
                    <Dropdown
                        trigger={['click']}
                        overlay={menu}
                        disabled={!hasContainers}>
                        <Button title={translate('buttons.fileOptions')}>
                            <Icon type="ellipsis" />
                        </Button>
                    </Dropdown>
                )}
            </div>
        );
    }
}

MultipleContainersControls.propTypes = {
    containerIds: PropTypes.array.isRequired,
    containers: PropTypes.array.isRequired,
    currentTab: PropTypes.string.isRequired,
    hideSignModal: PropTypes.func.isRequired,
    refreshContainersList: PropTypes.func.isRequired,
    sendForSignatureModal: PropTypes.func.isRequired,
    showCompanyRegistrationModal: PropTypes.func.isRequired,
    showSignaturePositioning: PropTypes.func.isRequired,
    showSignModal: PropTypes.func.isRequired,
    signatureProfiles: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
    approveContainer: PropTypes.func.isRequired,
    userProfileErrorModal: PropTypes.func.isRequired,
    getAttributeUsage: PropTypes.func.isRequired,
    openSelectCertificateModal: PropTypes.func.isRequired,
    openAttributeRolesModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        containers: state.containerList.selectedContainers,
        containerIds: state.containerList.selectedContainers.map((c) => c.data.id),
        currentTab: state.tabs.current,
        imageHeight: state.imageHeight,
        imageWidth: state.imageWidth,
        signatureProfiles: state.signatureProfiles,
        translate: getTranslate(state.locale)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideSignModal: () => {
            dispatch(removeModal());
        },
        refreshContainersList: () => {
            dispatch(getContainersInSignatureProcess());
            dispatch(getContainers());
        },
        sendForSignatureModal: () => {
            dispatch(toggleModal(SEND_FOR_SIGNATURE));
        },
        showCompanyRegistrationModal: (data) => {
            return dispatch(toggleModal(COMPANY_REGISTRATION, data));
        },
        showSignaturePositioning: (data) => {
            dispatch(toggleModal(SIGNATURE_POSITION, data));
        },
        showSignModal: (data) => {
            dispatch(toggleModal(SIGN, data));
        },
        userProfileErrorModal: (locale) => {
            dispatch(toggleModal(USER_PROFILE_ERROR, { locale: locale }));
        },
        approveContainer: (containerIds) => {
            return dispatch(approveContainers(containerIds));
        },
        getAttributeUsage: () => {
            return dispatch(getAttributeUsage());
        },
        openAttributeRolesModal: (callback) => {
            dispatch(toggleModal(ATTRIBUTE_ROLES, callback));
        },
        openSelectCertificateModal: (callback) => {
            dispatch(toggleModal(SELECT_CERTIFICATE, callback));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultipleContainersControls);
