import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';

const SignImage = ({ props, backgroundImage }) => {
    const [fontHeight, setFontHeight] = useState(0);

    const measuredRef = useCallback((node) => {
        if (node !== null) {
            setFontHeight(node.getBoundingClientRect().height);
        }
    }, []);

    const titleStyle = () => {
        let dateExtensionPlace = 0.54 * fontHeight;
        return {
            marginTop: props.datePosition
                ? Math.round(0.83 * props.height - (fontHeight + dateExtensionPlace))
                : Math.round(0.83 * props.height - fontHeight),
            marginLeft: Math.round(0.07 * props.width),
            fontSize: props.fontSize,
            fontFamily: 'CustomFont',
            whiteSpace: 'nowrap',
            letterSpacing: '1px',
        };
    };

    const dateStyle = () => {
        return {
            marginTop: props.namePosition ? null : Math.round(0.83 * props.height - fontHeight),
            marginLeft: Math.round(0.07 * props.width),
            fontSize: props.fontSize,
            fontFamily: 'CustomFont',
            whiteSpace: 'nowrap',
            letterSpacing: '1px',
        };
    };

    const backgroundStyle = () => {
        return {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: `url("${backgroundImage}")`,
        };
    };

    return (
        <Rnd
            onResize={props.onResize}
            onDragStop={props.onDragStop}
            enableResizing={props.isResizingAndDraggingDisabled ? false : { bottomRight: true }}
            lockAspectRatio={true}
            bounds={'parent'}
            size={{ width: props.width, height: props.height }}
            position={{ x: props.deltaPosition.x, y: props.deltaPosition.y }}
            minHeight={props.minConstraints[1]}
            minWidth={props.minConstraints[0]}
            maxHeight={props.maxConstraints[1]}
            maxWidth={props.maxConstraints[0]}
            style={backgroundStyle()}
            disableDragging={props.isResizingAndDraggingDisabled}
        >
            <div style={{ overflow: 'hidden', height: props.height }}>
                <SignImageBorder width={props.width} height={props.height} />
                <ResizeCorner />
                {props.namePosition ? (
                    <div style={titleStyle()} ref={measuredRef}>
                        {props.translate('user.name')}
                    </div>
                ) : null}
                {props.datePosition ? (
                    <div style={dateStyle()} ref={measuredRef}>
                        {dateString()}
                    </div>
                ) : null}
            </div>
        </Rnd>
    );
};

SignImage.propTypes = {
    minConstraints: PropTypes.number,
    maxConstraints: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    pdfSizeX: PropTypes.number,
    translate: PropTypes.func,
    deltaPosition: PropTypes.object,
    onDragStop: PropTypes.func,
    onResize: PropTypes.func,
    backgroundImage: PropTypes.string,
    props: PropTypes.object,
    datePosition: PropTypes.number,
    fontSize: PropTypes.number,
    namePosition: PropTypes.number,
    isResizingAndDraggingDisabled: PropTypes.bool,
};

const dateString = () => {
    return '2000-01-01 00:00:00 +0000';
};

const SignImageBorder = ({ width, height }) => {
    const signImageBorderStyle = {
        position: 'absolute',
        width: width + 10,
        height: height + 10,
        top: '-5px',
        left: '-5px',
        border: '4px dashed #7f96ed',
    };
    return <div style={signImageBorderStyle}></div>;
};

SignImageBorder.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

const ResizeCorner = () => {
    return <span className="resize-corner"></span>;
};

export default SignImage;
