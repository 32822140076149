import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function (state = initialState.documentTitles, action) {
    switch (action.type) {
        case types.GET_DOCUMENT_TITLES_SUCCESS:
            return [...action.payload];
        case types.RESET_DOCUMENT_TITLES:
            return [[]];
        default:
            return state;
    }
}
