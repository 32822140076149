import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { toggleModal } from '../../actions/modal';
import { connect } from 'react-redux';
import { getDocuments } from '../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { Button, Checkbox } from 'antd';
import * as userActions from '../../actions/user';
import { NOTIFY_USER } from '../../constants/modalNames';

class NotifyUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkbox: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange = () => {
        this._isMounted && this.setState({ checkbox: !this.state.checkbox });
    };

    render() {
        const pricesLink = getDocuments('price_list', this.props.currentLanguage);
        const orderLink = getDocuments('order', this.props.currentLanguage);
        const premiumServices = getDocuments('premium_services', this.props.currentLanguage);
        const link1 = this.props.translate('prices.link');
        const link2 = this.props.translate('order.link');
        const link3 = this.props.translate('premium.link');
        return (
            <ModalWrapper {...this.props} title={this.props.translate('warning')}>
                <p>
                    {this.props.translate('userSubscriptionNotification', {
                        date: window.config.REACT_APP_RELEASE_DATE,
                        priceLink: '<a href=' + pricesLink + " target={'_blank'} >" + link1 + '</a>',
                        orderLink: '<a href=' + orderLink + " target={'_blank'} >" + link2 + '</a>',
                        premiumLink: '<a href=' + premiumServices + " target={'_blank'} >" + link3 + '</a>',
                    })}
                </p>
                <p>
                    <Checkbox onChange={this.onChange}>{this.props.translate('checkbox.notToSee')}</Checkbox>
                </p>
                <p>
                    <Button type="primary" block onClick={() => this.props.stopNotifyUserModal(this.state.checkbox)}>
                        OK
                    </Button>
                </p>
            </ModalWrapper>
        );
    }
}

NotifyUserModal.propTypes = {
    translate: PropTypes.func.isRequired,
    stopNotifyUserModal: PropTypes.func.isRequired,
    currentLanguage: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        stopNotifyUserModal: (checkbox) => {
            checkbox && userActions.stopNotifyUser()();
            dispatch(toggleModal(NOTIFY_USER));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyUserModal);
