import {
    CREATE_FREQUENTLY_USED_EMAIL,
    UPDATE_FREQUENTLY_USED_EMAIL,
    REFRESH_FREQUENTLY_USED_EMAIL,
    RESET_FREQUENTLY_USED_EMAIL,
} from './actionTypes';

export const createFrequentlyUsedEmailAddress = (emailAddress) => {
    return {
        type: CREATE_FREQUENTLY_USED_EMAIL,
        payload: emailAddress,
    };
};

export const updateFrequentlyUsedEmailAddress = (emailAddress) => {
    return {
        type: UPDATE_FREQUENTLY_USED_EMAIL,
        payload: emailAddress,
    };
};

export const refreshFrequentlyUsedEmailAddresses = (emailAddress) => {
    return {
        type: REFRESH_FREQUENTLY_USED_EMAIL,
        payload: emailAddress,
    };
};

export const resetFrequentlyUsedEmailAddresses = () => {
    return {
        type: RESET_FREQUENTLY_USED_EMAIL,
    };
};
