import MenuMarkup from './MenuMarkup';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';
import throttle from 'lodash/throttle';
import { Popover, Icon } from 'antd';

class ResponsiveNav extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        viewportWidth: 0,
        menuVisible: false,
    };

    componentDidMount() {
        this._isMounted = true;
        this.saveViewportDimensions();
        window.addEventListener('resize', this.saveViewportDimensions);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.saveViewportDimensions);
    }

    handleMenuVisibility = (menuVisible) => {
        this._isMounted && this.setState({ menuVisible });
    };

    saveViewportDimensions = throttle(() => {
        this._isMounted &&
            this.setState({
                viewportWidth: window.innerWidth,
            });
    }, this.props.applyViewportChange);

    render() {
        if (this.state.viewportWidth > this.props.mobileBreakPoint) {
            return (
                <MenuMarkup
                    translate={this.props.translate}
                    activeLinkKey={this.props.activeLinkKey}
                    lightStyle={this.props.lightStyle}
                />
            );
        }
        return (
            <Popover
                content={
                    <MenuMarkup
                        translate={this.props.translate}
                        activeLinkKey={this.props.activeLinkKey}
                        lightStyle={this.props.lightStyle}
                        mobileVersion
                        onSubMenuItemClick={() => {
                            this.handleMenuVisibility(false);
                        }}
                    />
                }
                trigger="click"
                placement={this.props.placement}
                visible={this.state.menuVisible}
                onVisibleChange={this.handleMenuVisibility}
            >
                <Icon
                    style={{
                        height: 48,
                        float: 'right',
                        marginRight: 24,
                        marginTop: 24,
                        fontSize: 26,
                        color: this.props.lightStyle ? theme.textColor : theme.textColorLight,
                    }}
                    type="menu"
                />
            </Popover>
        );
    }
}

ResponsiveNav.propTypes = {
    mobileBreakPoint: PropTypes.number,
    applyViewportChange: PropTypes.number,
    activeLinkKey: PropTypes.string,
    placement: PropTypes.string,
    translate: PropTypes.func,
    lightStyle: PropTypes.bool,
};

ResponsiveNav.defaultProps = {
    mobileBreakPoint: 725,
    applyViewportChange: 250,
    placement: 'bottom',
};

export default ResponsiveNav;
