import React from 'react';
import ReactGA from 'react-ga';
import { NEW_CONTAINER } from '../constants/modalNames';
import { Button, Col, Dropdown, Icon, Menu, notification, Row } from 'antd';
import {
    createNotification,
    createNotificationShort,
    getContainerTypeFromFilename,
    removeExtension,
    validateFileSize
} from '../helper';
import { getTranslate } from 'react-localize-redux/lib/index';
import { toggleModal } from '../actions/modal';
import { updateSelectedContainerList, uploadContainer } from '../actions/container';
import { useDispatch, useSelector } from 'react-redux';

export default function ContainerControls() {
    const dispatch = useDispatch();

    const translate = useSelector((state) => getTranslate(state.locale));

    function handleContainerUpload(event) {
        let documentFocus = 1;
        // eslint-disable-next-line
        Array.from(event.target.files).forEach((file, i) => {
            try {
                const formData = new FormData();
                formData.append('file', file);

                validateFileSize(file);

                const containerType = getContainerTypeFromFilename(file.name);
                if (!containerType) {
                    createNotificationShort(translate, {
                        description: translate('notifications.uploadUnknownTypeFailure.description', {
                            fileName: file.name
                        }),
                        message: translate('notifications.uploadUnknownTypeFailure.message'),
                        type: 'error'
                    });
                }

                ReactGA.event({
                    action: 'Container Upload',
                    category: 'User'
                });

                dispatch(uploadContainer(formData))
                    .then((res) => {
                        if (documentFocus-- === 1) {
                            dispatch(updateSelectedContainerList([res]));
                        }
                        notification.open({
                            description: translate('notifications.uploadOK.description', {
                                fileName: file.name
                            }),
                            message: translate('notifications.uploadOK.message'),
                            type: 'success',
                            duration: 10
                        });
                        if (removeExtension(file.name) !== res.data.title) {
                            notification.open({
                                message: translate('notifications.uploadOK.fileNameChanged'),
                                type: 'info',
                                duration: 10
                            });
                        }
                    })
                    .catch((error) => {
                        createNotification(translate, error.response.data.code, {
                            description: translate(`errorCodes.${error.response.data.code}`),
                            message: translate('notifications.uploadFailure.message'),
                            type: 'error'
                        });
                    });
            } catch (err) {
                if (err.message === 'USER_MAX_UPLOAD_SIZE_ERROR') {
                    createNotification(translate, err.response.data.code, {
                        description: translate(`errorCodes.${err.message}`),
                        message: translate('notifications.uploadFailure.message'),
                        type: 'error'
                    });
                }
            }
        });
        // Reset <input type="file" ..> to upload the same file multiple times (onChange do not trigger if this is not set)
        event.target.value = null;
    };

    return (
        <div>
            <Row
                align={'middle'}
                justify={'center'}
                style={{ padding: '10px 0' }}
                type={'flex'}>
                <Col xs={24}>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item onClick={() => dispatch(toggleModal(NEW_CONTAINER))}>
                                    <Icon type={'folder-add'} /> {translate('menus.dropdown.create')}
                                </Menu.Item>
                                <Menu.Item onClick={() => document.getElementById('containerUpload').click()}>
                                    <Icon type={'folder-open'} /> {translate('menus.dropdown.open')}
                                    <input
                                        accept={
                                            '.es3, .et3, .dosszie, .dossier, .nsack, .asice, .asics, .sce, .scs, .asic, .zip, .pdf, application/vnd.eszigno3+xml, application/vnd.etsi.asic-e+zip, application/vnd.etsi.asic-s+zip, application/pdf'
                                        }
                                        id={'containerUpload'}
                                        multiple={true}
                                        onChange={handleContainerUpload}
                                        style={{ display: 'none' }}
                                        type={'file'}
                                    />
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}>
                        <Button type={'primary'}>
                            <Icon type={'plus'} />
                            {translate('buttons.new')}
                            <Icon type={'down'} />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </div>
    );
}
