import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import moment from 'moment';
import { sendCleanUpRequest } from '../../actions/admin';

export default function DatabaseCleanUp(props) {
    const dispatch = useDispatch();
    const translate = useSelector((state) => getTranslate(state.locale));
    const [from, setFrom] = useState(moment());
    const [to, setTo] = useState(moment().add(1,'day'));
    const [all, setAll] = useState(false);

    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';

    function onChange(dates, dateStrings) {
        setFrom(dates[0])
        setTo(dates[1])
    }

    function onCheckChange() {
        setAll(!all)
    }

    return (
        <div>
            <Row type='flex' align='middle'>
                <Col sm={6} md={6} lg={3}>
                    <div>Hibás rekordok törlése: </div>
                </Col>
                <Col sm={5} md={5} lg={3}>
                    <Checkbox checked={all} onChange={onCheckChange}>Teljes adatbázisra</Checkbox>
                </Col>
                <Col sm={18} md={18} lg={7}>
                    <RangePicker
                        format={dateFormat}
                        value={[from, to]}
                        onChange={onChange}
                        disabled={all}
                    />
                </Col>
                <Col sm={4} md={4} lg={2}>
                    <Button onClick={() => dispatch(sendCleanUpRequest(Object.assign({
                        from: from, to: to, checkAll: all, createFile: false })))}>
                        {translate('buttons.send')}
                    </Button>
                </Col>
            </Row>
            <Row type='flex' align='middle'>
                <Col sm={6} md={6} lg={3}>
                    <div>Hibás rekordok fájlba írása: </div>
                </Col>
                <Col sm={4} md={4} lg={2}>
                    <Button onClick={() => dispatch(sendCleanUpRequest(Object.assign({
                        from: from, to: to, checkAll: all, createFile: true })))}>
                        {translate('buttons.createContainer')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

DatabaseCleanUp.propTypes = {
    history: PropTypes.object.isRequired,
};
