/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getWaitingToSignByOthersByContainer } from '../actions/container';
import { Collapse, List, Spin } from 'antd';
import WorkflowComment from './WorkflowComment';
import PropTypes from 'prop-types';

const MESSAGE_PREVIEW_LENGTH = 100;

export default function WorkflowComments (props) {
    const dispatch = useDispatch();
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const translate = useSelector((state) => getTranslate(state.locale));

    const getMessagePreview = (message) => {
        if (message.length <= MESSAGE_PREVIEW_LENGTH || isPanelOpen) {
            return message;
        }
        return message.slice(0, 100).concat('...');
    };

    const onPanelClick = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    useEffect(() => {
        const translateWorkflowStatusToRole = (workflowStatus) => {
            switch (workflowStatus) {
                case 'APPROVED':
                case 'WAITING_TO_APPROVE':
                    return translate('sendForSignature.approverRole');
                case 'SIGNED':
                case 'WAITING_TO_SIGN':
                    return translate('sendForSignature.signerRole');
                default:
                    return '';
            }
        };

        const handleComments = () => {
            let commentArray = [];
            return dispatch(getWaitingToSignByOthersByContainer(props.workflowGuid)).then((response) => {
                commentArray.push({
                    emailAddress: response.data.createdBy,
                    message: response.data.comment ? response.data.comment : '',
                    role: translate('attributes.createdBy').toLowerCase()
                });
                // Previous comments
                response.data.waitingToSignByOthers
                    .filter((p) => p.message !== undefined)
                    .forEach((p) => {
                        commentArray.push({
                            emailAddress: p.user,
                            message: p.message,
                            role: translateWorkflowStatusToRole(p.status).toLowerCase()
                        });
                    });
            }).finally(() => {
                setComments(commentArray);
                setLoading(false);
            });
        };

        handleComments();
    }, [props.workflowGuid]);

    if (loading) {
        return (
            <Collapse>
                <Collapse.Panel header={<Spin />} showArrow={false} style={{ textAlign: 'center' }} />
            </Collapse>
        );
    } else if (comments.length === 0) {
        return '';
    } else if (comments.length === 1) {
        return (
            <Collapse expandIconPosition='right' onChange={(e) => onPanelClick(e)}>
                <Collapse.Panel
                    header={
                        <WorkflowComment
                            emailAddress={comments[0].emailAddress}
                            message={getMessagePreview(comments[0].message)}
                            role={comments[0].role}
                        />
                    }
                />
            </Collapse>
        );
    } else {
        return (
            <Collapse expandIconPosition='right' onChange={() => onPanelClick()}>
                <Collapse.Panel
                    header={
                        <WorkflowComment
                            emailAddress={comments[0].emailAddress}
                            message={getMessagePreview(comments[0].message)}
                            role={comments[0].role}
                        />
                    }
                >
                    <List
                        itemLayout='horizontal'
                        dataSource={comments.slice(1, comments.length)}
                        renderItem={(comment) => (
                            <li style={{ marginTop: 8 }}>
                                <WorkflowComment
                                    emailAddress={comment.emailAddress}
                                    message={comment.message}
                                    role={comment.role}
                                />
                            </li>
                        )}
                        style={{ padding: 16 }}
                    />
                </Collapse.Panel>
            </Collapse>
        );
    }
};

WorkflowComments.propTypes = {
    workflowGuid: PropTypes.string.isRequired
};
