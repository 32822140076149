import PropTypes from 'prop-types';
import React from 'react';
import authService from '../services/authService';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (authService.isRegistered() ? <Component {...props} /> : <Redirect to={'/login'} />)}
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.func
};

export default PrivateRoute;
