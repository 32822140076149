import { ajax } from '../helper';
import * as types from './actionTypes';

export function getDocumentTitlesSuccess(documentTitles) {
    return {
        type: types.GET_DOCUMENT_TITLES_SUCCESS,
        payload: documentTitles
    };
}

export const fetchDocumentTitles = () => async (dispatch) => {
    const response = await ajax().get('/eCegeljarasDocumentTitles');
    dispatch(getDocumentTitlesSuccess(response.data.DocumentTitles));
};
