import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const SkeletonTable = ({ rowCount }) => {
    const list = [];
    for (let i = 0; i < rowCount; i++) {
        list.push(<div key={i}>
                    <Skeleton height={40} width={`100%`} />
                  </div>
        );
    }

    return (
        <SkeletonTheme>
            <section>
                <div style={{ padding: '8px' }}>
                    {list}
                </div>
            </section>
        </SkeletonTheme>
    );
};

SkeletonTable.propTypes = {
    rowCount: PropTypes.number.isRequired,
};

SkeletonTable.defaultProps = {
    rowCount: 10,
};

export default SkeletonTable;
