import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.deputy, action) {
    switch (action.type) {
        case types.GET_DEPUTY_SUCCESS:
        case types.GET_DEPUTY_FAILURE:
            return { ...state, data: action.payload };
        case types.DEPUTY_RESET:
            return {};
        default:
            return state;
    }
}
