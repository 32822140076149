/* eslint-disable react-hooks/exhaustive-deps */
import CustomAlert from './CustomAlert';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import WrappedTspAuthForm from './TspAuthForm';
import { ajax, getSignModeLocalStorage } from '../helper';
import Sign from './Sign';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';

export default function CheckTspAuthAndSign(props) {
    const [error, setError] = useState(null);
    const [showTspAuthForm, setShowTspAuthForm] = useState(true);
    const [tspAuthNeededChecked, setTspAuthNeededChecked] = useState(false);

    const translate = useSelector((state) => getTranslate(state.locale));
    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);

    useEffect(() => {
        if (window.config.REACT_APP_TIMESTAMP_LOGIN_MODE !== 'microsec') {
            ajax()
                .get('/tsp')
                .then((response) => {
                    const shouldTspShowAuthForm = !(response.data.tsaUser !== undefined);
                    setTspAuthNeededChecked(true);
                    setShowTspAuthForm(shouldTspShowAuthForm);
                })
                .catch((error) => {
                    onError(error);
                });
        } else {
            setTspAuthNeededChecked(true);
            setShowTspAuthForm(false);
        }
    }, []);

    const onError = (error) => {
        if (error.response) {
            setError(error.response.data.error);
        } else {
            setError({ code: 'HTTP_NO_RESPONSE' });
        }
    };

    const {
        attributeRole,
        cegjegyzekSzam,
        certificate,
        commentMessage,
        commentType,
        onFinished,
        signingType,
        signModalData
    } = props;

    return (
        <div style={{ margin: '1em' }}>
            {error && <CustomAlert error={error} />}
            {!tspAuthNeededChecked && (
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            )}
            {tspAuthNeededChecked && showTspAuthForm && (
                <WrappedTspAuthForm
                    translate={translate}
                    onFinished={() => {
                        setShowTspAuthForm(false);
                    }}
                />
            )}
            {tspAuthNeededChecked && !showTspAuthForm && (
                <Sign
                    signingType={signingType}
                    activeLanguage={currentLanguage}
                    cegjegyzekSzam={cegjegyzekSzam}
                    onFinished={onFinished}
                    signModalData={signModalData}
                    signMode={getSignModeLocalStorage()}
                    translate={translate}
                    commentType={commentType}
                    commentMessage={commentMessage}
                    attributeRole={attributeRole}
                    certificateContent={certificate}
                />
            )}
        </div>
    );
}

CheckTspAuthAndSign.propTypes = {
    attributeRole: PropTypes.object,
    cegjegyzekSzam: PropTypes.string,
    certificate: PropTypes.any,
    containerIds: PropTypes.array,
    commentMessage: PropTypes.string,
    commentType: PropTypes.string,
    onFinished: PropTypes.func.isRequired,
    pdfImagePaddingX: PropTypes.number,
    pdfImagePaddingY: PropTypes.number,
    pdfImagePage: PropTypes.number,
    pdfImageResize: PropTypes.number,
    pdfReason: PropTypes.string,
    schemaId: PropTypes.number,
    signModalData: PropTypes.array,
    signingType: PropTypes.string.isRequired
};
