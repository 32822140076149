import CustomAlert from './CustomAlert';
import PropTypes from 'prop-types';
import React from 'react';
import WebPage from './WebPage';
import authService from '../services/authService';
import { Redirect } from 'react-router-dom';
import { Spin, Button } from 'antd';
import { getActiveLanguage, getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { ajax } from '../helper';
import { UPDATE_MENU_MARKUP } from '../actions/actionTypes';

class RegisteredAD extends React.Component {
    constructor(props) {
        super(props);
        let redirect = false;
        let logoutRequired = false;
        let tryLoginAD = false;
        if (authService.isRegistered()) {
            const { match } = props;
            if (authService.getUserEmail() === match.params.email) {
                redirect = true;
            } else {
                logoutRequired = true;
            }
        } else {
            tryLoginAD = true;
        }
        this.state = {
            redirect: redirect,
            logoutRequired: logoutRequired,
            tryLoginAD: tryLoginAD,
            error: null
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { tryLoginAD } = this.state;
        if (tryLoginAD) {
            setTimeout(() => {
                this.loginWithAD();
            }, 3000);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loginWithAD() {
        const instance = ajax(true);
        instance.defaults.timeout = 300000;
        instance
            .post('/loginAD', {
                language: this.props.currentLanguage
            })
            .then((response) => {
                const { data } = response;
                localStorage.setItem('JWT', data.JWT);
                authService.registeredLogin(data.email);
                this.props.setUserProfile(data.userProfile);
                authService.setRegistrationType('ad');
                this._isMounted && this.setState({ redirect: true, tryLoginAD: false });
            })
            .catch((error) => {
                this._isMounted && this.setState({ tryLoginAD: false, error: error.response.data.error });
            });
    }

    logoutAndLoginWithAD() {
        ajax()
            .post('/user/logout')
            .then(() => {
                authService.logout();
                const { updateMenuMarkup } = this.props;
                updateMenuMarkup();
                this._isMounted && this.setState({ logoutRequired: false, tryLoginAD: true });
                setTimeout(() => {
                    this.loginWithAD();
                }, 3000);
            })
            .catch((error) => {
                this._isMounted && this.setState({ error: error.response.data.error });
            });
    }

    render() {
        const { match, history, translate } = this.props;
        const { redirect, logoutRequired, tryLoginAD, error } = this.state;
        if (redirect) {
            return <Redirect to={'/' + match.params.id} />;
        }
        return (
            <WebPage location={history.location}>
                <div style={{ margin: 'auto', maxWidth: 400, textAlign: 'center' }}>
                    {tryLoginAD && (
                        <React.Fragment>
                            <h3>{translate('texts.loginWithADInProgress')}</h3>
                            <Spin size={'large'} />
                        </React.Fragment>
                    )}
                    {logoutRequired && (
                        <React.Fragment>
                            <p>{translate('texts.logoutAndTryLoginWithAD')}</p>
                            <Button
                                type={'primary'}
                                onClick={() => {
                                    this.logoutAndLoginWithAD();
                                }}>
                                {translate('buttons.logoutAndTryLoginWithAD')}
                            </Button>
                        </React.Fragment>
                    )}
                    {error && <CustomAlert error={error} />}
                </div>
            </WebPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateMenuMarkup: () => {
            dispatch({ type: UPDATE_MENU_MARKUP });
        },
        setUserProfile: (data) => {
            authService.setUserProfile(data, dispatch);
        }
    };
}

RegisteredAD.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setUserProfile: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    updateMenuMarkup: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredAD);
