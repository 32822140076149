import authService from '../services/authService';
import Box from './Box';
import CompleteLoginForm from './CompleteLoginForm';
import CustomAlert from './CustomAlert';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import WebPage from './WebPage';
import { connect } from 'react-redux';
import { login } from '../actions/user';
import { Redirect } from 'react-router-dom';
import { Row, Col, Form } from 'antd';
import { sleep } from '../helper';

class Registered extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
            redirect: false,
            loading: false
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (authService.isRegistered()) {
            if (authService.getUserEmail() === this.props.match.params.email) {
                this._isMounted && this.setState({ redirect: true });
            } else {
                authService.logout();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    login = (email, password) => {
        return this.props
            .loginAction(email, password)
            .then(() => {
                this._isMounted && this.setState({ redirect: true, loading: false });
            })
            .catch((error) => {
                return Promise.reject(error.response.data);
            });
    };

    handleSubmit = (e, loginData) => {
        const { form } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll(async (clientErr) => {
            if (!clientErr) {
                this._isMounted && this.setState({ loading: true });
                try {
                    await this.login(loginData.userName, loginData.password);
                } catch (err) {
                    if (err.code === 'USER_INVALID_USERNAME_OR_PASSWORD') {
                        await sleep(window.config.REACT_APP_LOCK_TIME_IN_SEC * 1000);
                    }
                    this._isMounted && this.setState({ loading: false, serverError: err });
                }
            }
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/' + this.props.match.params.id} />;
        }

        const { history } = this.props;
        const { serverError, loading } = this.state;
        return (
            <WebPage location={history.location}>
                <Row>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 8, offset: 8 }}>
                        <Box>
                            {serverError && <CustomAlert error={serverError} />}
                            <CompleteLoginForm
                                embedded={false}
                                method={(e, loginData) => this.handleSubmit(e, loginData)}
                                showForgottenPassword={true}
                                showSocialOptions={true}
                                registeredFormLoading={loading}
                            />
                        </Box>
                    </Col>
                </Row>
            </WebPage>
        );
    }
}

Registered.propTypes = {
    form: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loginAction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    validateFieldsAndScroll: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: { email: ownProps.match.params.email }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loginAction: (email, password) => {
            ReactGA.event({
                category: 'User',
                action: 'Login'
            });
            return dispatch(
                login({
                    email: email,
                    password: password,
                    language: localStorage.getItem('language') ? localStorage.getItem('language') : 'hu',
                    type: 'password'
                })
            );
        }
    };
}

const WrappedRegistered = Form.create()(Registered);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistered);
