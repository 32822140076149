import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Checkbox, Dropdown, Icon, Menu } from 'antd';

const TitleOptionsDropdown = (props) => {
    const [state, setState] = useState({ visible: false });

    const handleVisibleChange = (flag) => {
        setState({ visible: flag });
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <Checkbox
                    disabled={props.isTitleModificationDisabled}
                    checked={props.isTitleModificationDisabled ? true : props.hasTitle}
                    onChange={props.changeTitle}
                >
                    {props.translate('signature.image.sign.title')}
                </Checkbox>
            </Menu.Item>
            <Menu.Item>
                <Checkbox checked={props.hasDate} onChange={props.changeDate}>
                    {props.translate('signature.image.title.time')}
                </Checkbox>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} onVisibleChange={handleVisibleChange} visible={state.visible}>
            <Button type={'default'} onClick={(e) => e.preventDefault()}>
                {props.translate('signature.image.title.options')}
                <Icon type="down" />
            </Button>
        </Dropdown>
    );
};

TitleOptionsDropdown.propTypes = {
    changeDate: PropTypes.func.isRequired,
    changeTitle: PropTypes.func.isRequired,
    hasDate: PropTypes.bool.isRequired,
    hasTitle: PropTypes.bool.isRequired,
    isTitleModificationDisabled: PropTypes.bool.isRequired,
    translate: PropTypes.func.isRequired,
};

export default TitleOptionsDropdown;
