import AdminSigningProcesses from './AdminSigningProcesses';
import Box from './Box';
import DeputyList from './admin/DeputyList';
import PropTypes from 'prop-types';
import React from 'react';
import UserFileList from './admin/UserFileList';
import UserList from './admin/UserList';
import WebPage from './WebPage';
import authService from '../services/authService';
import { Col, Menu, Row } from 'antd';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getHistory } from '../helper';
import PdfGeneration from './admin/PdfGeneration';
import DatabaseCleanUp from './admin/CleanUp';

class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentKey: '10',
            selectedUser: ''
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (!authService.isAdmin()) {
            getHistory().push('/');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showFileListOfUser = (email) => {
        this._isMounted && this.setState({ contentKey: '5', selectedUser: email });
    };

    stepBackToUserList = () => {
        this._isMounted && this.setState({ contentKey: '1', selectedUser: '' });
    };

    getContent(key) {
        const { history } = this.props;
        switch (key) {
            case '1':
                return <UserList showFileListOfUser={this.showFileListOfUser} history={history} />;
            case '2':
                return <AdminSigningProcesses history={history} />;
            case '3':
                return <DeputyList history={history} />;
            case '4':
                return <PdfGeneration history={history} />;
            case '5':
                return <DatabaseCleanUp history={history} />;
            case '6':
                return (
                    <UserFileList
                        stepBackToUserList={this.stepBackToUserList}
                        email={this.state.selectedUser}
                        history={history}
                    />
                );
            default:
                return <div></div>;
        }
    }

    render() {
        const { history, translate } = this.props;
        return (
            <WebPage location={history.location}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={4}>
                        <Menu
                            defaultSelectedKeys={['10']}
                            mode={'inline'}
                            theme={'light'}
                            onSelect={(item) => {
                                this._isMounted && this.setState({ contentKey: item.key });
                            }}
                        >
                            <Menu.Item key={'1'}>{translate('adminPage.menu.user')}</Menu.Item>
                            <Menu.Item key={'2'}>{translate('adminPage.menu.signatureProcesses')}</Menu.Item>
                            <Menu.Item key={'3'}>{translate('adminPage.menu.deputyList')}</Menu.Item>
                            {window.config.REACT_APP_PDF_GENERATION_ENABLED === true &&
                            <Menu.Item key={'4'}>{translate('adminPage.menu.pdfGenerationTest')}</Menu.Item>}
                            {window.config.REACT_APP_DATABASE_CLEANUP_ENABLED === true &&
                            <Menu.Item key={'5'}>{translate('adminPage.menu.dataCleanUp')}</Menu.Item>}
                        </Menu>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={20}>
                        <Box>{this.getContent(this.state.contentKey)}</Box>
                    </Col>
                </Row>
            </WebPage>
        );
    }
}

AdminPage.propTypes = {
    history: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return { translate: getTranslate(state.locale) };
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
