import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Col, Collapse, Row } from 'antd';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux/lib/index';

class CustomAlert extends React.Component {
    render() {
        const { error, translate } = this.props;
        let message = null;
        let description = null;
        if (error.code) {
            message = (
                <Row>
                    <Col span={24}>{translate(`errorCodes.${error.code}`, { lockTimeInSec: window.config.REACT_APP_LOCK_TIME_IN_SEC })}</Col>
                    {error.extraCom && <Col span={24}>{error.extraCom}</Col>}
                </Row>
            );
            if (error.detail) {
                if (!error.code.includes('USER')) {
                    description = (
                        <Collapse accordion style={{ textAlign: 'left', backgroundColor: '#ffeee9' }}>
                            <Collapse.Panel header={translate('details')} style={{ backgroundColor: '#ffdfde' }}>
                                <p>{error.detail}</p>
                            </Collapse.Panel>
                        </Collapse>
                    );
                }
            }
        } else {
            message = translate('errorCodes.THIRD_PARTY_APP_ERROR');
            description = error;
        }
        return <Alert type="error" showIcon message={message} description={description} style={{ marginBottom: 8 }} />;
    }
}

CustomAlert.propTypes = {
    translate: PropTypes.func.isRequired,
    error: PropTypes.shape({
        code: PropTypes.string.isRequired,
        detail: PropTypes.string,
        extraCom: PropTypes.string,
    }),
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

export default connect(mapStateToProps)(CustomAlert);
