import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, List } from 'antd';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Attributes from './Attributes';
import moment from 'moment';
import { extractFieldFromDN } from '../../../utilities/dnUtilities';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss:SSZ';

export default function AttributeCertificates(props) {
    const translate = useSelector((state) => getTranslate(state.locale));
    const { enableDetailedView, eSzignoAttributeCertificates } = props;
    return (
        <List
            size='small'
            dataSource={eSzignoAttributeCertificates}
            renderItem={(item) =>
                enableDetailedView ?
                    <List.Item>
                        <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                            <Descriptions.Item
                                label={translate('attributeCertificate.issuerDN')}>{item.issuerDN}</Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.notBefore')}>{moment(item.notBefore, dateFormat).format('YYYY.MM.DD. HH:mm')}</Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.notAfter')}>{moment(item.notAfter, dateFormat).format('YYYY.MM.DD. HH:mm')}</Descriptions.Item>
                            <Descriptions.Item label={translate('attributeCertificate.attributes')}>
                                <Attributes data={item.attributes} enableDetailedView={enableDetailedView} />
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.serialNumber')}>{item.serialNumber}</Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.digestAlgorithm')}>{item.digestAlgorithm}</Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.signatureAlgorithm')}>{item.signatureAlgorithm}</Descriptions.Item>
                        </Descriptions>
                    </List.Item>
                    :
                    <List.Item>
                        <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                            <Descriptions.Item
                                label={translate('attributeCertificate.issuerDN')}>{extractFieldFromDN('CN', item.issuerDN)}</Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.notBefore')}>{moment(item.notBefore, dateFormat).format('YYYY.MM.DD. HH:mm')}</Descriptions.Item>
                            <Descriptions.Item
                                label={translate('attributeCertificate.notAfter')}>{moment(item.notAfter, dateFormat).format('YYYY.MM.DD. HH:mm')}</Descriptions.Item>
                            <Descriptions.Item label={translate('attributeCertificate.attributes')}>
                                <Attributes data={item.attributes} enableDetailedView={enableDetailedView} />
                            </Descriptions.Item>
                        </Descriptions>
                    </List.Item>
            }
        />
    );
}

AttributeCertificates.propTypes = {
    eSzignoAttributeCertificates: PropTypes.array,
    enableDetailedView: PropTypes.bool
};
