import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { extractFieldFromDN } from '../../../utilities/dnUtilities';

export default function Attributes(props) {

    const renderDIVContent = (item) => {
        let cn = extractFieldFromDN('CN', item);
        if (cn) {
            return cn;
        }
        let o = extractFieldFromDN('O', item);
        if (o) {
            return o;
        }
        return '';
    };

    const roleNames = [];
    props.data.forEach((attribute) => {
        roleNames.push(attribute.roleName);
    });
    return (
        <List
            size='small'
            dataSource={roleNames}
            renderItem={item => <List.Item>
                {props.enableDetailedView ?
                    <List size='small'
                          dataSource={item.split(',')}
                          renderItem={element => <List.Item>
                              <div>{element}</div>
                          </List.Item>}>
                    </List>
                    :
                    <div>
                        <a target='_blank' rel='noopener noreferrer'
                           href={extractFieldFromDN('description', item)}>{extractFieldFromDN('title', item)}</a>
                        <div>{renderDIVContent(item)}</div>
                    </div>
                }
            </List.Item>}
        />
    );
}

Attributes.propTypes = {
    data: PropTypes.array,
    enableDetailedView: PropTypes.bool
};
