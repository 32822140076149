import * as types from './actionTypes';
import { ajax } from '../helper';

export const addTimeStampPDFAction = (containerId) => {
    return function () {
        return ajax()
            .post(`/containers/${containerId}/addTimestampToPDF`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const addTspCredentials = ({ tsaUser, tsaPassword }) => {
    return function () {
        return ajax()
            .post('/tsp', { tsaUser: tsaUser, tsaPassword: tsaPassword })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export function checkTsp() {
    return function (dispatch) {
        return ajax()
            .get('/tsp')
            .then((response) => {
                if (response.data.tsaUser !== undefined) {
                    dispatch(checkTspSuccess());
                } else {
                    dispatch(checkTspFailure());
                }
            })
            .catch((error) => {
                dispatch(checkTspFailure());
                throw error;
            });
    };
}

export function checkTspSuccess() {
    return {
        type: types.CHECK_TSP_SUCCESS,
    };
}

export function checkTspFailure() {
    return {
        type: types.CHECK_TSP_FAILURE,
    };
}

export function tspClearSuccess() {
    return {
        type: types.TSP_CLEARED,
    };
}
