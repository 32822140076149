import Box from '../Box';
import FormItem from '../form/FormItem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TooltipButton from '../buttons/TooltipButton';
import WebPage from '../WebPage';
import { Col, Form, Icon, Input, Row } from 'antd';
import { createNotification, createNotificationShort, validatePassword } from '../../helper';
import { getTranslate } from 'react-localize-redux';
import { renewPassword } from '../../actions/user';
import { setIsPassword } from '../../actions/confirm';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../services/authService';

function ChangePassword(props) {
    const { form, history, match } = props;

    const [confirmDirty, setConfirmDirty] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const dispatch = useDispatch();

    const translate = useSelector((state) => getTranslate(state.locale));

    useEffect(() => {
        authService.logout();
    });

    function submitForm(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                dispatch(renewPassword(match.params.id, values.newPassword))
                    .then(() => {
                        createNotificationShort(translate, {
                            message: translate('changePassword.operation.success.message'),
                            type: 'success'
                        });
                        dispatch(setIsPassword());
                        history.push('/confirm');
                    })
                    .catch((error) => {
                        if (error.response.data.code === 'USER_PASSWORD_RENEWAL_TOKEN_EXPIRED') {
                            createNotification(translate, error.response.data.code, {
                                description: translate('errorCodes.USER_PASSWORD_RENEWAL_TOKEN_EXPIRED.description'),
                                duration: 10,
                                key: 'password-renewal-token-expired',
                                message: translate('errorCodes.USER_PASSWORD_RENEWAL_TOKEN_EXPIRED.message'),
                                placement: 'topRight',
                                type: 'error'
                            });
                        } else {
                            createNotification(translate, error.response.data.code, {
                                message: translate('changePassword.operation.error.message'),
                                type: 'error'
                            });
                        }
                    });
            }
        });
    }

    function handleConfirmBlur(e) {
        setConfirmDirty(confirmDirty || !!e.target.value);
    }

    function compareToFirstPassword(rule, value, callback) {
        if (value && value !== form.getFieldValue('newPassword')) {
            callback(translate('form.password.different'));
            setIsButtonDisabled(true);
        } else {
            callback();
            setIsButtonDisabled(false);
        }
    }

    return (
        <WebPage location={history.location}>
            <Col
                xs={{ offset: 1, span: 22 }}
                sm={{ offset: 2, span: 20 }}
                md={{ offset: 4, span: 16 }}
                lg={{ offset: 6, span: 12 }}
                xl={{ offset: 7, span: 10 }}
                xxl={{ offset: 8, span: 8 }}>
                <Row
                    align={'middle'}
                    justify={'center'}
                    type={'flex'}>
                    <h1>{translate('changePassword.title')}</h1>
                </Row>
                <Row>
                    <Box
                        borderRadius={8}
                        padding={32}>
                        <p>{translate('changePassword.description')}</p>
                        <Form onSubmit={submitForm}>
                            <FormItem>
                                {form.getFieldDecorator('newPassword', {
                                    rules: [
                                        { message: translate('form.required'), required: true },
                                        {
                                            validator: (rule, value, callback) =>
                                                validatePassword(
                                                    translate,
                                                    value,
                                                    callback,
                                                    () => setIsButtonDisabled(true),
                                                    form,
                                                    confirmDirty
                                                )
                                        }
                                    ]
                                })(
                                    <Input
                                        prefix={<Icon type={'lock'} />}
                                        placeholder={translate('changePassword.placeholder.newPassword')}
                                        type={'password'}
                                    />
                                )}
                            </FormItem>
                            <FormItem>
                                {form.getFieldDecorator('confirmPassword', {
                                    rules: [
                                        { message: translate('form.required'), required: true },
                                        { validator: compareToFirstPassword }
                                    ]
                                })(
                                    <Input
                                        onBlur={handleConfirmBlur}
                                        placeholder={translate('changePassword.placeholder.confirmNewPassword')}
                                        prefix={<Icon type={'lock'} />}
                                        type={'password'}
                                    />
                                )}
                            </FormItem>
                            <FormItem>
                                <TooltipButton
                                    block={true}
                                    disabled={isButtonDisabled}
                                    disabledTooltip={translate('changePassword.button.disabled')}
                                    htmlType={'submit'}
                                    icon={'save'}
                                    id={'change-password-button'}
                                    title={translate('changePassword.button.title')}
                                    tooltipPlacement={'bottom'}
                                    type={'primary'}
                                />
                            </FormItem>
                        </Form>
                    </Box>
                </Row>
            </Col>
        </WebPage>
    );
}

ChangePassword.propTypes = {
    form: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

const WrappedChangePassword = Form.create({ name: 'change-password' })(ChangePassword);
export default WrappedChangePassword;
