import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon, Table } from 'antd';
import { iconTypeFromType } from '../../helper';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { downloadAttachment } from '../../actions/document';

class PdfEmbeddedFiles extends React.Component {
    handleDownload = (fileName, description) => {
        let descriptionToUse = description;
        if (description === undefined || description === '' || description === 'undefined') {
            descriptionToUse = 'without';
        }
        this.props.downloadAttachmentAction(this.props.container.id, fileName, descriptionToUse);
    };

    render() {
        const columns = [
            {
                title: this.props.translate('attachment.fileName'),
                dataIndex: 'filename',
                key: 'filename',
                render: (text) => (
                    <div>
                        <Icon
                            style={{ marginRight: '5px', fontSize: '20px' }}
                            type={iconTypeFromType(text.split('.').pop())}
                        />
                        {text}
                    </div>
                ),
            },
            {
                title: this.props.translate('attachment.description'),
                dataIndex: 'description',
                key: 'description',
            },
            {
                dataIndex: 'download',
                key: 'download',
                render: (text, record) => (
                    <Button
                        className="remove-focus-from-button"
                        style={{ marginRight: '10px' }}
                        onClick={() => this.handleDownload(record.filename, encodeURIComponent(record.description))}
                        size="small"
                    >
                        <Icon type="download" />
                    </Button>
                ),
            },
        ];
        return (
            <Table
                columns={columns}
                dataSource={this.props.container.pdfEmbeddedFiles.map((embeddedFile) => ({
                    filename: embeddedFile.fileName,
                    description: embeddedFile.description,
                }))}
            />
        );
    }
}

PdfEmbeddedFiles.propTypes = {
    container: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        fileBaseName: PropTypes.string.isRequired,
        size: PropTypes.string.isRequired,
        creationDate: PropTypes.string.isRequired,
        esNamespace: PropTypes.string,
        ackVersion: PropTypes.string,
        pdfEmbeddedFiles: PropTypes.array,
    }),
    downloadAttachmentAction: PropTypes.func,
    translate: PropTypes.func,
};

const mapStateToProps = (state) => ({
    translate: getTranslate(state.locale),
});

function mapDispatchToProps(dispatch) {
    return {
        downloadAttachmentAction: (contId, docId, signatureId, fileName) => {
            dispatch(downloadAttachment(contId, docId, signatureId, fileName));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfEmbeddedFiles);
