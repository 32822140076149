import PropTypes from 'prop-types';
import React from 'react';
import TooltipButton from '../buttons/TooltipButton';
import { Divider } from 'antd';
import { createNotification, createNotificationShort } from '../../helper';
import { getTranslate } from 'react-localize-redux';
import { removeUserFromWorkflow, resendWorkflow } from '../../actions/workflow';
import { useDispatch, useSelector } from 'react-redux';

function WorkflowParticipantOperations(props) {
    const { isStarterOfSelectedWorkflow, selectedParticipantOrder, selectedWorkflowGuid } = props;

    const dispatch = useDispatch();

    const translate = useSelector((state) => getTranslate(state.locale));

    function deleteParticipant() {
        dispatch(removeUserFromWorkflow(selectedWorkflowGuid, selectedParticipantOrder))
            .then(() => {
                createNotificationShort(translate, {
                    message: translate('workflow.details.operations.delete.success.message'),
                    type: 'success'
                });
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate('workflow.details.operations.delete.error.message'),
                    type: 'error'
                });
            });
    }

    function resend() {
        dispatch(resendWorkflow(selectedWorkflowGuid, selectedParticipantOrder))
            .then(() => {
                createNotificationShort(translate, {
                    message: translate('workflow.details.operations.resend.success.message'),
                    type: 'success'
                });
            })
            .catch((error) => {
                createNotification(translate, error.response.data.code, {
                    message: translate('workflow.details.operations.resend.error.message'),
                    type: 'error'
                });
            });
    }

    if (!selectedWorkflowGuid) {
        return <div style={{ marginBottom: '48px' }} />;
    }

    return (
        <div style={{ marginBottom: '8px' }}>
            <TooltipButton
                disabled={!isStarterOfSelectedWorkflow || !selectedParticipantOrder}
                disabledTooltip={translate('workflow.details.operations.resend.disabled')}
                icon={'mail'}
                id={'signature-process-resend'}
                onClick={resend}
                title={translate('workflow.details.operations.resend.button')}
                tooltip={translate('workflow.details.operations.resend.tooltip')}
                type={'primary'}
            />
            <Divider orientation={'center'} type={'vertical'} />
            <TooltipButton
                disabled={!isStarterOfSelectedWorkflow || !selectedParticipantOrder}
                disabledTooltip={translate('workflow.details.operations.delete.disabled')}
                icon={'delete'}
                id={'signature-process-delete'}
                onClick={deleteParticipant}
                title={translate('workflow.details.operations.delete.button')}
                tooltip={translate('workflow.details.operations.delete.tooltip')}
                type={'danger'}
            />
        </div>
    );
}

WorkflowParticipantOperations.propTypes = {
    isStarterOfSelectedWorkflow: PropTypes.bool.isRequired,
    selectedParticipantOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    selectedWorkflowGuid: PropTypes.string
};

export default WorkflowParticipantOperations;
