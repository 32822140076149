import * as userActions from '../actions/user';
import CheckStatusMultiple from './CheckStatusMultiple';
import CustomAlert from './CustomAlert';
import ESignCheckDocumentStates from './ESignCheckDocumentStates';
import ESignNoClient from './ESignNoClient';
import PropTypes from 'prop-types';
import React from 'react';
import eszignoImg from '../images/eszigno-full.svg';
import microSignerImg from '../images/microsigner.svg';
import pbmImg from '../images/passbyme_v2.svg';
import { connect } from 'react-redux';
import { browserName, osName, osVersion } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { Avatar, Button, Modal, Icon } from 'antd';
import { removeModal, toggleModal } from '../actions/modal';
import { ajax, createNotification, createNotificationShort, getHistory } from '../helper';
import { ERROR_CODE_USER_ACCESS_TOKEN_EMPTY } from '../constants/errorCode';
import { MOBILE_AUTHENTICATION, SIGN } from '../constants/modalNames';
import { isBlank } from '../utilities/stringUtilities';
import { startMobileLogin } from "../actions/user";

export function deleteMultiple(containerIds) {
    ajax()
        .delete(`/deleteMultiple?ids=${containerIds.join(';')}`)
        .then(() => {
            // NOP
        })
        .catch(() => {
            // NOP
        });
}

function esignStateCodeToMessage(esignStateCode, signMode, translate) {
    if (esignStateCode === 'WAITING_FOR_CONFIG_GOT') {
        if (signMode === 'signUsingQRCode') {
            return (
                <React.Fragment>
                    {translate('esignStates.waitForMobileAppStart.beforeFirstLogo')}
                    <Avatar size={20} src={eszignoImg} style={{ marginLeft: 6, marginRight: 4 }} />
                    {translate('esignStates.waitForMobileAppStart.beforeSecondLogo')}
                    <Avatar size={20} src={pbmImg} style={{ marginLeft: 6, marginRight: 4 }} shape="square" />
                    {translate('esignStates.waitForMobileAppStart.afterSecondLogo')}
                </React.Fragment>
            );
        }
        if (signMode === 'signUsingPush') {
            return (
                <React.Fragment>
                    {translate('esignStates.waitForPush.beforeFirstLogo')}
                    <Avatar size={20} src={eszignoImg} style={{ marginLeft: 6, marginRight: 4 }} />
                    {translate('esignStates.waitForPush.beforeSecondLogo')}
                    <Avatar size={20} src={pbmImg} style={{ marginLeft: 6, marginRight: 4 }} shape="square" />
                    {translate('esignStates.waitForPush.afterSecondLogo')}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {translate('esignStates.waitForAppStart.beforeLogo')}
                <Avatar size={20} src={microSignerImg} style={{ marginLeft: 6, marginRight: 4 }} shape="square" />
                {translate('esignStates.waitForAppStart.afterLogo')}
            </React.Fragment>
        );
    }
    if (esignStateCode === 'WAITING_FOR_CERTIFICATE') {
        return translate('esignStates.waitForCert');
    }
    if (esignStateCode === 'WAITING_FOR_UNSIGNED_HASHES') {
        return translate('esignStates.waitForUH');
    }
    if (esignStateCode === 'WAITING_FOR_SIGNED_HASHES') {
        return translate('esignStates.waitForSH');
    }
    if (esignStateCode === 'FINISHED') {
        return translate('esignStates.finished');
    }
    return '\xa0';
}

class Sign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            esignError: null,
            ackContainerIds: null,
            esignSessionId: null,
            esignDocumentIds: null,
            esignStateCode: '',
            showESignNoClient: false,
            checkStatusMultipleStart: false,
            esignCheckDocumentStatesStart: false,
            pdfReason: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.startSigning();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.error !== this.state.error && this.state.error) {
            this.props.onFinished({ succeeded: false });

            if (this.state.ackContainerIds) {
                deleteMultiple(this.state.ackContainerIds);
            }

            if (this.state.esignSessionId) {
                ajax()
                    .get(`/esign/${this.state.esignSessionId}/error`)
                    .then((response) => {
                        const { data } = response;
                        let code;
                        if (data.error) {
                            code = data.error;
                        } else if (data.error === '') {
                            code = 'USER_INTERRUPTED_SIGN';
                        } else {
                            code = 'UNKNOWN_SIGN_ERROR';
                        }
                        this._isMounted && this.setState({ esignError: { code, detail: null } });
                    })
                    .catch(() => {
                        // NOP
                    });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        const { error, esignSessionId, esignStateCode } = this.state;

        if (esignSessionId) {
            window.esignstate.onStateAJAXFailure = () => {
                /*NOP*/
            };
            window.esignstate.onStateESignFailure = () => {
                /*NOP*/
            };
            if (error) {
                ajax()
                    .delete(`/esign/${esignSessionId}`)
                    .then(() => {
                        // NOP
                    })
                    .catch(() => {
                        // NOP
                    });
            } else {
                if (esignStateCode !== 'FINISHED') {
                    window.esignstate.cancel();
                }
            }
        }
    }

    createSigningRequest = (certificateContent) => {
        const {
            signingType,
            activeLanguage,
            cegjegyzekSzam,
            signModalData,
            signMode,
            attributeRole,
            commentType,
            commentMessage,
        } = this.props;
        return {
            certificateContent: certificateContent || null,
            ackVersion: signingType === 'ACKNOWLEDGEMENT' ? 'v2' : null,
            containers: signModalData || [],
            language: activeLanguage || 'en',
            registrationNumber: cegjegyzekSzam || null,
            sendPassByMENotification: signMode === 'signUsingPush',
            signingType: signingType,
            commentType: commentType ? commentType : null,
            commentMessage: commentMessage,
            attributeRole: attributeRole,
        };
    };

    startSigning = () => {
        const { certificateContent, translate } = this.props;
        if (isBlank(certificateContent)) {
            ajax()
                .get('/user/certificate')
                .then((response) => {
                    console.debug('Signing with default user certificate.');
                    this.signing(response.data);
                })
                .catch((error) => {
                    if (error.response.data.error.code === 'HTTP_CLIENT_ERROR_NOT_FOUND') {
                        console.debug('Signing without default user certificate.');
                        this.signing(null);
                    } else {
                        console.error(
                            `Unable to sign because getting possible user certificate was failed: ${error.message}.`
                        );

                        createNotification(translate, error.response.data.error.code, {
                            description: translate('messages.error.signingDefaultUserCertificateGet', null, {
                                missingTranslationMsg: 'Getting possible user certificate was failed.',
                            }),
                            message: translate('titles.signingError', null, { missingTranslationMsg: 'Signing error' }),
                            type: 'error',
                        });
                    }
                });
        } else {
            console.debug('Signing with preselected user certificate.');

            this.signing(certificateContent);
        }
    };

    signing = (certificateContent) => {
        const { showSignModal, signingType, signMode, removeModal, translate } = this.props;
        ajax()
            .post('/esign', this.createSigningRequest(certificateContent))
            .then((response) => {
                response.data.notSignedContainersWithReason.forEach((containerNamesWithReason) => {
                    createNotificationShort(this.props.translate, {
                        message: translate(`errorCodes.${containerNamesWithReason.reason}`, {
                            title: containerNamesWithReason.containerName,
                        }),
                        type: 'warning',
                    });
                });
                window.esignstate.onStateAJAXFailure = () => {
                    // TODO: retry 3 times
                    return true;
                };
                window.esignstate.onStateESignFailure = (status) => {
                    if (status.code === 'CANCEL') {
                        this._isMounted &&
                            this.setState({ error: { code: 'ESZIGNO_PROCESS_CANCELLED', detail: null } });
                    } else if (status.code === 'ERROR') {
                        this._isMounted &&
                            this.setState({ error: { code: 'ESZIGNO_INTERNAL_PROGRAM_ERROR', detail: null } });
                    }
                };

                let esignWaitingForConfigGotCount = 0;
                window.esignstate.onStateGot = (counter, state) => {
                    const { esignStateCode, showESignNoClient } = this.state;
                    if (esignStateCode === state.code) {
                        // esign state didn't become different
                        if (esignStateCode === 'WAITING_FOR_CONFIG_GOT') {
                            esignWaitingForConfigGotCount++;
                            if (!showESignNoClient && esignWaitingForConfigGotCount > 10) {
                                this._isMounted && this.setState({ showESignNoClient: true });
                            }
                        }
                    } else {
                        // esign state became different
                        this._isMounted &&
                            this.setState({
                                esignStateCode: state.code,
                                showESignNoClient:
                                    esignStateCode === 'WAITING_FOR_CONFIG_GOT' &&
                                    showESignNoClient &&
                                    state.code !== 'WAITING_FOR_CONFIG_GOT'
                                        ? false
                                        : showESignNoClient,
                                checkStatusMultipleStart:
                                    esignStateCode !== 'WAITING_FOR_UNSIGNED_HASHES' &&
                                    state.code === 'WAITING_FOR_UNSIGNED_HASHES',
                            });
                    }
                    return true;
                };

                let ackContainerIds = signingType === 'acknowledgement' ? response.data.documentIds : null;
                let esignRequest = response.data.esignRequest;

                this._isMounted &&
                    this.setState({
                        ackContainerIds: ackContainerIds,
                        esignSessionId: esignRequest.sessionId,
                        esignDocumentIds: esignRequest.documentIds,
                    });

                window.esignstate.startAndListen(
                    esignRequest,
                    signMode === 'signUsingQRCode' || signMode === 'signUsingPush' ? 'img_esign' : 'a_esign',
                    () => {
                        this._isMounted && this.setState({ esignCheckDocumentStatesStart: true });
                    }
                );
            })
            .catch((error) => {
                if (error.response.status === 420 && error.response.data.code === ERROR_CODE_USER_ACCESS_TOKEN_EMPTY) {
                    console.info('User has no refresh token for sending notification of signing.');

                    this.obtainRefreshTokenForSigning()
                        .then(() =>
                            showSignModal(
                                Object.assign(this.getNonMappedProps(), {
                                    certificateContent: certificateContent,
                                })
                            )
                        )
                        .catch((error) => this.onError(error));
                } else if (error.response.status === 400 && error.response.data.code === "USER_INVALID_PARAMETERS") {
                    removeModal();
                    Modal.confirm({
                            title: translate("notifications.userSignModeDisabled.title"),
                            maskClosable: false,
                            width: '30%',
                            content: translate("notifications.userSignModeDisabled.description"),
                            okText: translate('buttons.submitOidAndPassword'),
                            onOk: () => {
                                getHistory().push({
                                    pathname: '/settings'
                                });
                            },
                            cancelText: translate('buttons.later'),
                            icon: <Icon type="close-circle" theme="twoTone" twoToneColor="red" />,
                        });
                }
                else if (error.response.status === 405) {
                    removeModal();
                    error.response.data.data.forEach((fileError) => {
                        createNotification(translate, error.response.data.code, {
                            message: this.props.translate(`errorCodes.${fileError.reason}`, { title: fileError.containerName }),
                            type: 'error'
                        });
                    });
                }
                else {
                    this.onError(error.response);
                }
            });
    };

    onError = (error) => {
        if (!error) {
            console.debug("Error undefined");
        } else if (error.response) {
            this._isMounted && this.setState({ error: error.response.data.error });
        } else if (error.data) {
            this._isMounted && this.setState({ error: error.data });
        } else {
            this._isMounted && this.setState({ error: { code: 'HTTP_NO_RESPONSE' } });
        }
    };

    onCancel = () => {
        if (this.state.esignSessionId) {
            window.esignstate.cancel();
        }
    };

    onFinishedESignCheckDocumentStates = (succeeded) => {
        if (succeeded) {
            this.props.onFinished({ succeeded: true, ackContainerIds: this.state.ackContainerIds });
        } else {
            this._isMounted && this.setState({ error: { code: 'ESZIGNO_INTERNAL_PROGRAM_ERROR', detail: null } });
        }
    };

    obtainRefreshTokenForSigning = () => {
        const { startMobileLogin, removeModal, showMobileAuthenticationModal, translate } = this.props;

        return new Promise((resolve, reject) => {
            startMobileLogin(`web-Szigno/${browserName}/${osName}-${osVersion}`)
                .then((response) => {
                    removeModal();

                    showMobileAuthenticationModal(
                        Object.assign(response.data, {
                            updateRefreshToken: true,
                            title: translate('titles.notificationSignWithoutRefreshToken'),
                            description: translate('texts.notificationSignWithoutRefreshToken'),
                            onAuthenticated: () => {
                                console.info('Refresh token obtained to user for signing.');

                                resolve();
                            },
                            onCancel: () => {
                                reject(undefined);
                            },
                        })
                    );
                })
                .catch((error) => reject(error));
        });
    };

    getNonMappedProps = () => {
        const {
            activeLanguage,
            attributeRole,
            cegjegyzekSzam,
            certificateContent,
            commentMessage,
            commentType,
            containerIds,
            pdfImagePaddingX,
            pdfImagePaddingY,
            pdfImagePage,
            pdfImageResize,
            pdfReason,
            schemaId,
            signingType,
            signModalData,
            signMode,
            onFinished,
        } = this.props;

        return {
            activeLanguage: activeLanguage,
            attributeRole: attributeRole,
            cegjegyzekSzam: cegjegyzekSzam,
            certificateContent: certificateContent,
            commentMessage: commentMessage,
            commentType: commentType,
            containerIds: containerIds,
            pdfImagePaddingX: pdfImagePaddingX,
            pdfImagePaddingY: pdfImagePaddingY,
            pdfImagePage: pdfImagePage,
            pdfImageResize: pdfImageResize,
            pdfReason: pdfReason,
            schemaId: schemaId,
            signingType: signingType,
            signModalData: signModalData,
            signMode: signMode,
            onFinished: onFinished,
        };
    };


    render() {
        const { translate, signingType, signMode, signModalData } = this.props;
        const {
            error,
            esignError,
            ackContainerIds,
            esignSessionId,
            esignDocumentIds,
            esignStateCode,
            showESignNoClient,
            checkStatusMultipleStart,
            esignCheckDocumentStatesStart,
        } = this.state;
        if (error) {
            return (
                <React.Fragment>
                    <CustomAlert error={esignError ? esignError : error} />
                </React.Fragment>
            );
        }
        const signWithPBM = signMode === 'signUsingQRCode' || signMode === 'signUsingPush';
        const imgEsignStyleDisplay = signMode === 'signUsingQRCode' ? 'block' : 'none';
        const containerIds = signModalData.map((data) => data.containerId);
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    {translate('esign.sessionId')} {esignSessionId}
                </p>
                {signWithPBM && (!esignStateCode || esignStateCode === 'WAITING_FOR_CONFIG_GOT') && (
                    <img
                        id="img_esign"
                        alt="Scan QR code to sign"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAAMcSURBVHhe7dQxAQAgDMCwgX/PwIGLJk8ddJ1ngKT9CwQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAIQZAGTNXB6wBfwB+T1dAAAAAElFTkSuQmCC"
                        style={{ display: imgEsignStyleDisplay, margin: 'auto' }}
                    />
                )}
                <p>{esignStateCodeToMessage(esignStateCode, signMode, translate)}</p>
                <ESignNoClient translate={translate} signWithPBM={signWithPBM} visible={showESignNoClient} />
                {esignStateCode && esignStateCode !== 'WAITING_FOR_CONFIG_GOT' && (
                    <CheckStatusMultiple
                        translate={translate}
                        containerIds={signingType === 'acknowledgement' ? ackContainerIds : containerIds}
                        start={checkStatusMultipleStart}
                        sessionId={esignSessionId}
                        onFinished={this.onFinishedESignCheckDocumentStates}
                    />
                )}
                {esignStateCode && esignStateCode !== 'WAITING_FOR_CONFIG_GOT' && esignDocumentIds && (
                    <ESignCheckDocumentStates
                        translate={translate}
                        documentIds={esignDocumentIds}
                        start={esignCheckDocumentStatesStart}
                        onFinished={this.onFinishedESignCheckDocumentStates}
                    />
                )}
                <div style={{ marginTop: '1em' }}>
                    <Button block type="primary" onClick={this.onCancel} disabled={esignStateCode === 'FINISHED'}>
                        {translate('buttons.cancel')}
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

Sign.propTypes = {
    actions: PropTypes.object.isRequired,
    signingType: PropTypes.string.isRequired,
    certificateContent: PropTypes.string,
    containerIds: PropTypes.array,
    signMode: PropTypes.string.isRequired,
    onFinished: PropTypes.func.isRequired,
    cegjegyzekSzam: PropTypes.string,
    pdfImagePaddingX: PropTypes.number,
    pdfImagePaddingY: PropTypes.number,
    pdfImagePage: PropTypes.number,
    pdfImageResize: PropTypes.number,
    activeLanguage: PropTypes.string,
    schemaId: PropTypes.number,
    pdfReason: PropTypes.string,
    removeModal: PropTypes.func.isRequired,
    showMobileAuthenticationModal: PropTypes.func.isRequired,
    showSignModal: PropTypes.func.isRequired,
    signModalData: PropTypes.array,
    translate: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
        removeModal: () => {
            dispatch(removeModal());
        },
        showMobileAuthenticationModal: (data) => {
            return dispatch(toggleModal(MOBILE_AUTHENTICATION, data));
        },
        showSignModal: (data) => {
            return dispatch(toggleModal(SIGN, data));
        },
        startMobileLogin: startMobileLogin
    };
}

export default connect(null, mapDispatchToProps)(Sign);
