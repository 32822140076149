import CustomAlert from '../../CustomAlert';
import FormItem from '../../form/FormItem';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { Button, Form, Icon, Input } from 'antd';
import { addTspCredentials, checkTsp } from '../../../actions/tsa';
import { getActiveLanguage, getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { toggleModal } from '../../../actions/modal';
import authService from '../../../services/authService';
import { TSA } from '../../../constants/modalNames';

class TSAModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        const { validateFormAndAddTimeStampCredentials, form } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                validateFormAndAddTimeStampCredentials(values).catch((err) => {
                    this._isMounted && this.setState({ serverError: err });
                });
            }
        });
    };

    render() {
        const { translate, modalData } = this.props;
        const { serverError } = this.state;
        const { getFieldDecorator } = this.props.form;
        const enableSkipTimestampAuthButton = !authService.getSigningWithTimestampOnly();

        return (
            <ModalWrapper
                {...this.props}
                title={
                    <span>
                        <Icon type="clock-circle-o" /> {translate('modals.tsa.title')}
                    </span>
                }
            >
                <p>
                    {translate('modals.tsa.textStart')}
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {' '}
                        (<span style={{ fontWeight: 'bold' }}>{window.config.REACT_APP_TSA_URL}</span>){' '}
                    </span>
                    {translate('modals.tsa.textEnd')}
                </p>
                {serverError && <CustomAlert error={serverError} />}
                <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <FormItem>
                        {getFieldDecorator('tsaUser', {
                            rules: [{ required: true, message: translate('formValidationErrors.required') }],
                        })(
                            <Input
                                prefix={<Icon type="user" />}
                                placeholder={translate('modals.tsa.fields.tsaUser.placeholder')}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('tsaPassword', {
                            rules: [{ required: true, message: translate('formValidationErrors.required') }],
                        })(
                            <Input
                                prefix={<Icon type="lock" />}
                                placeholder={translate('modals.tsa.fields.tsaPassword.placeholder')}
                                type={'password'}
                            />
                        )}
                    </FormItem>
                    <FormItem>
                        <Button type="primary" block htmlType="submit">
                            {translate('modals.tsa.buttonNext.text')}
                        </Button>
                    </FormItem>
                </Form>
                {modalData &&
                    modalData.signWithoutTS &&
                    enableSkipTimestampAuthButton(
                        <Button
                            type="primary"
                            block
                            onClick={() => {
                                this.props.toggleTsaModal();
                                modalData.signWithoutTS();
                            }}
                        >
                            {translate('modals.tsa.buttonSkip.text')}
                        </Button>
                    )}
            </ModalWrapper>
        );
    }
}

TSAModal.propTypes = {
    form: PropTypes.object.isRequired,
    modalData: PropTypes.object.isRequired,
    toggleTsaModal: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    validateFormAndAddTimeStampCredentials: PropTypes.isRequired,
};

const form = Form.create()(TSAModal);

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
        containerId: state.containerList.selectedContainers[0],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleTsaModal: () => {
            dispatch(toggleModal(TSA));
        },
        validateFormAndAddTimeStampCredentials: (data) => {
            ReactGA.event({
                category: 'User',
                action: 'Add Timestamp Credentials',
            });
            return dispatch(addTspCredentials(data))
                .then(() => {
                    dispatch(checkTsp());
                    dispatch(toggleModal(TSA));
                })
                .catch((error) => {
                    return Promise.reject(error.response.data.error);
                });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(form);
