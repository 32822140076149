import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { logout } from '../actions/user';
import { connect } from 'react-redux';
import { createNotification } from '../helper';
import { getTranslate } from 'react-localize-redux/lib/index';

class Logout extends React.Component {
    componentDidMount() {
        const { logoutAction, translate, history } = this.props;
        const isAdmin = localStorage.getItem('admin') === 'true';
        logoutAction()
            .then(() => {
                if (isAdmin) {
                    history.push('/adminLogin');
                } else {
                    history.push('/login');
                }
            })
            .catch((e) => {
                createNotification(translate, e.response.data.code, {
                    message: translate('errorCodes.SERVER_APPLICATION_ERROR'),
                    type: 'error',
                });
                history.push('/');
            });
    }

    render() {
        return null;
    }
}

Logout.propTypes = {
    history: PropTypes.object,
    logoutAction: PropTypes.func,
    translate: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logoutAction: () => {
            ReactGA.event({
                category: 'User',
                action: 'Logout',
            });
            return dispatch(logout());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
