import Banner from './layout/Banner';
import Footer from './layout/Footer';
import Header from './layout/Header';
import PropTypes from 'prop-types';
import React from 'react';
import { Layout, Spin } from 'antd';

export default function WebPage(props) {
    const { children, isLoaded } = props;

    return (
        <Layout
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
            }}>
            <Banner />
            <Header />
            <Layout.Content
                style={{
                    flex: '1 0 auto',
                    margin: '16px',
                    overflow: 'hidden',
                    padding: '0px 24px'
                }}>
                {isLoaded ? (
                    children
                ) : (
                    <Spin
                        size={'large'}
                        style={{
                            left: '50%',
                            marginLeft: '-32px',
                            marginTop: '-32px',
                            position: 'fixed',
                            top: '50%'
                        }}
                    />
                )}
            </Layout.Content>
            <Footer />
        </Layout>
    );
}

WebPage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isLoaded: PropTypes.bool
};

WebPage.defaultProps = {
    isLoaded: true
};
