import DeleteButton from './DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Tooltip } from 'antd';

export default function TooltipButton(props) {
    const {
        block,
        disabled,
        disabledTooltip,
        htmlType,
        ghost,
        icon,
        id,
        onClick,
        style,
        title,
        tooltip,
        tooltipPlacement,
        type
    } = props;

    const buttonProps = {
        block: block,
        className: 'remove-focus-from-button',
        disabled: disabled,
        htmlType: htmlType,
        ghost: ghost,
        icon: icon,
        id: id,
        onClick: onClick,
        style: style ? style : { borderRadius: 8 }
    };

    return (
        <Tooltip
            placement={tooltipPlacement ? tooltipPlacement : 'top'}
            title={disabled ? disabledTooltip : tooltip}>
            {type === 'danger' ? (
                <DeleteButton {...buttonProps}>{title}</DeleteButton>
            ) : (
                <Button {...buttonProps}>{title}</Button>
            )}
        </Tooltip>
    );
}

TooltipButton.propTypes = {
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.string,
    htmlType: PropTypes.string,
    ghost: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    id: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    type: PropTypes.string
};
