/**
 * Gets the UTC-based time offset by timezone and daylight save time of the local computer.
 *
 * @param utcDate UTC-based date.
 * @return {Date} The offset date.
 */
export function offsetDateToLocalComputer(utcDate) {
    // Időzóna és téli-nyári időszámítás eltolás.
    let offsetInMilliseconds = (new Date().getTimezoneOffset() * 60 * 1000);

    return new Date(utcDate.getTime() - offsetInMilliseconds)
}
