import * as types from '../actions/actionTypes';
import { ajax } from '../helper';

export const getUser = (email) => {
    return function () {
        return ajax()
            .get(`/admin/users/${email}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getUsers = (filter, orderBy, order, userLimit, userOffset, onlyAdmins) => {
    return function () {
        let params = '';
        filter && filter !== '' && (params = params + `filter=${filter}&`);
        params = params + `orderBy=${orderBy}&order=${order}&userLimit=${userLimit}&userOffset=${userOffset}&onlyAdmins=${onlyAdmins}`;

        return ajax()
            .get(`/admin/users?${params}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getUserContainers = (email) => async () => {
    return await ajax().get(`/admin/users/${email}/containers`);
};

export const createUsers = (inputValues) => {
    return function () {
        return ajax()
            .post(`/admin/user`, inputValues)
            .then((response) => {
                return response;
            });
    };
};

export const deleteUser = (email) => async () => {
    return await ajax().delete(`/admin/users/${email}`);
};

export const getSigningProcesses = () => {
    return function (dispatch) {
        return ajax()
            .get('/admin/containers/signingProcesses')
            .then((response) => {
                dispatch(updateSigningProcesses(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const getSigningProcessesByGuid = (guid) => {
    return function (dispatch) {
        return ajax()
            .get(`/admin/containers/signingProcesses/${guid}`)
            .then((response) => {
                dispatch(updateSigningProcessesByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const deleteSigningProcess = (guid) => {
    return function (dispatch) {
        return ajax()
            .delete(`/admin/containers/signingProcesses/${guid}`)
            .then((response) => {
                dispatch(updateSigningProcesses(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const removeUserFromSigningProcess = (guid, signingOrder) => {
    return function (dispatch) {
        return ajax()
            .delete(`/admin/containers/signingProcesses/${guid}/remove/${signingOrder}`)
            .then((response) => {
                dispatch(updateSigningProcessesByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const resendContainerToUser = (guid, signingOrder) => {
    return function (dispatch) {
        return ajax()
            .get(`/admin/containers/signingProcesses/${guid}/resend/${signingOrder}`)
            .then((response) => {
                dispatch(updateSigningProcessesByContainer(response.data));
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const saveDeputy = (deputyData) => async () => {
    return await ajax().post(`/admin/deputies`, { ...deputyData });
};

export const updateDeputy = (deputyData) => async () => {
    return await ajax().put(`/admin/deputies/${deputyData.userEmail}`, {
        userEmail: deputyData.userEmail,
        deputyEmail: deputyData.deputyEmail,
        deputyPeriod: { from: deputyData.deputyPeriod.from, to: deputyData.deputyPeriod.to },
    });
};

export const deleteDeputyData = (deputyData) => async () => {
    return await ajax().delete(`/admin/deputies/${deputyData.userEmail}`);
};

export const updateSigningProcesses = (containers) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS,
        payload: containers,
    };
};

export const updateSigningProcessesByContainer = (container) => {
    return {
        type: types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_BY_CONTAINER,
        payload: container,
    };
};

export const sendGenerationData = (generationData) => {
    return function () {
        return ajax()
            .post(`/admin/generate`, { ...generationData })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
        });
    };
};

export const sendCleanUpRequest = (data) => {
    return function () {
        return ajax()
            .post(`/admin/cleanUp`, { ...data })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
