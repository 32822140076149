import CertificateSelect from '../widget/CertificateSelect';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ajax, createNotification } from '../../helper';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { toggleModal, removeModal } from '../../actions/modal';
import { ERROR_LEVEL_WARNING } from '../../constants/errorLevel';
import { ATTRIBUTE_ROLES } from '../../constants/modalNames';

class SelectCertificateModal extends Component {
    handleUserCertificateSelected = (sessionId, sessionURL) => {
        const { translate } = this.props;
        ajax()
            .get(`/certificate/${sessionId}`)
            .then((response) => {
                this.props.removeModal();
                this.props.openAttributeRolesModal({
                    startSign: this.props.modalData.startSign,
                    certificate: response.data,
                });
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    description: translate('messages.error.defaultUserCertificateSet', null, {
                        missingTranslationMsg: 'Unable to set selected default certificate due a server error.',
                    }),
                    message: translate('titles.defaultUserCertificateError', null, {
                        missingTranslationMsg: 'Default certificate error',
                    }),
                    type: 'error',
                });
            });
    };

    handleUserCertificateSelectError = (level, errorCode) => {
        const { translate } = this.props;

        createNotification(translate, errorCode, {
            description: translate(`messages.error.certificateSelect.${errorCode}`, null, {
                showMissingTranslationMsg: false
            }),
            message: translate('titles.defaultUserCertificateSelectError', null, {
                missingTranslationMsg: 'Default certificate selection error'
            }),
            type: level === ERROR_LEVEL_WARNING ? 'warning' : 'error'
        });
    };

    render() {
        const { activeLanguageCode, translate } = this.props;
        const modalProps = { width: '700px', ...this.props };
        return (
            <ModalWrapper
                {...modalProps}
                title={translate('esign.message.certificateSelect')}
                width={600}>
                <p style={{ marginBottom: 20 }}>{translate('modals.attributeCert.selectCertificateDesc')}</p>
                <CertificateSelect
                    autoStart={true}
                    activeLanguageCode={activeLanguageCode}
                    eSignMessage={translate('esign.message.defaultUserCertificateSelect', null, {
                        missingTranslationMsg: 'Please select your default certificate'
                    })}
                    showDetails={true}
                    signMode={localStorage.getItem('sign_mode')}
                    text={translate('button.defaultUserCertificateSelect', null, {
                        missingTranslationMsg: 'Select default certificate'
                    })}
                    title={translate('hint.defaultUserCertificateSelect', null, {
                        missingTranslationMsg: 'Select certificate'
                    })}
                    onCertificateSelected={this.handleUserCertificateSelected}
                    onCertificateSelectError={this.handleUserCertificateSelectError}
                />
            </ModalWrapper>
        );
    }
}

SelectCertificateModal.propTypes = {
    activeLanguageCode: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        activeLanguageCode: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeModal: () => dispatch(removeModal()),
        openAttributeRolesModal: (data) => {
            dispatch(toggleModal(ATTRIBUTE_ROLES, data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCertificateModal);
