import PropTypes from 'prop-types';
import React from 'react';
import { Comment } from 'antd';

/**
 * An antd comment with some style modifications.
 * The external props are:
 * - emailAddress: The author of the comment.
 * - message: The message.
 * - role: The workflow role of the author.
 */
function WorkflowComment(props) {
    return (
        <Comment
            author={<b style={{ color: 'rgb(0,0,0,0.85)', fontSize: 15 }}>{props.emailAddress}</b>}
            content={<div style={{ color: 'rgb(0,0,0,0.85)', fontSize: 14 }}>{props.message}</div>}
            datetime={
                <b>
                    <i style={{ color: 'rgb(0,0,0,0.7)', fontSize: 15 }}>({props.role})</i>
                </b>
            }
        />
    );
}

WorkflowComment.propTypes = {
    emailAddress: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
};

export default WorkflowComment;
