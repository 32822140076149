import FormItem from '../../form/FormItem';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { EMAIL_SEARCH_LIST_SIZE, EMAIL_SEARCH_MIN_LENGTH, PLACEHOLDER_EMAIL } from '../../../constants/config';
import { AutoComplete, Button, Form, Icon, Input } from 'antd';
import { createNotification, createNotificationShort } from '../../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { searchEmails, sendInEmail } from '../../../actions/container';
import { removeModal } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';

function ShareModal(props) {
    const { form } = props;

    const [emailAddresses, setEmailAddresses] = useState([]);

    const translate = useSelector((state) => getTranslate(state.locale));
    const currentLanguage = useSelector((state) => getActiveLanguage(state.locale).code);
    const container = useSelector((state) => state.containerList.selectedContainers[0]);

    const dispatch = useDispatch();

    function submitForm(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                dispatch(sendInEmail(container.data.id, values.email, currentLanguage))
                    .then(() => {
                        createNotificationShort(translate, {
                            message: translate('notifications.share.success'),
                            type: 'success'
                        });
                    })
                    .catch((e) => {
                        createNotification(translate, e.response.data.code, {
                            message: translate('notifications.share.error'),
                            type: 'error'
                        });
                    })
                    .finally(() => {
                        dispatch(removeModal());
                    });
            }
        });
    }

    function onAutoCompleteChange(event) {
        if (event.length >= EMAIL_SEARCH_MIN_LENGTH) {
            dispatch(searchEmails(event)).then((response) => {
                setEmailAddresses(response.data);
            });
        } else {
            setEmailAddresses([]);
        }
    }

    const dataSource =
        emailAddresses.length < EMAIL_SEARCH_LIST_SIZE
            ? emailAddresses
            : emailAddresses.slice(0, EMAIL_SEARCH_LIST_SIZE);

    return (
        <ModalWrapper
            {...props}
            alignFooterLeft={true}
            footer={<i>{'(*) ' + translate('modals.share.footnote')}</i>}
            title={
                <b>
                    {translate('modals.share.title')}
                    {<i>{' *'}</i>}
                </b>
            }>
            <Form onSubmit={submitForm}>
                <p>{translate('modals.share.description')}</p>
                <FormItem>
                    {form.getFieldDecorator('email', {
                        normalize: (input) => (input !== undefined ? input.toLowerCase() : ''),
                        rules: [
                            { required: true, message: translate('form.required') },
                            { type: 'email', message: translate('form.email.invalid') }
                        ]
                    })(
                        <AutoComplete
                            autoFocus={true}
                            dataSource={dataSource}
                            onChange={onAutoCompleteChange}>
                            <Input
                                placeholder={PLACEHOLDER_EMAIL}
                                prefix={<Icon type={'mail'} />}
                            />
                        </AutoComplete>
                    )}
                </FormItem>
                <FormItem formItemProps={{ style: { marginBottom: 0 } }}>
                    <Button
                        block={true}
                        htmlType={'submit'}
                        id={'share-button'}
                        type={'primary'}>
                        {translate('modals.share.title')}
                    </Button>
                </FormItem>
            </Form>
        </ModalWrapper>
    );
}

ShareModal.propTypes = {
    form: PropTypes.object.isRequired
};

const WrappedShareModal = Form.create({ name: 'share-modal' })(ShareModal);
export default WrappedShareModal;
