import DeleteButton from './DeleteButton';
import PropTypes from 'prop-types';
import React from 'react';
import { REMOVE_CONTAINER } from '../../constants/modalNames';
import { getTranslate } from 'react-localize-redux';
import { makeGetFirstFromRemainingContainers } from './../../selectors/index';
import { toggleModal } from '../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';

function DeleteContainerButton(props) {
    const { disabled } = props;

    const getFirstFromRemainingContainers = makeGetFirstFromRemainingContainers();
    const newActiveContainer = useSelector((state) => getFirstFromRemainingContainers(state, props));
    const selectedContainerIDs = useSelector((state) =>
        state.containerList.selectedContainers.map((container) => container.data.id)
    );
    const translate = useSelector((state) => getTranslate(state.locale));

    const dispatch = useDispatch();

    return (
        <DeleteButton
            disabled={disabled}
            id={'delete-container-button'}
            onClick={() =>
                dispatch(
                    toggleModal(REMOVE_CONTAINER, {
                        newActiveContainer: newActiveContainer,
                        selectedContainerIDs: selectedContainerIDs
                    })
                )
            }
            title={translate('buttons.delete.container')}
        />
    );
}

DeleteContainerButton.propTypes = {
    disabled: PropTypes.bool.isRequired
};

export default DeleteContainerButton;
