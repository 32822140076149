import AppStoreImage from '../../images/badge_appstore-lrg.svg';
import PlayStoreImage from '../../images/google-play-badge.svg';
import PropTypes from 'prop-types';
import React from 'react';

export default function MobileStoreButton(props) {
    const { linkProps, store, url } = props;

    return (
        <a
            href={url}
            rel={'noopener noreferrer'}
            style={{
                display: 'block',
                textDecoration: 'none'
            }}
            target={'_blank'}
            {...linkProps}>
            <img
                alt={'Mobile store button'}
                style={{
                    height: '58px',
                    margin: '0px',
                    minWidth: '150px',
                    width: 'auto'
                }}
                src={store === 'android' ? PlayStoreImage : AppStoreImage}
            />
        </a>
    );
}

MobileStoreButton.propTypes = {
    linkProps: PropTypes.object,
    store: PropTypes.oneOf(['android', 'ios']).isRequired,
    url: PropTypes.string.isRequired
};
