import CustomAlert from '../CustomAlert';
import FormItem from '../form/FormItem';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Select, Input } from 'antd';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { removeModal, toggleModal } from '../../actions/modal';
import { USER_PROFILE_ERROR } from '../../constants/modalNames';

const { Option } = Select;

class ClosureModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
            isLoading: false,
            commentType: 'CLAUSE',
            inputValue: '',
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = (e) => {
        const { form } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                this.props.modalData.getComment(values.commentType, values.commentMessage);
                this.props.hideModal();
            }
        });
    };

    handleSelectChange = (value) => {
        this._isMounted && this.setState({ commentType: value });
    };

    handleInputChange = (event) => {
        this._isMounted && this.setState({ inputValue: event.target.value });
    };

    render() {
        const { translate } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { serverError } = this.state;
        return (
            <ModalWrapper {...this.props} title={translate('titles.signWithComment')}>
                {serverError && <CustomAlert error={serverError} />}
                <div>
                    <Form onSubmit={this.handleSubmit}>
                        <FormItem
                            formItemProps={{
                                label: translate('labels.signCommentType')
                            }}
                        >
                            {getFieldDecorator('commentType', {
                                initialValue: 'CLAUSE',
                            })(
                                <Select
                                    placeholder={translate('signCommentType.placeholder')}
                                    onChange={this.handleSelectChange}
                                >
                                    <Option value='CLAUSE'>{translate('signCommentType.clause')}</Option>
                                    <Option value='GLOSS'>{translate('signCommentType.gloss')}</Option>
                                    <Option value='COMMENT'>{translate('signCommentType.comment')}</Option>
                                    <Option value='OPINION'>{translate('signCommentType.opinion')}</Option>
                                </Select>
                            )}
                        </FormItem>
                        <FormItem
                            formItemProps={{
                                label: translate('labels.signCommentMessage')
                            }}
                        >
                            {getFieldDecorator(
                                'commentMessage',
                                {
                                    rules: [
                                        { required: true, message: translate('formValidationErrors.required')}
                                    ],
                                },
                            )(<Input maxLength={255} onChange={this.handleInputChange} />)}
                        </FormItem>
                        <FormItem>
                            <Button type='primary' block htmlType='submit'>
                                {translate('buttons.next')}
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </ModalWrapper>
        );
    }
}

ClosureModal.propTypes = {
    containers: PropTypes.array,
    currentLanguage: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    hideModal: PropTypes.func.isRequired,
    modalData: PropTypes.object.isRequired,
    userProfileErrorModal: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        hideModal: () => {
            dispatch(removeModal());
        },
        userProfileErrorModal: () => {
            dispatch(toggleModal(USER_PROFILE_ERROR));
        },
    };
}

const enhancedWithForm = Form.create()(ClosureModal);
export default connect(mapStateToProps, mapDispatchToProps)(enhancedWithForm);
