export function extractFieldFromDN(fieldName, dn) {
    const array = dn.match(/(\\.|[^,])+/g)

    if (array != null) {
        for (let index = 0; index < array.length; ++index) {
            const element = array[index];
            const prefix = fieldName + "=";

            if (element.startsWith(prefix)) {
                return element.substring(prefix.length).replace("\\,", ",");
            }
        }
    }

    return null;
}
