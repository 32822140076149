import * as types from './actionTypes';
import { ajax } from '../helper';

export const getDeputy = () => {
    return function (dispatch) {
        return ajax()
            .get('/deputy')
            .then((response) => {
                let payload = {};
                if (response.status === 200) {
                    payload = response.data;
                }
                dispatch({
                    type: types.GET_DEPUTY_SUCCESS,
                    payload: payload,
                });
            })
            .catch((e) => {
                console.error(e);
            });
    };
};

export const deleteDeputy = () => {
    return function (dispatch) {
        return ajax()
            .delete('/deputy')
            .then(() => {
                dispatch({
                    type: types.DEPUTY_RESET,
                });
            })
            .catch((e) => {
                console.error(e);
            });
    };
};

export const resetDeputy = () => {
    return {
        type: types.DEPUTY_RESET,
    };
};
