import FilenameInput from '../../form/FilenameInput';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, notification } from 'antd';
import { createNotification, removeExtension } from '../../../helper';
import { getTranslate } from 'react-localize-redux';
import { makeGetActiveTabContainerById } from '../../../selectors';
import { removeModal } from '../../../actions/modal';
import { renameContainer } from '../../../actions/container';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

function RenameContainerModal(props) {
    const { form } = props;

    const dispatch = useDispatch();

    const [isRenameButtonDisabled, setIsRenameButtonDisabled] = useState(false);

    const getActiveTabContainer = makeGetActiveTabContainerById();
    const currentContainer = useSelector((state) => getActiveTabContainer(state, props));
    const translate = useSelector((state) => getTranslate(state.locale));

    function submit(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                setIsRenameButtonDisabled(true);
                dispatch(renameContainer(currentContainer.data.id, values.filename))
                    .then((res) => {
                        notification.open({
                            message: translate('notifications.renameContainer.success'),
                            type: 'success',
                            duration: 10
                        });
                        if (removeExtension(values.filename) !== res.data.title) {
                            notification.open({
                                message: translate('notifications.uploadOK.fileNameChanged'),
                                type: 'info',
                                duration: 10
                            });
                        }
                        dispatch(removeModal());
                    })
                    .catch((error) => {
                        if (error.response.data.code === 'USER_INVALID_PARAMETERS') {
                            createNotification(translate, error.response.data.code, {
                                description: translate('notifications.filename.tooLong.description'),
                                message: translate('notifications.filename.tooLong.message'),
                                type: 'error'
                            });
                        } else {
                            createNotification(translate, error.response.data.code, {
                                message: translate('notifications.renameContainer.failure'),
                                type: 'error'
                            });
                        }
                    })
                    .finally(() => {
                        setIsRenameButtonDisabled(false);
                    });
            }
        });
    }

    return (
        <ModalWrapper
            {...props}
            title={translate('modals.renameContainer.title')}>
            <Form onSubmit={submit}>
                <FilenameInput
                    defaultFilename={currentContainer.data.fileBaseName}
                    form={form}
                />
                <Form.Item style={{ marginTop: '4px' }}>
                    <Button
                        block={true}
                        className={'remove-focus-from-button'}
                        disabled={isRenameButtonDisabled}
                        htmlType={'submit'}
                        type={'primary'}>
                        {translate('modals.renameContainer.button.text')}
                    </Button>
                </Form.Item>
            </Form>
        </ModalWrapper>
    );
}

RenameContainerModal.propTypes = {
    form: PropTypes.object.isRequired
};

export default Form.create({ name: 'rename-container' })(RenameContainerModal);
