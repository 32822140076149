import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import ModalWrapper from '../ModalWrapper';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { deleteDocument } from '../../../actions/document';
import { getActiveLanguage, getTranslate } from 'react-localize-redux/lib/index';
import { bindActionCreators } from 'redux';
import * as containerActions from '../../../actions/container';
import { createNotification, createNotificationShort } from '../../../helper';
import { REMOVE_DOCUMENT, REMOVE_SIGNATURE } from '../../../constants/modalNames';

class RemoveConfirmationModal extends React.Component {
    handleRemoveSignature = (callback) => {
        const { modalData, deleteSignatureAction, translate } = this.props;
        const { signature, contId, setTableLoadingFalse, setTableLoadingTrue } = modalData;
        setTableLoadingTrue();
        deleteSignatureAction(contId, signature.id)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    message: translate('signatures.remove.success'),
                    type: 'success'
                });
                callback();
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    description: e.response.data.detail,
                    message: translate('signatures.remove.failure'),
                    type: 'error'
                });
            })
            .finally(() => setTableLoadingFalse());
    };

    handleRemoveDocument = (callback) => {
        const { modalData, deleteDocumentAction, translate } = this.props;
        const { containerType, documentData, index, containerId, setTableLoadingFalse, setTableLoadingTrue } =
            modalData;
        let documentId = containerType === 'asic' ? documentData.title : documentData.id;
        setTableLoadingTrue();
        deleteDocumentAction(containerType, containerId, documentId, index)
            .then(() => {
                createNotificationShort(this.props.translate, {
                    message: translate('notifications.deleteDocument.success'),
                    type: 'success'
                });
                callback();
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: translate('notifications.deleteDocument.failure'),
                    type: 'error'
                });
            })
            .finally(() => setTableLoadingFalse());
    };

    getTypeFromModalName = () => {
        switch (this.props.modalName) {
            case REMOVE_SIGNATURE:
                return 'removeSignature';
            case REMOVE_DOCUMENT:
                return 'removeDocument';
            default:
                return 'unknownType';
        }
    };

    getHandlerFromType = (callback) => {
        switch (this.props.modalName) {
            case REMOVE_SIGNATURE:
                this.handleRemoveSignature(callback);
                break;
            case REMOVE_DOCUMENT:
                this.handleRemoveDocument(callback);
                break;
            default:
                break;
        }
    };

    render() {
        const { translate, toggleModal, modalData, modalName } = this.props;
        let typeToRemove = this.getTypeFromModalName();
        return (
            <ModalWrapper
                {...this.props}
                closable={false}
                footer={[
                    <Button
                        id={'deleteSigYes'}
                        key={'yes'}
                        type="primary"
                        onClick={() => {
                            toggleModal(modalName);
                            this.getHandlerFromType(modalData.callback ? modalData.callback : () => {});
                        }}>
                        {translate('buttons.yes')}
                    </Button>,
                    <Button
                        id={'deleteSigNo'}
                        key={'no'}
                        type="primary"
                        onClick={() => {
                            toggleModal('', '');
                        }}>
                        {translate('buttons.no')}
                    </Button>
                ]}
                title={translate(`modals.${typeToRemove}.title`)}>
                <p>{translate(`modals.${typeToRemove}.subTitle`)}</p>
            </ModalWrapper>
        );
    }
}

RemoveConfirmationModal.propTypes = {
    deleteDocumentAction: PropTypes.func.isRequired,
    deleteSignatureAction: PropTypes.func.isRequired,
    modalData: PropTypes.object,
    modalName: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
        containers: state.containerList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteSignatureAction: (contId, signId) => {
            ReactGA.event({
                category: 'User',
                action: 'Delete Signature'
            });
            return dispatch(containerActions.deleteSignature(contId, signId));
        },
        deleteDocumentAction: (type, contId, docId, index) => {
            ReactGA.event({
                category: 'User',
                action: 'Delete Document'
            });
            return dispatch(deleteDocument(type, contId, docId, index));
        },
        actions: bindActionCreators({ ...containerActions }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveConfirmationModal);
