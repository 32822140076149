import React from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

export default function BackToHomePageButton() {
    const translate = useSelector((state) => getTranslate(state.locale));

    return (
        <Link
            style={{ fontSize: 20 }}
            to={'/'}>
            <Icon type={'left-circle'} />
            <span> {translate('backToHomePage')}</span>
        </Link>
    );
}
