/* eslint-disable react-hooks/exhaustive-deps */
import Box from '../Box';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import authService from '../../services/authService';
import { TABLE_PAGE_SIZE_WORKFLOW_LIST } from '../../constants/config';
import { Switch, Table } from 'antd';
import { useSelector } from 'react-redux';
import {
    calculatePageNumber,
    formatDate,
    getStatusFilters,
    reversedSorter,
    sorter,
    translateStatus
} from '../../helper';
import { getTranslate } from 'react-localize-redux';

function WorkflowList(props) {
    const {
        isSelectMultipleWorkflow,
        selectedWorkflowGuid,
        selectedWorkflowGuids,
        setContainsMetadata,
        setIsSelectMultipleWorkflow,
        setSelectedWorkflowGuid,
        setSelectedWorkflowGuids,
        workflows
    } = props;

    const [filtered, setFiltered] = useState({});
    const [isMounted, setIsMounted] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [sortDirection, setSortDirection] = useState('descend');
    const [sortKey, setSortKey] = useState('date');

    const translate = useSelector((state) => getTranslate(state.locale));

    const mounted = useRef();

    useEffect(() => {
        setIsMounted(true);
        if (mounted.current) {
            setPageNumber(getPageNumber());
        }
        return () => {
            setIsMounted(false);
        };
    }, []);

    function getSorter() {
        let _sorter;
        if (sortDirection === 'descend') {
            _sorter = () => reversedSorter(sortKey);
        } else {
            _sorter = () => sorter(sortKey);
        }
        return _sorter;
    }

    function getPageNumber() {
        return calculatePageNumber(
            workflows.sort(getSorter()).findIndex((workflow) => workflow.guid === selectedWorkflowGuid),
            TABLE_PAGE_SIZE_WORKFLOW_LIST
        );
    }

    function filterOwnWorkflows() {
        return workflows.filter((w) => w.createdBy === authService.getUserEmail()).map((w) => w.guid);
    }

    function filterOwnWorkflowsOnPage(guids) {
        return workflows
            .filter((w) => guids.includes(w.guid) && w.createdBy === authService.getUserEmail())
            .map((w) => w.guid);
    }

    function onRowSelection(pagination, filters, sorter) {
        isMounted && setFiltered(filters);
        isMounted && setSortDirection(sorter.order);
        isMounted && setSortKey(sorter.columnKey);
    }

    function selectRow(workflow) {
        isMounted && setSelectedWorkflowGuid(workflow.guid);
        isMounted && setContainsMetadata(workflows.filter((w) => w.guid === workflow.guid)[0].containsMetadata);
    }

    function onSwitch() {
        if (!isSelectMultipleWorkflow) {
            isMounted && setSelectedWorkflowGuid(undefined);
            isMounted && setContainsMetadata(false);
        }
        isMounted && setIsSelectMultipleWorkflow(!isSelectMultipleWorkflow);
    }

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div style={{ display: 'table', wordBreak: 'break-all', wordWrap: 'break-word' }}>
                    <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>{record.name}</div>
                </div>
            ),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            title: translate('workflow.list.table.name')
        },
        {
            dataIndex: 'createdBy',
            key: 'createdBy',
            title: translate('workflow.list.table.processStarter')
        },
        {
            align: 'center',
            dataIndex: 'status',
            filters: getStatusFilters(translate),
            filteredValue: filtered.status || null,
            key: 'status',
            onFilter: (value, record) => record.status.includes(value),
            sorter: (a, b) => {
                return a.status.localeCompare(b.status);
            },
            sortDirections: ['descend', 'ascend'],
            title: translate('workflow.list.table.status'),
            width: 120
        },
        {
            dataIndex: 'comment',
            key: 'comment',
            title: translate('workflow.list.table.comment'),
            width: 300
        },
        {
            align: 'center',
            dataIndex: 'date',
            defaultSortOrder: 'descend',
            key: 'date',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => {
                return a.date.localeCompare(b.date);
            },
            title: translate('workflow.list.table.startDate'),
            width: 150
        }
    ];

    const dataSource = workflows.map((workflow) => ({
        comment: workflow.comment,
        createdBy: workflow.createdBy,
        date: formatDate(workflow.creationDate, translate('language')),
        guid: workflow.guid,
        key: workflow.guid,
        name: workflow.fileName,
        status: translateStatus(translate, workflow.status)
    }));

    const rowSelection = {
        hideDefaultSelections: true,
        onChange: (selectedRowKeys) => isMounted && setSelectedWorkflowGuids(selectedRowKeys),
        onSelectAll: () => isMounted && setSelectedWorkflowGuids(filterOwnWorkflows()),
        selectedRowKeys: selectedWorkflowGuids,
        selections: [
            {
                key: 'all',
                onSelect: () => isMounted && setSelectedWorkflowGuids(filterOwnWorkflows()),
                text: translate('workflow.list.table.select.all')
            },
            {
                key: 'page',
                onSelect: (rowKeys) => isMounted && setSelectedWorkflowGuids(filterOwnWorkflowsOnPage(rowKeys)),
                text: translate('workflow.list.table.select.page')
            }
        ]
    };

    return (
        <Box
            borderRadius={8}
            padding={16}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                <h2>{translate('workflow.list.title')}</h2>
                <Switch
                    checkedChildren={translate('workflow.list.switch.checked')}
                    defaultChecked={false}
                    onChange={() => onSwitch()}
                    style={{ marginTop: 4.75 }}
                    unCheckedChildren={translate('workflow.list.switch.unchecked')}
                />
            </div>
            <Table
                columns={columns}
                dataSource={dataSource}
                locale={{ emptyText: translate('workflow.list.placeholder') }}
                onChange={onRowSelection}
                onRow={(row) => ({
                    onClick: () => {
                        selectRow(row);
                    }
                })}
                pagination={{
                    current: pageNumber,
                    onChange: (page) => isMounted && setPageNumber(page),
                    pageSize: TABLE_PAGE_SIZE_WORKFLOW_LIST,
                    size: 'small'
                }}
                rowClassName={(row) => {
                    return row.guid === selectedWorkflowGuid ? 'table-select' : '';
                }}
                rowSelection={isSelectMultipleWorkflow ? rowSelection : undefined}
                size={'small'}
            />
        </Box>
    );
}

WorkflowList.propTypes = {
    isSelectMultipleWorkflow: PropTypes.bool.isRequired,
    selectedWorkflowGuid: PropTypes.string,
    selectedWorkflowGuids: PropTypes.array,
    setContainsMetadata: PropTypes.func.isRequired,
    setIsSelectMultipleWorkflow: PropTypes.func.isRequired,
    setSelectedWorkflowGuid: PropTypes.func.isRequired,
    setSelectedWorkflowGuids: PropTypes.func.isRequired,
    workflows: PropTypes.array.isRequired
};

export default WorkflowList;
