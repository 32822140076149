export const E_SIGN_STATE_WAITING_FOR_CERTIFICATE = "WAITING_FOR_CERTIFICATE";
export const E_SIGN_STATE_WAITING_FOR_CONFIGURATION = "WAITING_FOR_CONFIG_GOT";
export const E_SIGN_STATE_INTERNAL_ERROR = "INTERNAL_ERROR";
export const E_SIGN_STATE_INTERNAL_AJAX_ERROR = "INTERNAL_AJAX_ERROR";
export const E_SIGN_STATE_FINISHED = "FINISHED";
export const E_SIGN_STATE_CANCELLED = "CANCEL";

export function isErrorESignState(eSignState) {
    switch (eSignState) {
        case E_SIGN_STATE_INTERNAL_ERROR:
        case E_SIGN_STATE_INTERNAL_AJAX_ERROR:
            return true;

        default:
            return false;
    }
}

export function isFinalESignState(eSignState) {
    if (isErrorESignState(eSignState)) {
        return true;
    }

    switch (eSignState) {
        case E_SIGN_STATE_FINISHED:
        case E_SIGN_STATE_CANCELLED:
            return true;

        default:
            return false;
    }
}
