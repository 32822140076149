import { ajax } from '../helper';
import { DELETE_WORKFLOW_TEMPLATE, FETCH_WORKFLOW_TEMPLATES, UPDATE_WORKFLOW_TEMPLATE } from './actionTypes';

export const fetchWorkflowTemplates = (templates) => {
    return {
        type: FETCH_WORKFLOW_TEMPLATES,
        payload: templates
    };
};

export const updateWorkflowTemplateAction = (template) => {
    return {
        type: UPDATE_WORKFLOW_TEMPLATE,
        payload: template
    };
};

export const deleteWorkflowTemplateAction = (guid) => {
    return {
        type: DELETE_WORKFLOW_TEMPLATE,
        payload: guid
    };
};

export const deleteWorkflow = (guid) => {
    return function() {
        return ajax()
            .delete(`/containers/bulkSigningProcesses/${guid}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const removeUserFromWorkflow = (guid, order) => {
    return function() {
        return ajax()
            .delete(`/containers/bulkSigningProcesses/${guid}/user/${order}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const resendWorkflow = (guid, order) => {
    return function() {
        return ajax()
            .put(`/containers/bulkSigningProcesses/${guid}/user/${order}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export function getMetadata(guid) {
    return function() {
        return ajax()
            .get(`/workflow/metadata/${guid}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const getWorkflowTemplates = () => {
    return (dispatch) => {
        return ajax()
            .get(`/workflow/template`)
            .then((response) => {
                dispatch(fetchWorkflowTemplates(response.data));
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export function getWorkflowTemplateById(id) {
    return function() {
        return ajax()
            .get(`/workflow/template/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function createWorkflowTemplate(title, targets, metadata, comment) {
    return function() {
        return ajax()
            .post(`/workflow/template`, {
                templateTitle: title,
                recipientList: targets,
                metadataList: metadata,
                comment: comment
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const updateWorkflowTemplate = (template) => {
    return (dispatch) => {
        return ajax()
            .put(`/workflow/template`, template)
            .then((response) => {
                dispatch(updateWorkflowTemplateAction(template));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const deleteWorkflowTemplate = (id) => {
    return (dispatch) => {
        return ajax()
            .delete(`/workflow/template/${id}`)
            .then((response) => {
                dispatch(deleteWorkflowTemplateAction(id));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};
