import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchAllSignatureProfiles } from '../../actions/signatureProfile';
import { createNotification, createNotificationShort, getHistory } from '../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { Button } from 'antd';
import { removeModal, toggleModal } from '../../actions/modal';
import * as userActions from '../../actions/user';
import authService from '../../services/authService';
import { NOTIFY_USER } from '../../constants/modalNames';

class MobileLoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiTimeout: null,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const { modalData } = this.props;
        this._isMounted &&
            this.setState({ apiTimeout: modalData.isLogin ? setTimeout(this.pollingLogin, modalData.pollingTimeIntervalSec * 1000) : setTimeout(this.pollingSetRefreshToken, modalData.pollingTimeIntervalSec * 1000)  });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    pollingSetRefreshToken = () => {
        const { actions, modalData, currentLanguage, removeModal } = this.props;
        actions
            .pollingTokenProvider(modalData, currentLanguage)
            .then((response) => {
                if (response === 202) {
                    this._isMounted &&
                        this.setState({
                            apiTimeout: setTimeout(this.pollingSetRefreshToken, modalData.pollingTimeIntervalSec * 1000),
                        });
                } else {
                    clearTimeout(this.state.apiTimeout);
                    removeModal();
                    authService.setSecondFactor(true);
                    createNotificationShort(this.props.translate, {
                        message: this.props.translate('messages.success.settingSaved'),
                        type: 'success',
                    });
                }
            })
            .catch((e) => {
                if (this.props.modalData.onCancel) {
                    this.props.modalData.onCancel();
                }
                clearTimeout(this.state.apiTimeout);
                removeModal();
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.couldNotLogin'),
                    type: 'error',
                });
            });
    };

    pollingLogin = () => {
        const { actions, modalData, currentLanguage, removeModal } = this.props;
        actions
            .pollingMobileLogin(modalData, currentLanguage)
            .then((response) => {
                if (response === 202) {
                    this._isMounted &&
                        this.setState({
                            apiTimeout: setTimeout(this.pollingLogin, modalData.pollingTimeIntervalSec * 1000),
                        });
                } else {
                    clearTimeout(this.state.apiTimeout);
                    removeModal();
                    if (modalData.isLogin) {
                        this.redirect(response.data.notifyUser);
                    } else {
                        authService.setSecondFactor(true);
                        createNotificationShort(this.props.translate, {
                            message: this.props.translate('messages.success.settingSaved'),
                            type: 'success',
                        });
                    }
                }
            })
            .catch((e) => {
                if (this.props.modalData.onCancel) {
                    this.props.modalData.onCancel();
                }
                clearTimeout(this.state.apiTimeout);
                removeModal();
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('messages.error.couldNotLogin'),
                    type: 'error',
                });
            });
    };

    redirect(shouldNotifyUser) {
        getHistory().push('/');
        if (shouldNotifyUser) {
            this.props.notifyUserModal();
        }
    }

    onCancel = () => {
        if (this.props.modalData.onCancel) {
            this.props.modalData.onCancel();
        }
        clearTimeout(this.state.apiTimeout);
        this.props.removeModal();
    };

    render() {
        const { modalData, translate } = this.props;
        const modalProps = { width: '700px', ...this.props };
        const modalTitle = modalData.isLogin ? translate('login.title') : translate('modals.secondFactor.modalTitle');
        const modalDescription = modalData.isLogin
            ? translate('modals.loginQRCode')
            : translate('modals.settingQRCode');
        return (
            <ModalWrapper
                {...modalProps}
                title={modalTitle}
                width={400}
                bodyStyle={{ textAlign: 'center' }}
                onCancel={this.props.modalData.onCancel}
                closable={false}
                maskClosable={false}
            >
                <div>
                    <QRCode value={modalData.qrContent} size={150} />
                    <p>{modalDescription}</p>
                    <Button type="primary" block onClick={this.onCancel}>
                        {translate('buttons.cancel')}
                    </Button>
                </div>
            </ModalWrapper>
        );
    }
}

MobileLoginModal.propTypes = {
    actions: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
    modalData: PropTypes.object,
    notifyUserModal: PropTypes.func.isRequired,
    pollMobileLogin: PropTypes.func,
    pollingMobileLogin: PropTypes.func,
    removeModal: PropTypes.func,
    translate: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
        fetchSignatureProfiles: () => dispatch(fetchAllSignatureProfiles()),
        removeModal: () => dispatch(removeModal()),
        notifyUserModal: (result) => {
            if (result) {
                dispatch(toggleModal(NOTIFY_USER));
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileLoginModal);
