import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';
import { Button } from 'antd';

export default function DeleteButton(props) {
    const { children, disabled } = props;

    return (
        <Button
            {...props}
            className={'remove-focus-from-button'}
            icon={props.icon ? props.icon : 'delete'}
            style={{ borderColor: disabled ? theme.DANGER10 : theme.DANGER }}
            type={'danger'}>
            {children}
        </Button>
    );
}

DeleteButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    disabled: PropTypes.bool,
    icon: PropTypes.string
};
