import React from "react";
import { getActiveLanguage, getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { Collapse, Table } from "antd";
import PropTypes from "prop-types";
import { offsetDateToLocalComputer } from "../../utilities/dateUtilities";
import { mapObjectToArray } from "../../utilities/objectUtilities";
import { capitalizeFirstLetter } from "../../utilities/stringUtilities";

// The keys which will be displayed in expanded certificate properties table. The array also determines the order of rows in
// the table.
const DISPLAYED_CERTIFICATE_PROPERTY_KEYS = [
    "subject.commonName",
    "subject.organizationName",
    "notBefore",
    "notAfter",
    "signatureAlgorithmName",
    "signatureAlgorithmOid",
    "issuer.commonName",
    "issuer.organizationName",
    "fingerprint" ];

class CertificateProperties extends React.Component {
    filterDataSource = (element) => (DISPLAYED_CERTIFICATE_PROPERTY_KEYS.indexOf(element.key) >= 0);

    render() {
        const { activeLanguageCode, children, certificateProperties, showDetails, title, titleExtra, translate } = this.props;

        const columns = [
            {
                key: "key",
                dataIndex: "key",
                defaultSortOrder: "ascend",
                render: (value, row, index) =>
                    capitalizeFirstLetter(translate(`certificate.${value}`, null, { missingTranslationMsg: value })),
                sorter: (row1, row2) => {
                    const row1Index = DISPLAYED_CERTIFICATE_PROPERTY_KEYS.indexOf(row1.key);

                    if (row1Index < 0) {
                        return 1;
                    }

                    const row2Index = DISPLAYED_CERTIFICATE_PROPERTY_KEYS.indexOf(row2.key);

                    if (row2Index < 0) {
                        return -1;
                    }

                    return (row1Index - row2Index);
                },
                width: 200
            },
            {
                key: "value",
                dataIndex: "value",
                render: (value, row, index) => {
                    switch (row.key) {
                        case "notAfter":
                        case "notBefore":
                            const options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric", timeZone: "UTC" };

                            return value && offsetDateToLocalComputer(new Date(value)).toLocaleString(activeLanguageCode, options);

                        case "serialNumber":
                            // Decimális (de nem az alapértelmezett tudományos) alakban:
                            // return (value && value.toLocaleString("fullwide", { useGrouping: false }));

                            // Hexadecimális alakban:
                            return value && value.toString(16).padStart(26, '0');

                        case "version":
                            return value && `V${value}`;

                        default:
                            return value;
                    }
                }
            }
        ];

        return (
            <Collapse
                bordered={ false }
                defaultActiveKey={ [ (showDetails ? "certificate-properties" : undefined) ] }>
                <Collapse.Panel
                    key="certificate-properties"
                    header={ title || certificateProperties?.subject?.commonName }
                    extra={ titleExtra }>
                    <Table
                        columns={ columns }
                        dataSource={ mapObjectToArray(certificateProperties).filter(this.filterDataSource) }
                        pagination={ false }
                        showHeader={ false }
                        size={ "small" } />
                    { children }
                </Collapse.Panel>
            </Collapse>
        );
    }
}

CertificateProperties.propTypes = {
    activeLanguageCode: PropTypes.string.isRequired,
    certificateProperties: PropTypes.object.isRequired,
    showDetails: PropTypes.bool,
    title: PropTypes.string,
    titleExtra: PropTypes.object,
    translate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        activeLanguageCode: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale)
    };
}

export default connect(mapStateToProps)(CertificateProperties);
