import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Tag, Col, Row, Badge, Tooltip } from 'antd';
import theme from '../../theme';
import lawyerIcon from '../../images/lawyer.svg';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { isTimestamp } from '../../helper';

const getIconForSigType = (signature, color) => {
    if (signature.type === 'signature') {
        return (<Icon type='form' style={{ marginRight: 4, color: color }} />);
    } else if (signature.type === 'countersignature') {
        return (<img alt='' src={lawyerIcon} style={{ marginBottom: 4, width: 16, height: 16, color: color }} />);
    } else if (signature.type === 'timestamp') {
        return (<Icon type='clock-circle-o' style={{ marginRight: 4, color: color }} />);
    } else {
        return (<Icon type='question' style={{ marginRight: 4, color: color }} />);
    }
};

const SignatureTag = (props) => {
    let showBadgeForAttribute = props.signature.eSzignoAttributeCertificates !== null && props.signature.eSzignoAttributeCertificates.length > 0;
    const translate = useSelector((state) => getTranslate(state.locale));

    function getTypeName(signatureType) {
        switch (signatureType) {
            case 'bes':
                return translate("signature.tooltip.noTimestamp");
            case 't':
                return translate("signature.tooltip.timestamp");
            case "a":
                return translate("signature.tooltip.archiveTimestamp");
            default:
                return translate("signature.tooltip.timestamp");
        }
    }

    function getSignatory() {
        const signature = props.signature;

        if (signature.signerName) {
            return signature.signerName;
        }

        if (signature.subjectDN) {
            if (signature.subjectDN.CN) {
                return signature.subjectDN.CN;
            }
        }

        return undefined;
    }

    function getColor() {
        const signature = props.signature;

        if (isTimestamp(signature)) {
            return 'geekblue';
        }

        switch (signature.signatureLevel) {
            case 'a':
                return 'orange';

            case 'c':
                return 'green';

            case 't':
                 return 'blue';

            case 'x':
            case 'x-l':
                 return 'purple';

            case 'epes':
            default:
                return undefined;
        }
    }

    const generateTooltip = (signature) => {
        let signatureType = getTypeName(signature.signatureLevel);
        let isCountersign = signature.type !== null && signature.type === "countersignature" ?  translate("signature.tooltip.countersignature") : translate("signature.tooltip.signature");
        let isTimestamp = signature.type === "timestamp";
        let timestampName = signature.signatureLevel === "a" ?translate("buttons.archiveValidate.text") : translate("signature.tooltip.onlyTimestamp");
        let isAttribute = showBadgeForAttribute ? translate("signature.tooltip.attribute") : "";
        return isTimestamp ? timestampName : signatureType.concat(" ").concat(isCountersign).concat(" ").concat(isAttribute);
    };

    const tagComponent =
        <Tooltip placement='top' title={generateTooltip(props.signature)}>
            <Tag
                style = {{ marginBottom: 5, height: 22, cursor: 'pointer' }}
                onClick = { () => props.onOpenSignatureDetails() }
                color = { getColor() }>
                <Col>
                    <Row id={props.name}>
                        { getIconForSigType(props.signature, 'black') }
                        { getSignatory() }
                    </Row>
                    {props.signature.commentDocumentTitle && (
                        <Row>
                            <div
                                className='comment-document-title'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.downloadAttachment();
                                }}
                            >
                                <Icon type='link' style={{ fontSize: '12px', marginRight: '4px' }} />
                                {props.signature.commentDocumentTitle}
                            </div>
                        </Row>
                    )}
                </Col>
            </Tag>
        </Tooltip>;

    return (
        showBadgeForAttribute ?
            <Badge
                count = { <Icon type='tags' style={{ color: theme.textColor }} /> }
                offset = { [-10, 0] }
                title = { translate('attributeCertificate.tooltip') }>
                { tagComponent }
            </Badge>
            :
            (tagComponent)
    );
};

SignatureTag.propTypes = {
    signature: PropTypes.object,
    downloadAttachment: PropTypes.func,
    onOpenSignatureDetails: PropTypes.func
};

export default SignatureTag;
