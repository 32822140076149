import Logo from './Logo';
import React from 'react';
import ResponsiveNav from '../ResponsiveNav';
import theme from '../../theme';
import { Col, Layout, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getHistory, isOnLoginPage } from '../../helper';

export default function Header() {
    const history = getHistory();

    const isLoginPage = isOnLoginPage();
    const translate = useSelector((state) => getTranslate(state.locale));

    return (
        <Layout.Header
            className={ isLoginPage ? 'light' : 'dark' }
            style={{
                backgroundColor: isLoginPage ? theme.headerLight : theme.headerDark,
                borderTop: `4px solid ${theme.primaryColor}`,
                height: '76px',
                margin: '0px',
                overflowX: 'hidden',
                overflowY: 'hidden',
                padding: '0px',
                position: 'relative'
            }}>
            <Row
                align={'middle'}
                justify={'end'}
                style={{ height: '72px' }}
                type={'flex'}>
                <Col span={10}>
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                </Col>
                <Col
                    span={14}
                    style={{ textAlign: 'right' }}>
                    <ResponsiveNav
                        activeLinkKey={history.location.pathname}
                        lightStyle={isLoginPage}
                        placement={'bottomLeft'}
                        translate={translate}
                    />
                </Col>
            </Row>
        </Layout.Header>
    );
}
