import React from 'react';
import PropTypes from 'prop-types';
import { ReCaptcha } from 'react-recaptcha-google';

export default class InvisibleCaptcha extends React.Component {
    resetRecaptcha = () => {
        if (this.captcha) {
            this.captcha.reset();
            this.captcha.execute();
        }
    };

    componentDidMount() {
        this.resetRecaptcha();
    }

    onLoadRecaptcha = () => {
        this.resetRecaptcha();
    };

    render() {
        const { verifyCallback, shouldReset } = this.props;

        if (shouldReset) {
            this.resetRecaptcha();
        }

        return (
            <ReCaptcha
                ref={(el) => {
                    this.captcha = el;
                }}
                size="invisible"
                render="explicit"
                sitekey={window.config.REACT_APP_RECAPTCHA_SITE_KEY}
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={verifyCallback}
            />
        );
    }
}

InvisibleCaptcha.propTypes = {
    shouldReset: PropTypes.bool,
    verifyCallback: PropTypes.func.isRequired,
};
