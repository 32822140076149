import React from 'react';
import image from '../images/sign_image.png';
import { getTranslate } from 'react-localize-redux/lib/index';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

export class SignatureImageInfo extends React.Component {
    render() {
        const { translate } = this.props;
        return (
            <div>
                <p className="info">{translate('signature.image.info.title.1')}</p>
                <ul className="info">
                    <li className="info">
                        {translate('signature.image.info.1')} <b>PNG</b>
                    </li>
                    <li className="info">{translate('signature.image.info.2')}</li>
                    <li className="info">{translate('signature.image.info.3')}</li>
                    <li className="info">{translate('signature.image.info.4')}</li>
                </ul>
                <p className="info">{translate('signature.image.info.title.2')}</p>
                <ul className="info">
                    <li className="info">{translate('signature.image.info.5')}</li>
                    <li className="info">{translate('signature.image.info.6')}</li>
                    <li className="info">{translate('signature.image.info.7')}</li>
                    <li className="info">{translate('signature.image.info.8')}</li>
                </ul>
                <p className="info">{translate('signature.image.info.9')}</p>
                <div className="image" align="center">
                    <img className="info" src={image} alt={'PDF document'} draggable={false} />
                </div>
            </div>
        );
    }
}

SignatureImageInfo.propTypes = {
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

export default connect(mapStateToProps, null)(SignatureImageInfo);
