import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.modal, action) {
    switch (action.type) {
        case types.TOGGLE_MODAL:
            return { ...state, visible: !state.visible, name: action.name, data: action.data };
        case types.REMOVE_MODAL:
            return { ...state, visible: false, name: '', data: '' };
        default:
            return state;
    }
}
