import React from 'react';
import { renderToString } from 'react-dom/server';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Avatar, Button, Comment, Icon, Spin } from 'antd';
import PropTypes from 'prop-types';
import ESignNoClient from '../ESignNoClient';
import { ERROR_LEVEL_ERROR, ERROR_LEVEL_WARNING } from '../../constants/errorLevel';
import {
    isFinalESignState,
    E_SIGN_STATE_CANCELLED,
    E_SIGN_STATE_INTERNAL_AJAX_ERROR,
    E_SIGN_STATE_INTERNAL_ERROR,
    E_SIGN_STATE_WAITING_FOR_CONFIGURATION,
} from '../../constants/eSignState';
import {
    isPassByMeRelatedSignMode,
    SIGN_MODE_MICRO_SIGNER,
    SIGN_MODE_PUSH_NOTIFICATION,
} from '../../constants/signMode';
import { ajax } from '../../helper';

import eSzignoMobileIcon from '../../images/eszigno-full.svg';
import microSignerIcon from '../../images/microsigner.svg';
import passByMeIcon from '../../images/passbyme_v2.svg';

export const ERROR_CODE_E_SIGN_FAILURE = 'E_SIGN_FAILURE';
export const ERROR_CODE_MULTIPLE_SESSIONS = 'MULTIPLE_SESSIONS';
export const ERROR_CODE_REQUEST_FAILURE = 'REQUEST_FAILURE';

export const E_SIGN_STATE_STARTING = 'STARTING';
export const E_SIGN_STATE_FINALIZING = 'FINALIZING';

export function isInternalESignState(eSignState) {
    return eSignState === E_SIGN_STATE_STARTING || eSignState === E_SIGN_STATE_FINALIZING;
}

const E_SIGN_LINK_HTML_COMPONENT_ID = 'a_esign';
const E_SIGN_IMAGE_HTML_COMPONENT_ID = 'img_esign';

class CertificateSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eSignState: undefined,
            isCancelled: false,
            showClientRecommendation: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.autoStart) {
            this.handleCertificateSelect();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        const { eSignState } = this.state;

        if (this.isActiveSession(eSignState)) {
            if (!isInternalESignState(eSignState)) {
                this.cancelESignSession();
            }

            this.releaseESignObjects();
        }
    }

    isActiveSession = (eSignState) => {
        return eSignState && !isFinalESignState(eSignState);
    };

    setCancelled = () => {
        this._isMounted && this.setState({ isCancelled: true });
    };

    setESignState = (eSignState) => {
        this._isMounted && this.setState({ eSignState: eSignState });
    };

    setShowClientRecommendation = (showClientRecommendation) => {
        this._isMounted && this.setState({ showClientRecommendation: showClientRecommendation });
    };

    resolveDetailMessageKeyPostfix = () => {
        const { eSignState } = this.state;

        if (eSignState === E_SIGN_STATE_WAITING_FOR_CONFIGURATION) {
            const { signMode } = this.props;

            return `${eSignState}.${signMode}`;
        }

        return eSignState;
    };

    reset = (releaseESignObjects, finalESignState) => {
        if (releaseESignObjects) {
            this.releaseESignObjects();
        }

        this._isMounted &&
            this.setState({ isCancelled: false, showClientRecommendation: false, eSignState: finalESignState });
    };

    releaseESignObjects = () => {
        window.esignstate.onStateESignFailure = undefined;
        window.esignstate.onStateAJAXFailure = undefined;
        window.esignstate.onStateGot = undefined;
    };

    cancelESignSession = () => {
        window.esignstate.cancel();
    };

    handleCertificateSelect = () => {
        const { activeLanguageCode, eSignMessage, signMode, translate, onCertificateSelectError } = this.props;

        if (this.isActiveSession(this.state.eSignState)) {
            console.warn('Certificate selection is already in progress.');

            if (onCertificateSelectError) {
                onCertificateSelectError(ERROR_LEVEL_WARNING, ERROR_CODE_MULTIPLE_SESSIONS);
            }

            return false;
        }

        this.setESignState(E_SIGN_STATE_STARTING);

        ajax()
            .post('/certificate/startSelect', {
                languageCode: activeLanguageCode,
                serviceName: translate('applicationName', null, { missingTranslationMsg: 'web-Szignó' }),
                message:
                    eSignMessage ||
                    translate('esign.message.certificateSelect', null, {
                        missingTranslationMsg: 'Please select a certificate.',
                    }),
                signMode: signMode,
            })
            .then((response) => {
                const sessionId = response.data.sessionId;

                window.esignstate.onStateESignFailure = (errorObject) => {
                    // Az errorObject használhatatlan, mert eseményenként eltérő típussal bír.

                    if (errorObject.code === E_SIGN_STATE_CANCELLED) {
                        console.info(`Select certificate session '${sessionId}' has cancelled.`);

                        this.reset(true);
                    } else {
                        console.error(
                            `Unable to select certificate in session '${sessionId}' because of an e-Sign error.`
                        );

                        this.reset(true, E_SIGN_STATE_INTERNAL_ERROR);

                        if (onCertificateSelectError) {
                            onCertificateSelectError(ERROR_LEVEL_ERROR, ERROR_CODE_E_SIGN_FAILURE);
                        }
                    }
                    return false;
                };

                window.esignstate.onStateAJAXFailure = (xhr, statusText) => {
                    console.error(
                        `Unable to select certificate in session '${sessionId}' because of an internal AJAX error: ${statusText}.`
                    );

                    this.reset(true, E_SIGN_STATE_INTERNAL_AJAX_ERROR);

                    if (onCertificateSelectError) {
                        onCertificateSelectError(ERROR_LEVEL_ERROR, ERROR_CODE_E_SIGN_FAILURE);
                    }

                    return false;
                };

                window.esignstate.onStateGot = (counter, state) => {
                    const newESignState = state.code;

                    this.setESignState(newESignState);

                    this.setShowClientRecommendation(
                        newESignState === E_SIGN_STATE_WAITING_FOR_CONFIGURATION && counter >= 10
                    );

                    return true;
                };

                window.esignstate.startAndListen(
                    response.data,
                    isPassByMeRelatedSignMode(signMode)
                        ? E_SIGN_IMAGE_HTML_COMPONENT_ID
                        : E_SIGN_LINK_HTML_COMPONENT_ID,
                    () => {
                        console.info(`Certificate select session '${sessionId}' has finished.`);

                        this.setESignState(E_SIGN_STATE_FINALIZING);

                        this.props.onCertificateSelected(sessionId, response.data.sessionUrl);

                        this.reset(true);

                        console.info(`Certificate has selected.`);
                    }
                );

                if (this.state.isCancelled) {
                    this.cancelESignSession();
                }
            })
            .catch((error) => {
                console.error(`Unable to select certificate: ${error.message}.`);
                this.reset(false);

                if (onCertificateSelectError) {
                    onCertificateSelectError(ERROR_LEVEL_ERROR, ERROR_CODE_REQUEST_FAILURE);
                }
            });
    };

    handleCancel = () => {
        const { eSignState } = this.state;

        if (this.isActiveSession(eSignState)) {
            this.setCancelled();

            if (!isInternalESignState(eSignState)) {
                this.cancelESignSession();
            }

            return true;
        }

        return false;
    };

    render() {
        const { showDetails, signMode, text, title, translate } = this.props;
        const { eSignState, isCancelled, showClientRecommendation } = this.state;

        const isActiveSession = this.isActiveSession(eSignState);
        const isPassByMeRelated = isPassByMeRelatedSignMode(signMode);

        function onMissingTranslation(key, languageCode) {
            console.warn(`Translation is missing with ID '${key}' (language code: ${languageCode}).`);

            return key;
        }

        return (
            <React.Fragment>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        id={'selectCertificate'}
                        disabled={isActiveSession}
                        icon={'edit'}
                        title={
                            title ||
                            translate('hint.certificateSelect', null, { missingTranslationMsg: 'Select certificate' })
                        }
                        onClick={this.handleCertificateSelect}
                    >
                        {text ||
                            translate('button.certificateSelect', null, {
                                missingTranslationMsg: 'Select certificate',
                            })}
                        {isActiveSession && (
                            <span style={{ paddingLeft: 10 }}>
                                <Spin indicator={<Icon type="loading" style={{ fontSize: 14 }} spin />} />
                            </span>
                        )}
                    </Button>

                    {isActiveSession && (
                        <Button type="primary" onClick={this.handleCancel}>
                            {translate('buttons.cancel')}
                            {isCancelled && (
                                <span style={{ paddingLeft: 10 }}>
                                    <Spin indicator={<Icon type="loading" style={{ fontSize: 14 }} spin />} />
                                </span>
                            )}
                        </Button>
                    )}
                </div>

                {isPassByMeRelated &&
                    (eSignState === E_SIGN_STATE_STARTING || eSignState === E_SIGN_STATE_WAITING_FOR_CONFIGURATION) && (
                        <div style={{ textAlign: 'center' }}>
                            <img
                                id={E_SIGN_IMAGE_HTML_COMPONENT_ID}
                                alt={translate('hint.certificateSelectUsingQrCode', null, {
                                    missingTranslationMsg: 'Select certificate using QR code',
                                })}
                                style={{
                                    display:
                                        eSignState === E_SIGN_STATE_STARTING || signMode === SIGN_MODE_PUSH_NOTIFICATION
                                            ? 'none'
                                            : undefined,
                                    padding: '10px',
                                }}
                            />
                        </div>
                    )}

                {showDetails && eSignState && (
                    <React.Fragment>
                        <Comment
                            content={translate(
                                `esign.state.${this.resolveDetailMessageKeyPostfix()}`,
                                {
                                    eSzignoMobileAvatar: renderToString(
                                        <Avatar
                                            size={20}
                                            src={eSzignoMobileIcon}
                                            style={{ marginLeft: 4, marginRight: 4 }}
                                            shape="square"
                                        />
                                    ),
                                    microSignerAvatar: renderToString(
                                        <Avatar
                                            size={20}
                                            src={microSignerIcon}
                                            style={{ marginLeft: 4, marginRight: 4 }}
                                            shape="square"
                                        />
                                    ),
                                    passByMeAvatar: renderToString(
                                        <Avatar
                                            size={20}
                                            src={passByMeIcon}
                                            style={{ marginLeft: 4, marginRight: 4 }}
                                            shape="square"
                                        />
                                    ),
                                },
                                {
                                    missingTranslationCallback: onMissingTranslation,
                                    renderInnerHtml: true,
                                    showMissingTranslationMsg: false,
                                }
                            )}
                        />

                        <Comment
                            content={
                                <ESignNoClient
                                    visible={showClientRecommendation}
                                    signWithPBM={isPassByMeRelated}
                                    translate={translate}
                                />
                            }
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

CertificateSelect.propTypes = {
    activeLanguageCode: PropTypes.string.isRequired,
    eSignMessage: PropTypes.string,
    signMode: PropTypes.string.isRequired,
    showDetails: PropTypes.bool,
    text: PropTypes.string,
    title: PropTypes.string,
    translate: PropTypes.func.isRequired,
};

CertificateSelect.defaultProps = {
    signMode: SIGN_MODE_MICRO_SIGNER,
};

function mapStateToProps(state) {
    return {
        activeLanguageCode: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

export default connect(mapStateToProps)(CertificateSelect);
