import PropTypes from 'prop-types';
import React from 'react';
import { Modal, Collapse, List } from 'antd';
import { formatDate } from '../../helper';

const DeleteUserErrorDetails = ({ errors, translate }) => {
    const waitingToSignByOthersErrorCode = 'USER_DELETE_HAS_FILES_WAITING_TO_BE_SIGNED_BY_OTHERS';
    const waitingToSignErrorCode = 'USER_DELETE_HAS_FILES_WAITING_TO_BE_SIGNED';
    let panels = errors.map((error, i) => {
        let content = error.deleteUserErrorDetails.map((errorDetail) => {
            if (
                errorDetail.errorMessageCode === waitingToSignByOthersErrorCode ||
                errorDetail.errorMessageCode === waitingToSignErrorCode
            ) {
                return (
                    <div>
                        <p>{translate('errorCodes.' + errorDetail.errorMessageCode)}</p>
                        <h4>{translate('waitingtosignbyothers.details.title')}</h4>
                        <ul>
                            <li>{`${translate('home.title')}: ${errorDetail.fileName}`}</li>
                            <li>{`${translate('attributes.createdBy')}: ${errorDetail.createdByEmail}`}</li>
                            <li>{`${translate('attributes.creationDate')}: ${formatDate(
                                errorDetail.creationDate,
                                translate('language')
                            )}`}</li>
                            <li>{`ContainerId: ${errorDetail.containerSessionId}`}</li>
                        </ul>
                    </div>
                );
            } else {
                return (
                    <div>
                        <p>{`${translate('errorCodes.' + errorDetail.errorMessageCode)}`}</p>
                        <ul>
                            <li>{errorDetail.errorDetails}</li>
                        </ul>
                    </div>
                );
            }
        });
        return (
            <Collapse.Panel header={error.email} key={i}>
                <List bordered dataSource={content} renderItem={(item) => <List.Item>{item}</List.Item>} />
            </Collapse.Panel>
        );
    });

    return (
        <div style={{ maxHeight: '60vh', overflow: 'auto', paddingTop: '1rem' }}>
            <Collapse>{panels}</Collapse>
        </div>
    );
};

DeleteUserErrorDetails.propTypes = {
    errors: PropTypes.array,
    translate: PropTypes.func,
};

export const showErrorDetailsModal = (errors, translate) => {
    Modal.error({
        title: translate('adminPage.users.deletionErrorModalTitle'),
        maskClosable: true,
        width: '80%',
        content: <DeleteUserErrorDetails errors={errors} translate={translate} />,
    });
};
