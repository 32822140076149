import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function (state = initialState.enabled, action) {
    switch (action.type) {
        case types.DISABLE_ARCHIVE_BUTTON:
            return { ...state, archiveButton: false };
        case types.ENABLE_ARCHIVE_BUTTON:
            return { ...state, archiveButton: true };
        case types.RESET_CHECKBOX_ENABLE:
            return { ...state, checkbox: false };
        case types.RESET_ENABLED:
            return { ...state, checkbox: false, archiveButton: true };
        case types.TOGGLE_CHECKBOX:
            return { ...state, checkbox: !state.checkbox };
        default:
            return state;
    }
}
