import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../theme';
import { Badge } from 'antd';

function DocumentIcon(props) {
    let backgroundColor = theme.DEFAULT;
    let badge = null;
    let text = '-';
    let textColor = theme.WHITEEE;

    switch (props.type) {
        case 'asic':
            backgroundColor = theme.SECONDARY;
            text = 'ASiC';
            textColor = theme.WHITEEE;
            break;
        case 'es3':
        case 'et3':
            backgroundColor = theme.BRAND;
            badge = props.type;
            text = 'e-akta';
            textColor = theme.DEFAULT;
            break;
        case 'pdf':
            backgroundColor = theme.DANGER;
            text = 'PDF';
            textColor = theme.WHITEEE;
            break;
        default:
            break;
    }

    return (
        <Badge
            count={badge}
            style={{ backgroundColor: theme.DANGER, borderColor: theme.DANGER, color: theme.WHITEEE }}>
            <div
                style={{
                    background: backgroundColor,
                    borderRadius: 4,
                    color: textColor,
                    fontSize: '20px',
                    fontWeight: 'bolder',
                    height: '32px',
                    lineHeight: '29px',
                    textAlign: 'center',
                    width: '80px'
                }}>
                <p>{text}</p>
            </div>
        </Badge>
    );
}

DocumentIcon.propTypes = {
    type: PropTypes.string
};

export default DocumentIcon;
