import React from 'react';
import { Button } from 'antd';
import googleIcon from '../../images/googleIcon.svg';
import { getTranslate } from 'react-localize-redux/lib/index';
import { useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { createNotificationShort } from '../../helper';


const GoogleAuthButton = (props) => {
    const translate = useSelector((state) => getTranslate(state.locale));

    const getAccessToken = useGoogleLogin({
        onSuccess: credentialResponse  => {
            return props.googleAuth(credentialResponse.access_token)
        },
        onError: () => {            
            createNotificationShort(translate, {
                message: translate('notifications.login.failure.google'),
                type: 'error'
            });
        }
    });    

    const socialMediaIconsStyle = {
        float: 'left',
        width: '20px',
        height: '20px'
    };

    return (
        <Button
            block={true}
            className={'google-login'}
            //disabled={renderProps.disabled}
            onClick={() => getAccessToken()}>
            <img
                style={socialMediaIconsStyle}
                src={googleIcon}
                alt={'google icon'}
            />
            {translate('buttons.googleLogin')}
        </Button>
    )
}

export default GoogleAuthButton;