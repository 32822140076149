import { Modal } from 'antd';

export const confirmationModal = (translate, onOkFunction, title) =>
    Modal.confirm({
        title: title,
        cancelText: translate('buttons.cancel'),
        onCancel: () => Modal.destroyAll(),
        okText: translate('buttons.yes'),
        maskClosable: true,
        onOk: onOkFunction,
    });

export const confirmationModalWithCancel = (translate, onOkFunction, onCancelFunction, title) =>
    Modal.confirm({
            title: title,
            cancelText: translate('buttons.cancel'),
            onCancel: onCancelFunction,
            okText: translate('buttons.yes'),
            maskClosable: true,
            onOk: onOkFunction,
    });