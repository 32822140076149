/**
 * A megadott szöveg esetleges első betűjének lecserélése annak nagybetűs változatára.
 *
 * @param string A szöveg.
 * @return A szöveg, aminek esetleges első betűje nagybetűs.
 */
export function capitalizeFirstLetter(string) {
    if (!string || string.length === 0) {
        return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isBlank(string) {
    return string === undefined || string === null || string.length === 0 || string.trim().length === 0;
}

export function equals(string, value) {
    return !isBlank(string) && string === value;
}

export function shorten(string, length) {
    if (string.length < length) {
        return string;
    }
    return string.slice(0, length).concat('...');
}
