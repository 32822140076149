import initialState from './initialState';
import { DELETE_WORKFLOW_TEMPLATE, FETCH_WORKFLOW_TEMPLATES, UPDATE_WORKFLOW_TEMPLATE } from '../actions/actionTypes';

export default function (state = initialState.workflowTemplates, action) {
    switch (action.type) {
        case FETCH_WORKFLOW_TEMPLATES:
            const elements = action.payload.map((value) => value);
            return [...elements];
        case UPDATE_WORKFLOW_TEMPLATE:
            let old = state;
            let updated = [];
            old.forEach((template) =>{
                if(template.templateGuid === action.payload.templateGuid){
                    updated.push(action.payload)
                }
                else{
                    updated.push(template)
                }
            })

            return [...updated];
        case DELETE_WORKFLOW_TEMPLATE:
            return state.filter((template) => template.templateGuid !== action.payload);
        default:
            return state;
    }
}
