import FormItem from '../../form/FormItem';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import {WORKFLOW_MESSAGE_MAX_LENGTH} from '../../../constants/config';
import {Button, Form, Input} from 'antd';
import {createNotification, createNotificationShort} from '../../../helper';
import {getTranslate} from 'react-localize-redux';
import {getContainersInSignatureProcess, refuseSigning, updateSelectedContainerList} from '../../../actions/container';
import {removeModal} from '../../../actions/modal';
import {useDispatch, useSelector} from 'react-redux';

function RefuseSignatureModal(props) {
    const { form, modalData } = props;

    const translate = useSelector((state) => getTranslate(state.locale));

    const dispatch = useDispatch();

    function submitForm(e) {
        e.preventDefault();
        form.validateFieldsAndScroll((error, values) => {
            if (!error) {
                const refusedContainers = modalData.containerId.map((id) => {
                    return { containerId: id, reason: values.reason };
                });
                dispatch(refuseSigning(refusedContainers))
                    .then(() => {
                        window.config.REACT_APP_WORKFLOW_ROLES_ENABLED && dispatch(getContainersInSignatureProcess());
                        dispatch(getContainersInSignatureProcess());

                        dispatch(updateSelectedContainerList(modalData.newActiveContainerId));
                        createNotificationShort(translate, {
                            message: translate('notifications.refuseSignature.success'),
                            type: 'success'
                        });
                    })
                    .catch((e) => {
                        createNotification(translate, e.response.data.code, {
                            message: translate('notifications.refuseSignature.failure'),
                            type: 'error'
                        });
                    })
                    .finally(() => {
                        dispatch(removeModal());
                    });
            }
        });
    }

    let reason = localStorage.getItem('refuseSignatureReason');
    if (!reason || reason === 'undefined') {
        reason = '';
    }

    return (
        <ModalWrapper
            {...props}
            onCancel={() => {
                localStorage.setItem('refuseSignatureReason', form.getFieldValue('reason'));
            }}
            title={<b>{translate(`modals.refuseSignature.title`)}</b>}>
            <Form onSubmit={submitForm}>
                <p>{translate(`modals.refuseSignature.description`)}</p>
                <FormItem>
                    {form.getFieldDecorator('reason', {
                        initialValue: reason,
                        rules: [
                            {
                                max: WORKFLOW_MESSAGE_MAX_LENGTH,
                                message: translate('form.text.tooLong', { length: WORKFLOW_MESSAGE_MAX_LENGTH })
                            }
                        ]
                    })(
                        <Input.TextArea
                            autoFocus={true}
                            maxLength={WORKFLOW_MESSAGE_MAX_LENGTH}
                            rows={4}
                        />
                    )}
                </FormItem>
                <FormItem formItemProps={{ style: { marginBottom: 0 } }}>
                    <Button
                        block={true}
                        htmlType={'submit'}
                        id={'refuse-signature-button'}
                        type={'primary'}>
                        {translate('modals.refuseSignature.button')}
                    </Button>
                </FormItem>
            </Form>
        </ModalWrapper>
    );
}

RefuseSignatureModal.propTypes = {
    form: PropTypes.object.isRequired,
    modalData: PropTypes.shape({
        containerId: PropTypes.array.isRequired,
        newActiveContainerId: PropTypes.array.isRequired
    })
};

const WrappedRefuseSignatureModal = Form.create({ name: 'refuse-signature-modal' })(RefuseSignatureModal);
export default WrappedRefuseSignatureModal;
