import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { removeModal } from '../../actions/modal';

class MetadataModal extends React.Component {
    getColumns = (translate) => [
        {
            align: 'center',
            dataIndex: 'keyelement',
            key: 'keyelement',
            title: translate('modals.sendForSignature.metadata.key')
        },
        {
            align: 'center',
            dataIndex: 'value',
            ellipsis: true,
            key: 'value',
            title: translate('modals.sendForSignature.metadata.value')
        }
    ];

    render() {
        const { translate } = this.props;
        const columns = this.getColumns(translate);
        const modalProps = { width: '700px', ...this.props };
        const formattedMetaData = Object.entries(modalProps.modalData);
        return (
            <ModalWrapper
                {...modalProps}
                bodyStyle={{ textAlign: 'center' }}
                title={translate('titles.metaData')}
                width={600}>
                <Table
                    bordered={true}
                    columns={columns}
                    dataSource={formattedMetaData.map((d) => ({
                        key: d[0],
                        keyelement: d[0],
                        value: d[1]
                    }))}
                    locale={{ emptyText: translate('modals.metadata.empty') }}
                    pagination={{
                        defaultPageSize: 10
                    }}
                    size={'small'}
                />
            </ModalWrapper>
        );
    }
}

MetadataModal.propTypes = {
    actions: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
    modalData: PropTypes.object,
    removeModal: PropTypes.func,
    translate: PropTypes.func
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeModal: () => dispatch(removeModal())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetadataModal);
