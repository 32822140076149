import PropTypes from 'prop-types';
import React from 'react';
import { FILENAME_MAX_LENGTH, FILENAME_MIN_LENGTH } from '../../constants/config';
import { Form, Input } from 'antd';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

export default function FilenameInput(props) {
    const { defaultFilename, form } = props;

    const translate = useSelector((state) => getTranslate(state.locale));

    return (
        <Form.Item
            hasFeedback={true}
            label={translate('modals.createContainer.label.filename')}>
            {form.getFieldDecorator('filename', {
                initialValue: defaultFilename ? defaultFilename : '',
                rules: [
                    { message: translate('form.required'), required: true },
                    {
                        max: FILENAME_MAX_LENGTH,
                        message: translate('form.filename.tooLong', { length: FILENAME_MAX_LENGTH })
                    },
                    {
                        min: FILENAME_MIN_LENGTH,
                        message: translate('form.filename.tooShort', { length: FILENAME_MIN_LENGTH })
                    }
                ],
                validateTrigger: ['onBlur', 'onChange']
            })(<Input />)}
        </Form.Item>
    );
}

FilenameInput.propTypes = {
    defaultFilename: PropTypes.string,
    form: PropTypes.object.isRequired
};
