/* eslint-disable no-unused-vars */

// Main colors
const BRAND = '#FFCB00';
const BRAND_DISABLED = '#FFF0C2';
const BRAND_HOVER = '#CC9900';
const BRAND_PRESSED = '#FFD600';
const DEFAULT = '#0D121C';
const DISABLED = '#EEEEEE';
const MAIN = '#3D4149';
const NEUTRAL = '#B6B8BB';
const NORMAL = '#72767E';
const WHITEEE = '#FDFDFD';

// Complementary colors
const DANGER = '#C21F1F';
const DANGER50 = '#E56161';
const DANGER30 = '#F1A7A7';
const DANGER10 = '#F9DCDC';
const SECONDARY = '#0278D5';
const SECONDARY50 = '#55AECE';
const SECONDARY30 = '#A1DAF8';
const SECONDARY10 = '#ECF7FD';
const SUCCESS = '#95C11F';
const SUCCESS50 = '#D0EB84';
const SUCCESS30 = '#F1F9DC';
const SUCCESS10 = '#F4F8F1';
const WARNING = '#E87706';
const WARNING50 = '#FBAD60';
const WARNING30 = '#FDD6AF';
const WARNING10 = '#FEEBD7';

module.exports = {
    defaultColor: WHITEEE,
    primaryColor: BRAND,
    textColor: MAIN,
    textColorLight: WHITEEE,
    modalTextHighlightColor: '#e6e6e6',
    headerDark: MAIN,
    headerLight: WHITEEE,
    documentListBg: WHITEEE,
    boxBgColor: WHITEEE,
    dropZoneStyle: {
        border: '2px dashed rgb(127, 150, 237)',
        borderRadius: '4px',
        opacity: 0.7,
        minHeight: 300,
        position: 'relative'
    },
    dropZoneOverlayBoxStyle: {
        borderRadius: '4px',
        position: 'absolute',
        display: 'flex',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flex: 1,
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: DEFAULT,
        margin: 'auto',
        opacity: 0.9,
        padding: 10
    },
    successColor: SUCCESS,
    errorColor: DANGER,
    warningColor: WARNING,

    buttonDefaultBgColor: WHITEEE,
    buttonDisabledBgColor: BRAND_DISABLED,
    buttonDisabledBorderColor: BRAND_PRESSED,
    buttonDisabledTextColor: NEUTRAL,

    BRAND,
    BRAND_DISABLED,
    BRAND_HOVER,
    BRAND_PRESSED,
    DEFAULT,
    DISABLED,
    MAIN,
    NEUTRAL,
    NORMAL,
    WHITEEE,

    DANGER,
    DANGER50,
    DANGER30,
    DANGER10,
    SECONDARY,
    SECONDARY50,
    SECONDARY30,
    SECONDARY10,
    SUCCESS,
    SUCCESS50,
    SUCCESS30,
    SUCCESS10,
    WARNING,
    WARNING50,
    WARNING30,
    WARNING10,

    // Redefine Antd colors
    antd: {
        // Colors
        'disabled-bg': DISABLED,
        'disabled-color': NEUTRAL,
        'error-color': DANGER,
        'highlight-color': DANGER,
        'info-color': SECONDARY,
        'primary-color': BRAND,
        'processing-color': SECONDARY,
        'shadow-color': NEUTRAL,
        'shadow-color-inverse': NEUTRAL,
        'success-color': SUCCESS,
        'text-color': MAIN,
        'warning-color': WARNING,
        // Avatar
        'avatar-bg': BRAND,
        'avatar-color': DEFAULT,
        // Button
        'btn-danger-bg': WHITEEE,
        'btn-danger-border': DANGER,
        'btn-danger-color': DANGER,
        'btn-default-bg': WHITEEE,
        'btn-default-border': NEUTRAL,
        'btn-default-color': DEFAULT,
        'btn-disable-bg': DISABLED,
        'btn-disable-border': NEUTRAL,
        'btn-disable-color': NEUTRAL,
        'btn-primary-bg': BRAND,
        'btn-primary-border': NEUTRAL,
        'btn-primary-color': DEFAULT,
        'btn-height-base': '40px',
        // Checkbox
        'checkbox-check-color': WHITEEE,
        'checkbox-color': WARNING,
        // Form
        'form-item-margin-bottom': '12px',
        'form-warning-input-bg': WARNING30,
        // Input
        'input-height-base': '40px',
        // Link
        'link-color': WARNING,
        'link-hover-color': WARNING50,
        // Menu
        'menu-dark-bg': MAIN,
        'menu-dark-item-active-bg': 'transparent',
        // Progress
        'progress-default-color': WHITEEE,
        // Radio
        'radio-dot-color': WARNING,
        // Table
        'table-row-hover-bg': BRAND_DISABLED,
        'table-selected-row-bg': BRAND,
        'table-selected-row-color': DEFAULT,
        'table-selected-row-hover-bg': BRAND_PRESSED,
        // Tabs
        'tabs-active-color': DEFAULT,
        'tabs-card-active-color': DEFAULT,
        'tabs-hover-color': BRAND,
        'tabs-highlight-color': DEFAULT
    }
};
