import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import { Button, notification } from 'antd';
import { resendConfirmation } from '../actions/user';
import { connect } from 'react-redux';
import { createNotification, createNotificationShort } from '../helper';
import { getTranslate } from 'react-localize-redux/lib/index';

export class ResendConfirmation extends React.Component {
    resendConfirmation(email) {
        ReactGA.event({
            category: 'User',
            action: 'Resend Confirmation Email',
        });
        this.props
            .resendConfirmationAction(email)
            .then(() => {
                notification.destroy();
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('notifications.sendInEmail.success'),
                    type: 'success',
                });
            })
            .catch((e) => {
                notification.destroy();
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('notifications.sendInEmail.failure'),
                    type: 'error',
                });
            });
    }

    render() {
        const { email, translate } = this.props;
        return (
            <Button onClick={() => this.resendConfirmation(email)}>
                {translate('login.resendConfirmEmail')}
            </Button>
        );
    }
}

ResendConfirmation.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    resendConfirmationAction: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resendConfirmationAction: (email, lang) => {
            return dispatch(resendConfirmation(email, lang));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmation);
