/**
 * Decides if the given variable is a non null object.
 *
 * @param variable The variable.
 * @return {boolean} Returns {@code true} if the given variable is a non null object.
 */
export function isObject(variable) {
    return (
        variable !== null
        && typeof variable === "object"
        && !Array.isArray(variable));
}

/**
 * Collects all fields of an object into an array. The elements of the result array are objects with {@code key} and
 * {@code value} pairs. If the given object contains field that is object too, fields of that will be present with key
 * prefixed with key of the object.
 *
 * @param object The object to be converted to an array.
 * @param keyPrefix The prefix which will be used at creating {@code key} fields of elements of the result array.
 * @param innerObjectKeySeparator The separator which will be used at creating {@code key} fields of elements of the result
 * array.
 * @return {null | []} The array containing {@code key} and {@code value} pairs based of fields of the given object.
 */
export function mapObjectToArray(object, keyPrefix = "", innerObjectKeySeparator = ".") {
    let array = [];

    if (object !== null) {
        Object.keys(object).forEach((key) => {
            const newKey = keyPrefix + key;
            const value = object[key];

            if (isObject(value)) {
                array = array.concat(mapObjectToArray(value, newKey + "."));
            } else {
                array.push({
                    key: newKey,
                    value: value
                });
            }
        });
    }

    return array;
}
