export const NEW_CONTAINER_SUCCESS = 'new_container_success';
export const GET_CONTAINERS_SUCCESS = 'get_containers_success';
export const GET_CONTAINERS_FAILURE = 'get_container_failure';
export const GET_CONTAINER_DOCUMENTS_FAILURE = 'get_container_documents_failure';
export const GET_CONTAINER_DOCUMENTS_SUCCESS = 'get_container_documents_success';
export const GET_CONTAINER_SIGNATURES_FAILURE = 'get_container_signatures_failure';
export const GET_CONTAINER_SIGNATURES_SUCCESS = 'get_container_signatures_success';
export const UPLOAD_CONTAINER_SUCCESS = 'upload_container_success';
export const DELETE_CONTAINER_SUCCESS = 'delete_container_success';
export const UPLOAD_DOCUMENT_SUCCESS = 'upload_document_success';
export const VALIDATE_SIGNATURE_FAILURE = 'validate_signature_failure';
export const VALIDATE_SIGNATURE_SUCCESS = 'validate_signature_success';
export const RENAME_CONTAINER_SUCCESS = 'rename_container_success';
export const RENAME_DOCUMENT_SUCCESS = 'rename_document_success';
export const ARCHIVE_SIGNATURE_SUCCESS = 'archive_signature_success';
export const DELETE_SIGNATURE_SUCCESS = 'delete_signature_success';
export const GET_DOCUMENT_SIGNATURES_SUCCESS = 'get_document_signatures_success';
export const GET_DOCUMENT_SIGNATURES_FAILURE = 'get_document_signatures_failure';
export const CHECK_TSP_SUCCESS = 'check_tsp_success';
export const CHECK_TSP_FAILURE = 'check_tsp_failure';
export const TSP_CLEARED = 'tsp_cleared';
export const FETCH_DOCUMENT_DELETE_SUCCESS = 'success_document_delete_call';
export const TOGGLE_MODAL = 'toggle_modal';
export const REMOVE_MODAL = 'remove_modal';
export const RESET_DOCUMENT_LIST = 'reset_document_list';
export const RESET_CONTAINER_LIST = 'reset_container_list';
export const SET_SELECTED_CONTAINERS = 'set_selected_containers';
export const UPDATE_SELECTED_CONTAINER_IDS = 'update_selected_container_ids';
export const RESET_SELECTED_CONTAINER_IDS = 'reset_selected_container_ids';
export const GET_CONTAINERS_IN_SIGNATURE_PROCESS_SUCCESS = 'get_containers_in_signature_process_success';
export const GET_CONTAINERS_IN_SIGNATURE_PROCESS_FAILURE = 'get_containers_in_signature_process_failure';
export const TOGGLE_CHECKBOX = 'toggle-multiple-select-checkbox';
export const RESET_CHECKBOX_ENABLE = 'reset-multiple-select-checkbox';
export const GET_DEPUTY_SUCCESS = 'get-deputy-success';
export const GET_DEPUTY_FAILURE = 'get-deputy-failure';
export const DEPUTY_RESET = 'get-deputy-failure';
export const FETCH_SIGNATURE_PROFILE = 'fetch-signature-profile';
export const SET_ISPASSWORD = 'set_ispassword_true';
export const RESET_ISPASSWORD = 'set_ispassword_false';
export const ENABLE_ARCHIVE_BUTTON = 'enable_archive_button';
export const DISABLE_ARCHIVE_BUTTON = 'disable_archive_button';
export const STORE_FILE_ID = 'store_file_id';
export const DELETE_STORED_FILE_ID = 'delete_stored_file_id';
export const UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS = 'update_waiting_to_be_signed_by_others';
export const UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_BY_CONTAINER = 'update_waiting_to_be_signed_by_others_by_container';
export const ADD_SIGNATURE_PROFILE = 'add_signature_profile';
export const DELETE_SIGNATURE_PROFILE = 'delete_signature_profile';
export const UPDATE_SIGNATURE_PROFILE = 'update_signature_profile';
export const RESET_SIGNATURE_PROFILE = 'reset_signature_profile';
export const TOGGLE_DEFAULT_SIGNATURE_PROFILE = 'toggle_default_signature_profile';
export const REFRESH_SIGNATURE_PROFILE = 'refresh_signature_profile';
export const CREATE_FREQUENTLY_USED_EMAIL = 'create_frequently_used_email';
export const DELETE_FREQUENTLY_USED_EMAIL = 'delete_frequently_used_email';
export const UPDATE_FREQUENTLY_USED_EMAIL = 'update_frequently_used_email';
export const REFRESH_FREQUENTLY_USED_EMAIL = 'refresh_frequently_used_email';
export const GET_DOCUMENT_TITLES_SUCCESS = 'get_document_titles_success';
export const SELECT_ALL_CONTAINER = 'select_all_container';
export const SET_CURRENT_TAB = 'set_current_tab';
export const SORT_CONTAINER_LIST_BY_DATE_ASC = 'sort_container_list_by_date_asc';
export const SORT_CONTAINER_LIST_BY_DATE_DESC = 'sort_container_list_by_date_desc';
export const SORT_CONTAINER_LIST_BY_NAME_ASC = 'sort_container_list_by_name_asc';
export const SORT_CONTAINER_LIST_BY_NAME_DESC = 'sort_container_list_by_name_desc';
export const RESET_FREQUENTLY_USED_EMAIL = 'reset_frequently_used_email';
export const RESET_DOCUMENT_TITLES = 'reset_document_titles';
export const RESET_ENABLED = 'reset_enabled';
export const UPDATE_MENU_MARKUP = 'update_menu_markup';
export const RESET_MENU_MARKUP = 'reset_menu_markup';
export const FETCH_WORKFLOW_TEMPLATES = 'fetch_workflow_templates';
export const UPDATE_WORKFLOW_TEMPLATE = 'update_workflow_emplate';
export const DELETE_WORKFLOW_TEMPLATE = 'delete_frequently_used_email';
export const ADD_SIGNATURE_PROFILE_IMAGE = 'add_signature_profile_image';
export const DELETE_SIGNATURE_PROFILE_IMAGE = 'delete_signature_profile_image';
export const GET_SIGNATURE_PROFILE_IMAGE = 'get_signature_profile_image';
export const GET_SIGNATURE_PROFILE_IMAGES = 'get_signature_profile_images';
export const RESET_SIGNATURE_PROFILE_IMAGES = 'reset_signature_profile_images';
export const UPDATE_SIGNATURE_PROFILE_IMAGE = 'update_signature_profile_image';
export const RESET_SELECTED_SIGNATURE = 'reset_selected_signature';
export const UPDATE_SELECTED_SIGNATURE = 'update_selected_signature';
