import initialState from './initialState';
import * as types from '../actions/actionTypes';

export default function(state = initialState.tabs, action) {
    switch (action.type) {
        case types.SET_CURRENT_TAB:
            return {
                ...state, current: action.payload

            };
        default:
            return state;
    }
}