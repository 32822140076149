import { combineReducers } from 'redux';
import { localeReducer } from 'react-localize-redux';

import confirmReducer from './reducer_confirm';
import containersReducer from './reducer_container';
import deputyReducer from './reducer_deputy';
import documentsReducer from './reducer_documents';
import documentTitlesReducer from './reducer_document_titles';
import enabledReducer from './reducer_enabled';
import frequentlyUsedEmailAddressesReducer from './reducer_email';
import menuMarkupReducer from './reducer_menu_markup';
import modalReducer from './reducer_modal';
import signatureProfileReducer from './reducer_signature_profile';
import tabsReducer from './reducer_tabs';
import tsaReducer from './reducer_tsa';
import workflowTemplateReducer from './reducer_workflow_template';
import signatureProfileImageReducer from './reducer_signature_profile_image';

export default combineReducers({
    confirm: confirmReducer,
    containerList: containersReducer,
    deputy: deputyReducer,
    documentList: documentsReducer,
    documentTitles: documentTitlesReducer,
    enabled: enabledReducer,
    frequentlyUsedEmailAddresses: frequentlyUsedEmailAddressesReducer,
    locale: localeReducer,
    menuMarkup: menuMarkupReducer,
    modal: modalReducer,
    signatureProfiles: signatureProfileReducer,
    tabs: tabsReducer,
    tsa: tsaReducer,
    workflowTemplates: workflowTemplateReducer,
    signatureProfileImages: signatureProfileImageReducer,
});
