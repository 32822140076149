import React from 'react';
import ModalWrapper from './ModalWrapper';
import UserProfileError from '../UserProfileError';
import { PropTypes } from 'prop-types';

class UserProfileErrorModal extends React.Component {
    render() {
        const { modalData, translate } = this.props;
        const modalProps = { width: '700px', ...this.props };
        return (
            <ModalWrapper {...modalProps} title={translate('errorCodes.USER_RESTRICTED_OPERATION')}>
                <UserProfileError modalData={modalData} size={64} icon="user" />
            </ModalWrapper>
        );
    }
}

UserProfileErrorModal.propTypes = {
    modalData: PropTypes.object,
    translate: PropTypes.func,
};

export default UserProfileErrorModal;
