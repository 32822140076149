import {
    ADD_SIGNATURE_PROFILE,
    DELETE_SIGNATURE_PROFILE,
    FETCH_SIGNATURE_PROFILE,
    REFRESH_SIGNATURE_PROFILE,
    RESET_SIGNATURE_PROFILE,
    TOGGLE_DEFAULT_SIGNATURE_PROFILE,
    UPDATE_SIGNATURE_PROFILE,
} from './actionTypes';
import { ajax } from '../helper';

export const addSignatureProfile = (profile) => {
    return {
        type: ADD_SIGNATURE_PROFILE,
        payload: profile,
    };
};

export const deleteSignatureProfile = (profile) => {
    return {
        type: DELETE_SIGNATURE_PROFILE,
        payload: profile,
    };
};

export const fetchSignatureProfiles = (schemas) => {
    return {
        type: FETCH_SIGNATURE_PROFILE,
        payload: schemas,
    };
};

export const refreshSignatureProfile = (profile) => {
    return {
        type: REFRESH_SIGNATURE_PROFILE,
        payload: profile,
    };
};

export const toggleDefaultSignatureProfile = (profile) => {
    return {
        type: TOGGLE_DEFAULT_SIGNATURE_PROFILE,
        payload: profile,
    };
};

export const updateSignatureProfile = (profile) => {
    return {
        type: UPDATE_SIGNATURE_PROFILE,
        payload: profile,
    };
};

export const resetSignatureProfile = () => {
    return {
        type: RESET_SIGNATURE_PROFILE,
    };
};

export const fetchAllSignatureProfiles = () => {
    return (dispatch) => {
        return ajax()
            .get('/signatureProfiles')
            .then((response) => {
                dispatch(fetchSignatureProfiles(response.data));
                return response;
            })
            .catch((error) => {
                throw error;
            });
    };
};
