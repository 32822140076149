import PropTypes from 'prop-types';
import React from 'react';
import theme from '../theme';

function DescriptionItem(props) {
    const { content, margin, title } = props;

    return (
        <div style={{ marginBottom: margin }}>
            <p style={{ color: theme.textColor, margin: '0 0 6px 0' }}>{title}</p>
            {content ? content : '-'}
        </div>
    );
}

DescriptionItem.propTypes = {
    content: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.object]),
    margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.object])
};

DescriptionItem.defaultProps = {
    margin: 10
};

export default DescriptionItem;
