import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getDocuments, getHistory } from '../../helper';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { toggleModal } from '../../actions/modal';
import { Button, Checkbox } from 'antd';
import { NOTIFY_USER_REGISTERED } from '../../constants/modalNames';

class NotifyRegisteredUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkbox: false,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChange = () => {
        this._isMounted && this.setState({ checkbox: !this.state.checkbox });
    };

    saveToLocalStorage = (checkbox) => {
        localStorage.setItem('doNotShowAgain', checkbox);
    };

    render() {
        const pricesLink = getDocuments('price_list', this.props.currentLanguage);
        const orderLink = getDocuments('order', this.props.currentLanguage);
        const link1 = this.props.translate('prices.link');
        const link2 = this.props.translate('order.link');
        return (
            <ModalWrapper {...this.props} title={this.props.translate('warning')}>
                <p>
                    {this.props.translate('userSubscriptionNotificationForRegistered', {
                        priceLink: '<a href=' + pricesLink + " target={'_blank'} >" + link1 + '</a>',
                        orderLink: '<a href=' + orderLink + " target={'_blank'} >" + link2 + '</a>',
                    })}
                </p>
                <p>
                    <Checkbox onChange={this.onChange}>{this.props.translate('checkbox.notToSee')}</Checkbox>
                </p>
                <p>
                    <Button
                        type="primary"
                        block
                        onClick={() => {
                            this.props.stopNotify();
                            this.saveToLocalStorage(this.state.checkbox);
                        }}
                    >
                        OK
                    </Button>
                </p>
            </ModalWrapper>
        );
    }
}

NotifyRegisteredUserModal.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    stopNotify: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
        currentLanguage: getActiveLanguage(state.locale).code,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        stopNotify: () => {
            dispatch(toggleModal(NOTIFY_USER_REGISTERED));
            getHistory().push({
                pathname: '/settings',
                focusOnOid: true,
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyRegisteredUserModal);
