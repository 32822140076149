import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'antd';

function ModalWrapper(props) {
    return (
        <Modal
            bodyStyle={props.bodyStyle ? props.bodyStyle : {}}
            closable={props.closable}
            destroyOnClose={true}
            footer={
                props.footer ? (
                    <div style={{ textAlign: props.alignFooterLeft ? 'left' : 'right' }}>{props.footer}</div>
                ) : null
            }
            height={props.height ? props.height : 840}
            keyboard={props.keyboard}
            maskClosable={props.maskClosable}
            onCancel={() => {
                if (props.onCancel) {
                    props.onCancel();
                }
                props.toggleModal('', '');
            }}
            style={props.style ? props.style : ''}
            title={props.title}
            visible={props.visible}
            width={props.width ? props.width : 520}>
            {props.children}
        </Modal>
    );
}

ModalWrapper.propTypes = {
    alignFooterLeft: PropTypes.bool,
    bodyStyle: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    closable: PropTypes.bool,
    footer: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    height: PropTypes.number,
    keyboard: PropTypes.bool,
    maskClosable: PropTypes.bool,
    onCancel: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    toggleModal: PropTypes.func,
    visible: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default ModalWrapper;
