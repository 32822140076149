import PropTypes from 'prop-types';
import React from 'react';
import throttle from 'lodash/throttle';
import { Divider } from 'antd';

class CustomDivider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            div: window.innerWidth < 400
        };
        this._isMounted = false;
    }

    onSize = throttle(() => {
        const { div } = this.state;
        if (window.innerWidth < 400) {
            if (!div) {
                this._isMounted && this.setState({ div: true });
            }
            return;
        }
        if (div) {
            this._isMounted && this.setState({ div: false });
        }
    }, 100);

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.onSize);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.onSize);
    }

    render() {
        const { children } = this.props;
        const { div } = this.state;
        if (div) {
            return <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{children}</div>;
        }
        return <Divider style={{ margin: '8px 0px 8px 0px' }}>{children}</Divider>;
    }
}

CustomDivider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string])
};

export default CustomDivider;
