/* eslint-disable react-hooks/exhaustive-deps */
import DeleteButton from '../../buttons/DeleteButton';
import ModalWrapper from '../ModalWrapper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import UserProfileError from '../../UserProfileError';
import {
    WORKFLOW_MESSAGE_MAX_LENGTH,
    WORKFLOW_METADATA_KEY_MAX_LENGTH,
    WORKFLOW_METADATA_VALUE_MAX_LENGTH,
    WORKFLOW_TEMPLATE_MAX_LENGTH
} from '../../../constants/config';
import { AutoComplete, Button, Checkbox, Col, Divider, Form, Icon, Input, Row, Spin } from 'antd';
import { createNotification, createNotificationShort, generateTemplateTitle } from '../../../helper';
import { getTranslate } from 'react-localize-redux';
import {
    getContainersInSignatureProcess,
    getContainers,
    searchEmails,
    SendForSignature
} from '../../../actions/container';
import { isBlank } from '../../../utilities/stringUtilities';
import { removeModal } from '../../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import theme from '../../../theme';
import { createWorkflowTemplate, getWorkflowTemplates } from '../../../actions/workflow';
import { WORKFLOW_ROLE_APPROVER, WORKFLOW_ROLE_SIGNER } from '../../../constants/strings';
import SendForSignatureTitle from './SendForSignatureTitle';

function SendForSignatureModal(props) {
    const { form } = props;

    const [disabled, setDisabled] = useState(false);
    const [frequentlyUsedEmailAddresses, setFrequentlyUsedEmailAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [titleView, setTitleView] = useState(false);
    const [templateTitle, setTemplateTitle] = useState('');
    const [editedTemplateTitle, setEditedTemplateTitle] = useState('');
    const [commentValue, setCommentValue] = useState('');
    const [metaDataList, setMetaDataList] = useState([]);
    const [participantList, setParticipantList] = useState([{ email: '', role: WORKFLOW_ROLE_SIGNER }]);

    const [conditionalTitleSpan, setConditionalTitleSpan] = useState(24);
    const [conditionalTitleEditSpan, setConditionalTitleEditSpan] = useState(0);
    const [conditionalTitleEditOffset, setConditionalTitleEditOffset] = useState(0);

    const containerIdList = useSelector((state) =>
        state.containerList.selectedContainers.map((container) => container.data.id)
    );
    const translate = useSelector((state) => getTranslate(state.locale));

    const [isLoaded, setIsLoaded] = useState(false);

    const dispatch = useDispatch();

    const iconFilled = favorite ? 'filled' : 'outlined';
    const titleSetStyle = isBlank(templateTitle) ? { fontSize: 12, color: theme.DEFAULT } : {
        fontSize: 12,
        color: theme.SECONDARY
    };

    useDeepCompareEffect(() => {
        form.resetFields();
    }, [form, metaDataList]);

    useEffect(() => {
        if (!isLoaded) {
            if (props.modalData && props.modalData.template && props.modalData.template.length !== 0) {
                let template = props.modalData.template[0];
                setTemplateTitle(template.templateTitle);
                setEditedTemplateTitle(template.templateTitle);
                setCommentValue(template.comment);
                !isBlank(template.comment) && setShowComment(true);
                setParticipantList(template.recipientList);
                setMetaDataList(getMappedMetaData(template.metadataList));
            } else {
                dispatch(getWorkflowTemplates());
            }
        }
        if (!isBlank(templateTitle)) {
            setConditionalTitleSpan(18);
            setConditionalTitleEditSpan(2);
            setConditionalTitleEditOffset(2);
        } else {
            setConditionalTitleSpan(24);
            setConditionalTitleEditSpan(0);
            setConditionalTitleEditOffset(0);
        }
        setIsLoaded(true);
    }, [participantList, metaDataList, templateTitle, commentValue, props.modalData, isLoaded]);

    const getMappedMetaData = (metaDataList) => {
        let sortedList = [];
        metaDataList && metaDataList.forEach((element) => {
            sortedList.push({ key: element.data.key, value: element.data.value, mandatory: element.required });
        });
        return sortedList;
    };

    const participantFormItems = participantList.map((participant, index) => (
        <div
            key={index}>
            <Row>
                {window.config.REACT_APP_WORKFLOW_ROLES_ENABLED &&
                <div>
                    <Col span={2}>
                        <Button type='primary' icon='user'
                                ghost={participant.role === WORKFLOW_ROLE_APPROVER}
                                onClick={() => switchButtonStyle(index, WORKFLOW_ROLE_SIGNER)} />
                    </Col>
                    <Col span={2}>
                        <Button type='primary' icon='user-add'
                                ghost={participant.role === WORKFLOW_ROLE_SIGNER}
                                onClick={() => switchButtonStyle(index, WORKFLOW_ROLE_APPROVER)} />
                    </Col></div>}
                <Col span={16}>
                    <Form.Item>
                        {form.getFieldDecorator(`emailAddresses[${index}]`, {
                            initialValue: participant.email ? participant.email : null,
                            normalize: (input) => (input !== undefined && input !== null ? input.toLowerCase() : ''),
                            rules: [
                                { required: true, message: translate('form.required') },
                                { type: 'email', message: translate('form.email.invalid') }
                            ],
                            validateTrigger: ['onBlur', 'onChange']
                        })(
                            <AutoComplete
                                autoFocus={index === 0}
                                dataSource={
                                    frequentlyUsedEmailAddresses.length > 10
                                        ? frequentlyUsedEmailAddresses.slice(0, 10)
                                        : frequentlyUsedEmailAddresses
                                }
                                onChange={(e) => onAutoCompleteChange(index, e)}>
                                <Input
                                    onChange={(e) => handleEmailChange(index, e.target.value)}
                                    placeholder={translate('modals.sendForSignature.email.placeholder')}
                                />
                            </AutoComplete>
                        )}
                    </Form.Item>
                </Col>
                <Col offset={2} span={2}>
                    {participantList.length > 1 && <DeleteButton
                        id={'delete-participant-button'}
                        onClick={() => {
                            removeParticipant(index);
                        }}
                        title={translate('modals.sendForSignature.email.remove')}
                    />}
                </Col>
            </Row>
        </div>
    ));

    const metadataFormItems = metaDataList.map((metadata, index) => (
        <span
            key={index}
            style={{ paddingTop: 10 }}>
            <Row style={{ paddingTop: 10 }}>
                <Col span={9}>
                    {index === 0 && <div>{translate('modals.sendForSignature.metadata.key')}</div>}
                    <Form.Item
                        style={{
                            marginRight: '16px',
                            width: '200px'
                        }}>
                    {form.getFieldDecorator(`metadataKey[${index}]`, {
                        initialValue: metadata.key ? metadata.key : null,
                        rules: [
                            {
                                max: WORKFLOW_METADATA_KEY_MAX_LENGTH,
                                message: translate('form.text.tooLong', { length: WORKFLOW_METADATA_KEY_MAX_LENGTH })
                            },
                            { required: true, message: translate('form.required') }
                        ]
                    })(<Input placeholder={translate('workflow.metadata.keyPlaceholder')}
                              onChange={(e) => handleMetaDataKeyChange(index, e.target.value)} />)}
                    </Form.Item>
                </Col>
                <Col span={9} offset={2}>
                    {index === 0 && <div>{translate('modals.sendForSignature.metadata.value')}</div>}
                    <Form.Item
                        style={{
                            marginRight: '16px',
                            width: '200px'
                        }}>
                    {form.getFieldDecorator(`metadataValue[${index}]`, {
                        initialValue: metadata.value ? metadata.value : null,
                        rules: [
                            {
                                max: WORKFLOW_METADATA_VALUE_MAX_LENGTH,
                                message: translate('form.text.tooLong', { length: WORKFLOW_METADATA_VALUE_MAX_LENGTH })
                            }
                        ]
                    })(<Input placeholder={translate('workflow.metadata.valuePlaceholder')}
                              onChange={(e) => handleMetaDataValueChange(index, e.target.value)} />)}
                    </Form.Item>
                </Col>
                <Col span={2} offset={2}>
                    {index === 0 && <br />}
                    <Form.Item
                        style={{
                            width: '40px'
                        }}>
                        <DeleteButton
                            id={'delete-metadata-button'}
                            onClick={() => removeMetadata(index)}
                            title={translate('modals.sendForSignature.metadata.remove')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Checkbox onChange={() => handleMetaDataRequiredChange(index)}
                          checked={metadata.mandatory}
                          style={{ textAlign: 'left' }}>
                    {translate('workflow.metadata.mandatory')}
                </Checkbox>
            </Row>
        </span>
    ));

    function submitForm(event) {
        event.preventDefault();
        form.validateFields((error, values) => {
            const noMetaData = values.metadataValue === undefined && values.metadataKey === undefined;
            const faultyEmailAddresses =
                error === null ? false : error.emailAddresses && error.emailAddresses.length > 0;
            let anyMetaDataIsWithoutKey = false;
            if (!noMetaData) {
                for (let i = 0; i < values.metadataKey.length; i++) {
                    if (isBlank(values.metadataKey[i])) {
                        anyMetaDataIsWithoutKey = true;
                        break;
                    }
                }
            }
            if (!error || (!faultyEmailAddresses && !anyMetaDataIsWithoutKey)) {
                setDisabled(true);
                setLoading(true);

                const metadataMap = new Map();
                const metadataJSONForTemplate = [];
                for (let i = 0; i < metaDataList.length; i++) {
                    if (!isBlank(metaDataList[i].key)) {
                        metadataMap.set(metaDataList[i].key, metaDataList[i].value || '');
                        metadataJSONForTemplate.push({
                            'data': {
                                'key': metaDataList[i].key,
                                'value': metaDataList[i].value || ''
                            },
                            'required': !!metaDataList[i].mandatory,
                            'order': i
                        });
                    }
                }

                return dispatch(SendForSignature(containerIdList, participantList, false, commentValue, metadataMap))
                    .then((response) => {
                        if (response.data.invalidTargetEmails.length === 0) {
                            createNotificationShort(translate, {
                                description: translate('notifications.sendForSignature.success.description'),
                                message: translate('notifications.sendForSignature.success.message'),
                                type: 'success'
                            });

                            dispatch(getContainers());
                            if (participantList.map((t) => t.email).includes(localStorage.getItem('user'))) {
                                dispatch(getContainersInSignatureProcess());
                            }
                        } else {
                            const ul = document.createElement('ul');
                            for (let i = 0; i < response.data.invalidTargetEmails.length; ++i) {
                                const li = document.createElement('li');
                                li.innerHTML = response.data.invalidTargetEmails[i];
                                ul.appendChild(li);
                            }
                            createNotificationShort(translate, {
                                description: translate('notifications.sendForSignature.invalidTargets.description', {
                                    emailAddresses: ul.outerHTML
                                }),
                                message: translate('notifications.sendForSignature.invalidTargets.message'),
                                type: 'error'
                            });
                        }
                        if (favorite) {
                            dispatch(createWorkflowTemplate(templateTitle, participantList, metadataJSONForTemplate, commentValue));
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.containerNamesWithReason) {
                            error.response.data.containerNamesWithReason.forEach((containerNamesWithReason) => {
                                createNotification(translate, error.response.data.code, {
                                    message: translate(`errorCodes.${containerNamesWithReason.reason}`, {
                                        title: containerNamesWithReason.containerName
                                    }),
                                    type: 'error'
                                });
                            });
                        } else {
                            createNotificationShort(translate, {
                                message: translate('notifications.sendForSignature.failure'),
                                type: 'error'
                            });
                        }
                    })
                    .finally(() => dispatch(removeModal()));
            }
        });
    }

    function switchButtonStyle(index, roleToSet) {
        let updatedParticipantList = [...participantList];
        updatedParticipantList[index].role = roleToSet;
        setParticipantList(updatedParticipantList);
    }

    function showCommentElement() {
        setShowComment(!showComment);
    }

    function addParticipant() {
        setParticipantList((participantList) => [...participantList, { email: '', role: WORKFLOW_ROLE_SIGNER }]);
    }

    function removeParticipant(index) {
        if (participantList.length === 1) {
            return;
        }
        let reducedList = [...participantList];
        reducedList.splice(index, 1);
        setParticipantList(reducedList);
    }

    function addMetadata() {
        setMetaDataList((metaDataList) => [...metaDataList, { key: '', value: '', mandatory: false }]);
    }

    function removeMetadata(index) {
        let reducedList = [...metaDataList];
        reducedList.splice(index, 1);
        setMetaDataList(reducedList);
    }

    function handleEmailChange(index, value) {
        let updatedParticipantList = [...participantList];
        participantList[index].email = value;
        setParticipantList(updatedParticipantList);
    }

    function handleMetaDataKeyChange(index, value) {
        let updatedMetaDataList = [...metaDataList];
        updatedMetaDataList[index].key = value;
        setMetaDataList(updatedMetaDataList);
    }

    function handleMetaDataValueChange(index, value) {
        let updatedMetaDataList = [...metaDataList];
        updatedMetaDataList[index].value = value;
        setMetaDataList(updatedMetaDataList);
    }

    function handleMetaDataRequiredChange(index) {
        let updatedMetaDataList = [...metaDataList];
        updatedMetaDataList[index].mandatory = !updatedMetaDataList[index].mandatory;
        setMetaDataList(updatedMetaDataList);
    }

    function onAutoCompleteChange(index, event) {
        if (event.length > 2) {
            dispatch(searchEmails(event)).then((response) => {
                setFrequentlyUsedEmailAddresses(response.data);
            });
        }
        if (event.length < 3) {
            setFrequentlyUsedEmailAddresses([]);
        }
        handleEmailChange(index, event);
    }

    function handleTemplateTitle() {
        let generated = generateTemplateTitle(participantList.map((p) => p.email));
        setTemplateTitle(generated);
        setEditedTemplateTitle(generated);
    }

    function isFeatureEnabled() {
        if (localStorage.getItem('startWorkflow') !== 'true') {
            return false;
        }
        return true;
    }

    if (!isFeatureEnabled()) {
        return (
            <UserProfileError
                icon={'user'}
                size={64}
            />
        );
    }

    return (
        <ModalWrapper
            {...props}
            title={<SendForSignatureTitle />}>
            <Spin
                size={'large'}
                spinning={loading}>
                <Form onSubmit={submitForm}>
                    <Form.Item>
                        <Row type='flex'>
                            <Col span={8}>
                                <b>{translate('modals.sendForSignature.email.label')}</b>
                            </Col>
                            <Col span={8} offset={8}>
                                <Button block type='dashed' onClick={addParticipant}>
                                    <Icon type='plus' />
                                    {translate('modals.sendForSignature.email.new')}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <span>
                                <b style={{ color: theme.BRAND }}>{translate('modals.sendForSignature.roles.signer')}</b>
                                {window.config.REACT_APP_WORKFLOW_ROLES_ENABLED && <span>
                                    <b> / </b>
                                    <b style={{ color: theme.DEFAULT }}>{translate('modals.sendForSignature.roles.approver')}</b>
                                </span>}
                            </span>
                        </Row>
                        <Row>
                            {participantFormItems}
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        {form.getFieldDecorator('comment', {
                            rules: [
                                {
                                    max: WORKFLOW_MESSAGE_MAX_LENGTH,
                                    message: translate('form.text.tooLong', {
                                        length: WORKFLOW_MESSAGE_MAX_LENGTH
                                    })
                                }
                            ]
                        })(
                            <div>
                                <Row type='flex'>
                                    <Col span={8}>
                                        <b>{translate(`modals.sendForSignature.comment.label`)}</b>
                                    </Col>
                                    <Col span={8} offset={8}>
                                        <Button block type='dashed' onClick={showCommentElement}>
                                            <Icon type='plus' />
                                            {translate('waitingtosignbyothers.details.comment')}</Button>
                                    </Col>
                                </Row>
                                {showComment && <Row style={{ paddingTop: 10 }}>
                                    <Input.TextArea
                                        id={'comment'}
                                        maxLength={255}
                                        placeholder={translate('modals.sendForSignature.comment.placeholder')}
                                        rows={4}
                                        value={commentValue}
                                        onChange={(e) => {
                                            setCommentValue(e.target.value);
                                        }}
                                    />
                                </Row>}
                            </div>
                        )}
                    </Form.Item>
                    {window.config.REACT_APP_META_DATA_ENABLED &&
                        <Form.Item>
                            <Row type='flex'>
                                <Col span={8}>
                                    <b>{translate('modals.sendForSignature.metadata.title')}</b>
                                </Col>
                                <Col span={8} offset={8}>
                                    <Button block type='dashed' onClick={addMetadata}>
                                        <Icon type='plus' />{translate('modals.sendForSignature.metadata.new')}</Button>
                                </Col>
                            </Row>
                            <Row>
                                {metadataFormItems}
                            </Row>
                        </Form.Item>
                    }
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} type='horizontal' />
                    <Form.Item>
                        {form.getFieldDecorator('templateTitle', {
                            initialValue: editedTemplateTitle ? editedTemplateTitle : null,
                            rules: [
                                { required: favorite, message: translate('form.required') },
                                {
                                    max: WORKFLOW_TEMPLATE_MAX_LENGTH,
                                    message: translate('form.text.tooLong', {
                                        length: WORKFLOW_TEMPLATE_MAX_LENGTH
                                    })
                                }
                            ]
                        })(
                            titleView ?
                                (
                                    <Row>
                                        <Col span={18}>
                                            <Input
                                                maxLength={255}
                                                onChange={(e) => {
                                                    setEditedTemplateTitle(e.target.value);
                                                }}
                                                placeholder={translate('workflow.template.placeholder')}
                                            />
                                        </Col>
                                        <Col span={2} offset={2}>
                                            <Button style={{ border: 'none', boxShadow: 'none' }}
                                                    type='primary' icon='check'
                                                    onClick={() => {
                                                        setTitleView(false);
                                                        setTemplateTitle(editedTemplateTitle);
                                                    }} />
                                        </Col>
                                        <Col span={2}>
                                            <Button type='primary' icon='close' ghost
                                                    onClick={() => {
                                                        setTitleView(false);
                                                    }} />
                                        </Col>
                                    </Row>
                                ) :
                                (
                                    <div>
                                        <Row>
                                            <Col style={{ color: theme.primaryColor }}>
                                                <Button style={{ border: 'none', boxShadow: 'none' }} type='primary'
                                                        ghost
                                                        onClick={() => {
                                                            if (!isBlank(participantList[0].email)) {
                                                                if (!favorite) {
                                                                    isBlank(templateTitle) && handleTemplateTitle();
                                                                } else {
                                                                    setTitleView(false);
                                                                    setEditedTemplateTitle('');
                                                                    setTemplateTitle('');
                                                                }

                                                                setFavorite(!favorite);
                                                            }
                                                        }}><Icon theme={iconFilled} type='star' />
                                                    {favorite ? translate('workflow.template.saveAndSet') : translate('workflow.template.save')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={conditionalTitleSpan} style={{ marginLeft: 20 }}>
                                                <div style={titleSetStyle}>
                                                    {isBlank(templateTitle)
                                                        ? translate('workflow.template.saveDescription')
                                                        : templateTitle
                                                    }
                                                </div>
                                            </Col>
                                            {!isBlank(templateTitle) &&
                                            <Col span={conditionalTitleEditSpan} offset={conditionalTitleEditOffset}>
                                                <Button icon='edit'
                                                        style={{
                                                            border: 'none',
                                                            boxShadow: 'none',
                                                            color: 'black'
                                                        }}
                                                        onClick={() => {
                                                            !isBlank(templateTitle) && setTitleView(true);
                                                            setEditedTemplateTitle(templateTitle);
                                                        }} />
                                            </Col>}
                                        </Row>
                                    </div>
                                )
                        )
                        }
                    </Form.Item>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} type='horizontal' />
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Row>
                            <Col span={10} offset={14}>
                                <Button
                                    block={true}
                                    disabled={disabled}
                                    htmlType={'submit'}
                                    id={'send-for-signature-button'}
                                    type={'primary'}>
                                    {translate('modals.sendForSignature.button')}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Spin>
        </ModalWrapper>
    );
}

SendForSignatureModal.propTypes = {
    form: PropTypes.object.isRequired
};

const WrappedSendForSignatureModal = Form.create(
    {
        name: 'send-for-signature-modal'
    }
)(SendForSignatureModal);
export default WrappedSendForSignatureModal;
