import FormItem from '../form/FormItem';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { ajax, createNotification, createNotificationShort } from '../../helper';
import { Button, Form, Input } from 'antd';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux/lib/index';
import { removeModal } from '../../actions/modal';
import { refreshFrequentlyUsedEmailAddresses } from '../../actions/email';

class FrequentlyUsedEmailAddressModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: this.props.modalData !== undefined && this.props.modalData.emailAddress !== undefined,
        };
    }

    handleSubmit = (e) => {
        const { form } = this.props;
        e.preventDefault();
        form.validateFieldsAndScroll((clientErr, values) => {
            if (!clientErr) {
                this.props.hideModal();
                if (this.state.update) {
                    this.updateEmailAddress(values);
                } else {
                    this.createEmailAddress(values);
                }
            }
        });
    };

    createEmailAddress(emailAddress) {
        ajax()
            .post('/emailAddress', { emailAddress: emailAddress.emailAddress })
            .then((response) => {
                this.props.refreshEmailAddresses(response);
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('frequentlyUsedEmailAddresses.create.success'),
                    type: 'success',
                });
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('frequentlyUsedEmailAddresses.create.error'),
                    type: 'error',
                });
            });
    }

    updateEmailAddress(emailAddress) {
        ajax()
            .put(`/emailAddress/${this.props.modalData.emailAddress.id}`, { emailAddress: emailAddress.emailAddress })
            .then((response) => {
                this.props.refreshEmailAddresses(response);
                createNotificationShort(this.props.translate, {
                    message: this.props.translate('frequentlyUsedEmailAddresses.update.success'),
                    type: 'success',
                });
            })
            .catch((e) => {
                createNotification(this.props.translate, e.response.data.code, {
                    message: this.props.translate('frequentlyUsedEmailAddresses.update.error'),
                    type: 'error',
                });
            });
    }

    render() {
        const { translate } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <ModalWrapper {...this.props}>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem formItemProps={{ label: translate('frequentlyUsedEmailAddresses.modalTitle') }}>
                        {getFieldDecorator('emailAddress', {
                            initialValue:
                                this.state.update === false ? '' : this.props.modalData.emailAddress.emailAddress,
                            rules: [
                                {
                                    required: true,
                                    type: 'email',
                                    message: translate('login.validateEmail'),
                                },
                            ],
                            normalize: (input) => (input !== undefined ? input.toLowerCase() : ''),
                        })(<Input placeholder="john.doe@domain.com" />)}
                    </FormItem>
                    <FormItem>
                        <Button style={{ marginTop: 8 }} type="primary" block htmlType="submit">
                            {translate('buttons.save')}
                        </Button>
                    </FormItem>
                </Form>
            </ModalWrapper>
        );
    }
}

FrequentlyUsedEmailAddressModal.propTypes = {
    emailAddress: PropTypes.object,
    hideModal: PropTypes.func.isRequired,
    modalData: PropTypes.object,
    form: PropTypes.object.isRequired,
    refreshEmailAddresses: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        hideModal: () => {
            dispatch(removeModal());
        },
        refreshEmailAddresses: (emailAddresses) => {
            dispatch(refreshFrequentlyUsedEmailAddresses(emailAddresses));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FrequentlyUsedEmailAddressModal));
