import Box from './Box';
import CustomAlert from './CustomAlert';
import PropTypes from 'prop-types';
import React from 'react';
import Registration from './Registration';
import WebPage from './WebPage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActiveLanguage, getTranslate } from 'react-localize-redux/lib/index';
import { getHistory } from '../helper';
import { registration } from './../actions/user';
import { Row, Col, Form } from 'antd';
import * as userActions from '../actions/user';

class NewUserEmailRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: false,
        };
    }

    submitRegistrationForm = (request) => {
        return this.props.actions
            .registration(Object.assign(request, { sharedFileId: this.props.match.params.id }))
            .then(() => {
                getHistory().push('/confirm');
            })
            .catch((error) => {
                return Promise.reject(error.response.data.error);
            });
    };

    render() {
        const { currentLanguage, history, translate } = this.props;
        const { serverError } = this.state;
        return (
            <WebPage location={history.location}>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
                        <Box>
                            {serverError && <CustomAlert error={serverError} />}
                            <Registration
                                currentLanguage={currentLanguage}
                                defaultEmailAddress={this.props.match.params.email}
                                serverError={serverError}
                                submitFunction={this.submitRegistrationForm}
                                translate={translate}
                            />
                        </Box>
                    </Col>
                </Row>
            </WebPage>
        );
    }
}

NewUserEmailRegister.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLanguage: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        currentLanguage: getActiveLanguage(state.locale).code,
        translate: getTranslate(state.locale),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
        registrateUser: (request) => {
            return dispatch(registration(request));
        },
    };
}

const WrappedNewUserEmailRegister = Form.create()(NewUserEmailRegister);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedNewUserEmailRegister);
